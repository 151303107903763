import "../../css/VendorOwner.css";

import React, { Component } from 'react';
import { Container, Row, Jumbotron } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import {
    requestOtpToRegisteredPhone, submitOtpCodeForRegisteredPhone, submitNewDetails, submitOtpCode,
    fetchSigningUrl, completeVendorOwnerDetailsUpdate, resendOtpToRegisteredPhone, cancelInFlightUpdate, completeBeforeWeBegin, resendOtpToNewDetails,
    clearErrors, fetchVendorOwnerLockedStatus, fetchInFlightVendorOwnerDetailsUpdate
} from "../../actions/VendorOwnerActions";
import { fetchUserDetails } from "../../actions/UserActions";
import ViewVendorOwnerDetails from "../../components/vendor-owner-details/VendorOwnerDetailsView";
import EditVendorOwnerDetails from "../../components/vendor-owner-details/VendorOwnerDetailsEditForm";

class VendorOwnerDetailsContainer extends Component {

    constructor() {
        super();
        this.state = {
            heading: "Contact Details",
            editing: false
        }
    }

    componentDidMount = () => {
        this.props.actions.fetchUserDetails();

        if (this.props.location.pathname.includes("edit")) {
            this.setState({ editing: true })
        }
    }

    updateHeading = (headingText) => {
        if (headingText !== this.state.heading) {
            this.setState({
                heading: headingText
            })
        }
    }

    editVendorOwnerDetails = () => {
        this.props.history.push("/user-management/edit");
        this.setState({
            editing: true
        })
    }

    cancelEditVendorOwnerDetails = () => {
        this.props.actions.cancelInFlightUpdate();
        this.setState({
            editing: false
        })
        this.props.history.push("/user-management");
    }

    toggleEditState = () => {
        this.setState({
            editing: !this.state.editing
        })
    }


    render() {
        const { heading, editing } = this.state;
        const { user, userFetchInProgress, location, inFlightVendorOwnerChange, requestingOtpToRegisteredPhone, otpCodeRequested,
            submitOtpForRegisteredPhoneError, submittingOtpForRegisteredPhone, submittingNewDetails, submitNewDetailsError, submittingOtpCodes,
            fetchSigningUrlError, fetchingSigningUrl, otpCodeErrors, updatingVendorOwner, requestOtpToRegisteredPhoneError, beforeWeBeginCompleted,
            vendorOwnerDetailsUpdateErrorMessage, resendingNewDetailsOtps, resendingNewDetailsOtpsErrors, otpHasExpired, cancellingInFlightUpdate,
            isVendorOwnerLocked, fetchingVendorOwnerLockedStatus, requestOtpToRegisteredPhoneRateLimited, resendOtpToRegisteredPhoneRateLimited,
            fetchingVendorOwnerAfterUpdate } = this.props;
        const { requestOtpToRegisteredPhone, submitOtpCodeForRegisteredPhone, submitNewDetails, submitOtpCode, fetchSigningUrl,
            completeVendorOwnerDetailsUpdate, resendOtpToRegisteredPhone, completeBeforeWeBegin, resendOtpToNewDetails,
            clearErrors, fetchVendorOwnerLockedStatus, fetchInFlightVendorOwnerDetailsUpdate } = this.props.actions;
        return (
            <div className="mh-100 minh-100 vendor-owner-details-container">
                <Row className="summary-title-row">
                    <div className="col-sm-12 no-padding no-margin">
                        <h1 data-cy="page-title" className="d-none d-md-block" style={{ padding: "0px", color: "grey" }}>{heading}</h1>
                        <h3 data-cy="page-title" className="d-md-none" style={{ padding: "0px", color: "grey", marginTop: "0px" }}>{heading}</h3>
                    </div>
                </Row>

                <div className="d-block vendor-owner-details-item">
                    <Container className="page-content no-padding no-margin">
                        {(!userFetchInProgress && !cancellingInFlightUpdate) && <Row className="no-padding no-margin">
                            <Container className="no-padding no-margin">
                                <Jumbotron className="vendor-details-container">
                                    {!editing &&
                                        <ViewVendorOwnerDetails user={user} updateHeading={this.updateHeading} editVendorOwnerDetails={this.editVendorOwnerDetails}
                                            inFlightVendorOwnerChange={inFlightVendorOwnerChange} cancelInFlightUpdate={this.cancelEditVendorOwnerDetails} clearErrors={clearErrors}
                                            isVendorOwnerLocked={isVendorOwnerLocked} fetchingVendorOwnerLockedStatus={fetchingVendorOwnerLockedStatus} fetchVendorOwnerLockedStatus={fetchVendorOwnerLockedStatus} 
                                            fetchInFlightVendorOwnerDetailsUpdate={fetchInFlightVendorOwnerDetailsUpdate}/>
                                    }
                                    {editing &&
                                        <EditVendorOwnerDetails vendorOwner={user} updateHeading={this.updateHeading} cancelEditVendorOwnerDetails={this.cancelEditVendorOwnerDetails}
                                            pathname={location.pathname} push={this.props.history.push} requestOtpToRegisteredPhone={requestOtpToRegisteredPhone} submitOtpCodeForRegisteredPhone={submitOtpCodeForRegisteredPhone}
                                            inFlightVendorOwnerChange={inFlightVendorOwnerChange} requestingOtpToRegisteredPhone={requestingOtpToRegisteredPhone} otpCodeRequested={otpCodeRequested}
                                            submitOtpForRegisteredPhoneError={submitOtpForRegisteredPhoneError} submittingOtpForRegisteredPhone={submittingOtpForRegisteredPhone} submitNewDetails={submitNewDetails}
                                            submittingNewDetails={submittingNewDetails} submitNewDetailsError={submitNewDetailsError} submitOtpCode={submitOtpCode} submittingOtpCodes={submittingOtpCodes}
                                            fetchSigningUrlError={fetchSigningUrlError} fetchingSigningUrl={fetchingSigningUrl} fetchSigningUrl={fetchSigningUrl} otpCodeErrors={otpCodeErrors} completeVendorOwnerDetailsUpdate={completeVendorOwnerDetailsUpdate}
                                            updatingVendorOwner={updatingVendorOwner} requestOtpToRegisteredPhoneError={requestOtpToRegisteredPhoneError} resendOtpToRegisteredPhone={resendOtpToRegisteredPhone}
                                            cancelInFlightUpdate={this.cancelEditVendorOwnerDetails} beforeWeBeginCompleted={beforeWeBeginCompleted} completeBeforeWeBegin={completeBeforeWeBegin} vendorOwnerDetailsUpdateErrorMessage={vendorOwnerDetailsUpdateErrorMessage}
                                            resendingNewDetailsOtps={resendingNewDetailsOtps} resendingNewDetailsOtpsErrors={resendingNewDetailsOtpsErrors} resendOtpToNewDetails={resendOtpToNewDetails} otpHasExpired={otpHasExpired}
                                            fetchingVendorOwnerAfterUpdate={fetchingVendorOwnerAfterUpdate} requestOtpToRegisteredPhoneRateLimited={requestOtpToRegisteredPhoneRateLimited} resendOtpToRegisteredPhoneRateLimited={resendOtpToRegisteredPhoneRateLimited}
                                            toggleEditState={this.toggleEditState} />}
                                </Jumbotron>
                            </Container>
                        </Row>}
                        {(userFetchInProgress || cancellingInFlightUpdate) && <LoadingSpinner size={80} />}
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.user.user,
        userFetchInProgress: store.user.fetchingUser,
        inFlightVendorOwnerChange: store.vendorOwnerState.inFlightVendorOwnerChange,
        requestingOtpToRegisteredPhone: store.vendorOwnerState.requestingOtpToRegisteredPhone,
        otpCodeRequested: store.vendorOwnerState.otpCodeRequested,
        submitOtpForRegisteredPhoneError: store.vendorOwnerState.submitOtpForRegisteredPhoneError,
        submittingOtpForRegisteredPhone: store.vendorOwnerState.submittingOtpForRegisteredPhone,
        submittingNewDetails: store.vendorOwnerState.submittingNewDetails,
        submitNewDetailsError: store.vendorOwnerState.submitNewDetailsError,
        submittingOtpCodes: store.vendorOwnerState.submittingOtpCodes,
        fetchSigningUrlError: store.vendorOwnerState.fetchSigningUrlError,
        fetchingSigningUrl: store.vendorOwnerState.fetchingSigningUrl,
        otpCodeErrors: store.vendorOwnerState.otpCodeErrors,
        updatingVendorOwner: store.vendorOwnerState.updatingVendorOwner,
        requestOtpToRegisteredPhoneError: store.vendorOwnerState.requestOtpToRegisteredPhoneError,
        beforeWeBeginCompleted: store.vendorOwnerState.beforeWeBeginCompleted,
        vendorOwnerDetailsUpdateErrorMessage: store.vendorOwnerState.vendorOwnerDetailsUpdateErrorMessage,
        resendingNewDetailsOtps: store.vendorOwnerState.resendingNewDetailsOtps,
        resendingNewDetailsOtpsErrors: store.vendorOwnerState.resendingNewDetailsOtpsErrors,
        otpHasExpired: store.vendorOwnerState.otpHasExpired,
        cancellingInFlightUpdate: store.vendorOwnerState.cancellingInFlightUpdate,
        fetchVendorOwnerLockedStatus: store.vendorOwnerState.fetchVendorOwnerLockedStatus,
        isVendorOwnerLocked: store.vendorOwnerState.isVendorOwnerLocked,
        fetchingVendorOwnerLockedStatus: store.vendorOwnerState.fetchingVendorOwnerLockedStatus,
        requestOtpToRegisteredPhoneRateLimited: store.vendorOwnerState.requestOtpToRegisteredPhoneRateLimited,
        resendOtpToRegisteredPhoneRateLimited: store.vendorOwnerState.resendOtpToRegisteredPhoneRateLimited,
        fetchingVendorOwnerAfterUpdate: store.vendorOwnerState.fetchingVendorOwnerAfterUpdate
    }
}

const mapActionsToDispatch = (dispatch) => {
    return {
        actions: bindActionCreators({
            fetchUserDetails, requestOtpToRegisteredPhone, submitOtpCodeForRegisteredPhone, submitNewDetails,
            submitOtpCode, fetchSigningUrl, completeVendorOwnerDetailsUpdate, resendOtpToRegisteredPhone, cancelInFlightUpdate, completeBeforeWeBegin,
            resendOtpToNewDetails, clearErrors, fetchVendorOwnerLockedStatus, fetchInFlightVendorOwnerDetailsUpdate
        }, dispatch)
    }
}

export default connect(mapStateToProps, mapActionsToDispatch)(VendorOwnerDetailsContainer);