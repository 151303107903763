import { put, takeLatest } from 'redux-saga/effects';
import * as VendorActions from '../../actions/VendorActions';
import * as UserActions from "../../actions/UserActions";
import * as OrderReviewActions from "../../actions/OrderReviewActions";
import ApiClientProvider from '../../utils/api/ApiClientProvider';
import { Map } from "immutable";
import * as Sentry from '@sentry/browser';

function* fetchVendors() {
    try {
        let apiInstance = ApiClientProvider.getVendorControllerApiInstance();
        const {data} = yield apiInstance.getVendorsUsingGET();
        data.vendors.map(vendor => {
            vendor.selected = true
            return null;
        })
        let immutableVendorsMap = Map(data.vendors.map(vendor => [vendor.vendorID, vendor]));
        yield put({ type: VendorActions.fetchVendorsSuccess().type, payload: immutableVendorsMap});
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put(VendorActions.fetchVendorsFailed({message: "Could not process request to get owned vendors."}));
            return;
        }
        if(e.response.status === 401){
            yield put(UserActions.logout());
        } 
        Sentry.captureException(e);
        yield put(VendorActions.fetchVendorsFailed({message: "Something went wrong."}));
    }
}

function* fetchVendorsReviewSummaries(action){
    try{
        let apiInstance = ApiClientProvider.getVendorControllerApiInstance();
        const {data} = yield apiInstance.getReviewScoreSummaryForActivelyOwnedVendorsUsingGET({
            vendorIDBlacklist: action.payload.vendorIDBlacklist
        });
        yield put(VendorActions.fetchVendorsReviewSummariesSuccess(data.reviewScoreSummary));
    } catch(e){
        if(!e.response){
            Sentry.captureException(e);
            yield put(VendorActions.fetchVendorsReviewSummariesFailed({message: "Could not fetch review scores."}));
            return;
        }
    if(e.response.status === 401){
        yield put(UserActions.logout());
        return;
    }
    if(e.response.status === 501){
        yield put(OrderReviewActions.orderReviewsDisabled());
        return;
    }
    Sentry.captureException(e);
    yield put(VendorActions.fetchVendorsReviewSummariesFailed({message: "Could not fetch review scores."}))
    }
}

function* fetchVendorsCompliance() {
    try {
        let apiInstance = ApiClientProvider.getComplianceControllerApiInstance();
        const {data} = yield apiInstance.getOverallComplianceDetailsUsingGET();
        yield put({ type: VendorActions.fetchVendorsComplianceSuccess().type, payload: data});
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put(VendorActions.fetchVendorsComplianceFailed({message: "Could not process request to get compliance."}));
            return;
        }
        if(e.response.status === 401){
            yield put(UserActions.logout());
        }
        Sentry.captureException(e);
        yield put(VendorActions.fetchVendorsComplianceFailed({message: "Something went wrong."}));
    }
}

export default function* rootUserSaga() {
    yield takeLatest(VendorActions.fetchVendors().type, fetchVendors);
    yield takeLatest(VendorActions.fetchVendorsReviewSummaries().type, fetchVendorsReviewSummaries)
    yield takeLatest(VendorActions.fetchVendorsCompliance().type, fetchVendorsCompliance)

}