import moment from "moment/moment";
import React, {Component} from "react";


export default class DateDisplay extends Component {

    render() {
        const {date} = this.props;
        return (<span>{moment(date).format("DD/MM/YYYY")}</span>)

    }
}

