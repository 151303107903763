import React, { Component } from 'react';
import { Row, Col, Alert, Button } from 'reactstrap';
import OtpCode from '../../../common-form-fields/OtpCode';
import LoaderButton from '../../../LoaderButton';
import { Field, reduxForm } from 'redux-form';
import Loader from 'react-loader-spinner';

class ConfirmYourIdentity extends Component {

    componentDidMount() {
        this.props.requestOtpToRegisteredPhone();
    }

    render() {
        const { maxOTPMessagesSent, submittingOtpCode, requestingOtpToRegisteredPhone, otpCodeRequested, submittingOtpForRegisteredPhone,
            submitOtpForRegisteredPhoneError, requestOtpToRegisteredPhoneError, requestOtpToRegisteredPhoneRateLimited,
            resendOtpToRegisteredPhoneRateLimited } = this.props;

        if (requestOtpToRegisteredPhoneRateLimited) {
            return (<div>
                <Row>
                    <Col>
                        <span style={{ fontSize: "18px" }}>Confirm your identity</span>
                    </Col>
                </Row>
                        <Alert style={{ textAlign: "center" }} color="danger">
                            <span>There has been too many text messages requested from this account in too short a time period.  Please wait a few minutes and <button className="link-button black-link" style={{fontWeight: "bold", textDecoration: "underline"}} onClick={() => this.props.requestOtpToRegisteredPhone()}>click here</button> to try again.</span>
                        </Alert>
                        <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                                <Button block className="btn-default" style={{ cursor: "pointer" }} onClick={() => this.props.cancelInFlightUpdate()}>
                                    Return to User Management
                            </Button>
                            </Col>
            </div>)
        }

        if (requestingOtpToRegisteredPhone || !otpCodeRequested) {
            return (
                <div>
                    <Row>
                        <Col>
                            <span style={{ fontSize: "18px" }}>Confirm your identity</span>
                        </Col>
                    </Row>
                    <div style={{ height: "100px", textAlign: "center" }}>
                        <div style={{ marginTop: "50px" }}>
                            <Loader
                                type="Puff"
                                color="#e7131a"
                                height="50"
                                width="50" />
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                <Row>
                    <Col>
                        <span style={{ fontSize: "18px" }}>Confirm your identity</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <form onSubmit={this.props.handleSubmit}>
                            {!requestOtpToRegisteredPhoneError && <Field name="otpCode" type="text" component={OtpCode} />}
                            {!requestOtpToRegisteredPhoneError && <Field
                                name="otpGuid"
                                val={this.props.inFlightVendorOwnerChange.getIn(["otp", "guid"])}
                                component={({ input, val }) => {
                                    input.onChange(val)
                                    return <div />
                                }}
                            />}
                            {submitOtpForRegisteredPhoneError &&
                                <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                                    <Alert style={{ textAlign: "center" }} color="danger">
                                        <span>{submitOtpForRegisteredPhoneError}</span>
                                    </Alert>
                                </Col>}
                            {requestOtpToRegisteredPhoneError &&
                                <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                                    <Alert style={{ textAlign: "center" }} color="danger">
                                        <span>{requestOtpToRegisteredPhoneError}</span>
                                    </Alert>
                                </Col>}
                                {resendOtpToRegisteredPhoneRateLimited &&
                                <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                                    <Alert style={{ textAlign: "center" }} color="danger">
                                        <span>Please wait a while before requesting more verification codes.</span>
                                    </Alert>
                                </Col>}
                            {!requestOtpToRegisteredPhoneError && <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                                <LoaderButton
                                    block
                                    type="submit"
                                    isLoading={submittingOtpForRegisteredPhone}
                                    text="Verify Code"
                                    loadingText="Submitting…"
                                    className="btn-default"
                                    style={{ cursor: "pointer" }} />
                                <div className="mt-1 w-100 text-right">
                                    {!maxOTPMessagesSent && !submittingOtpForRegisteredPhone && <button className="link-button black-link" onClick={() => this.props.resendOtpToRegisteredPhone()}>Resend Verification Code |</button>}
                                    {submittingOtpCode && <Loader type="Oval" color="#e7131a" height={15} width={15} />}
                                    <button className="link-button black-link" onClick={() => this.props.cancelInFlightUpdate()}> Cancel</button>
                                </div>
                            </Col>}
                            {requestOtpToRegisteredPhoneError && <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                                <Button block className="btn-default" style={{ cursor: "pointer" }} onClick={() => this.props.cancelInFlightUpdate()}>
                                    Return to User Management
                            </Button>
                            </Col>}
                        </form>
                    </Col>
                </Row>
            </div>
        )
    }
}
export default reduxForm({
    form: "ConfirmIdentity"
})(ConfirmYourIdentity)