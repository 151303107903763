import moment from 'moment';
import { SEVEN_DAYS, TODAYS, MONTH, YEAR } from '../containers/Orders/FilterNames';  // Adjust the path if needed

// Utility function to calculate date ranges based on the order search filter
export const getDateRangeForFilter = (filterName) => {
    switch (filterName) {
        case SEVEN_DAYS.filterName:
            return {
                startDate: moment().subtract(6, "days").startOf("day").toDate(),
                endDate: moment().endOf("day").toDate()
            };
        case TODAYS.filterName:
            return {
                startDate: moment().startOf("day").toDate(),
                endDate: moment().endOf("day").toDate()
            };
        case MONTH.filterName:
            return {
                startDate: moment().subtract(1, "months").startOf("day").toDate(),
                endDate: moment().endOf("day").toDate()
            };
        case YEAR.filterName:
            return {
                startDate: moment().subtract(1, "years").startOf("day").toDate(),
                endDate: moment().endOf("day").toDate()
            };
        default:
            return {
                startDate: null,
                endDate: null
            };
    }
};