import { put, takeLatest } from 'redux-saga/effects';
import * as InvoiceActions from '../../actions/InvoiceActions';
import ApiClientProvider from '../../utils/api/ApiClientProvider';
import { logout } from '../../actions/UserActions';
import * as Sentry from '@sentry/browser';

function* fetchInvoices(action) {
    try{
        let apiInstance = ApiClientProvider.getInvoicesControllerApiInstance();
        const {vendorIDBlacklist, invoicesPerPage, currentPage} = action.payload.opts;
        const {data} = yield apiInstance.getVendorInvoicesUsingGET(
            {
                vendorIDBlacklist: vendorIDBlacklist,
                invoicesPerPage: invoicesPerPage,
                currentPage: currentPage
            });
        yield put({type: InvoiceActions.fetchInvoicesSuccess().type, payload: {invoiceData: data.invoices, totalInvoiceNumber : data.invoicesSummary.totalNumInvoices, currentPage : action.payload.pageNumber}})
    } catch(e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: InvoiceActions.fetchInvoicesFailed().type, payload: "Unable to process invoices request."});
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put({ type: InvoiceActions.fetchInvoicesFailed().type, payload: e.message});
    }
}

export default function* rootUserSaga() {
    yield takeLatest(InvoiceActions.fetchInvoices().type, fetchInvoices);
}