import '../css/Login.css';

import React, { Component } from 'react';
import {Form, FormGroup, Input} from 'reactstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LoaderButton from '../components/LoaderButton';
import logoImg from '../images/scoffable_partner_centre_logo.svg';
import {login, verifyOTP, cancelOTP, logoutSuccess, resendLoginOTP, selectLoginAccount} from '../actions/UserActions';
import QueryString from 'query-string';
import AccountSelection from "./AccountSelection";


class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
        email: "",
        password: "",
        isLoginError: false,
        errorMessage: "",
        otpCode: "",
        otpResent: false
    };
  }

  renderNotificationSection(){
    if(this.props.error){
      return (<div className="alert alert-danger" role="alert">{this.props.error.message}</div>);
    }
    if(this.props.notification){
      return (<div className="alert alert-success" role="alert">Verification code re-sent.</div>);
    }
  }

  componentDidUpdate = () => {
    if(this.otpInput){
    this.otpInput.focus();
  }
  }

  componentDidMount(){
    console.log(QueryString.parse(this.props.location.search))
      this.setState({
        email: "",
        password: "",
        isLoginError: false,
        errorMessage: "",
        otpCode: "",
        otpResent: false,
        isPasswordReset: QueryString.parse(this.props.location.search).passwordReset,
        redirect: QueryString.parse(this.props.location.search).redirect
    });
  }

  static getDerivedStateFromProps(props, state){
    if(state.isPasswordReset !== QueryString.parse(props.location.search).passwordReset){
      return {
        isPasswordReset: QueryString.parse(props.location.search).passwordReset
      }
    } else {
      return state;
    }
  }

  renderLoginForm(){
    return (
      <Form onSubmit={this.handleLoginSubmit}>
        {this.renderNotificationSection()}
        <FormGroup>
            <Input type="email" 
              name="email" 
              id="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="Email" />
        </FormGroup>
        <FormGroup>
            <Input type="password" 
                  name="password" 
                  id="password" 
                  value={this.state.password}
                  onChange={this.handleChange}
                  placeholder="Password" />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          isLoading={this.props.isLoginInProgress || this.props.isVerifyOTPInProgress}
          text="Login"
          loadingText="Logging in…"
          className="btn-default"
          disabled={this.state.email === "" || this.state.password === ""}
          />
          <div className="mt-1 w-100 text-right">
            <a href="/passwordreset">Forgot Password?</a> | <a href="/register">Register</a>
          </div>
      </Form>
    );
  }
  
  renderOTPForm(){
    
    return (
      <Form onSubmit={this.handleOTPVerificationSubmit}>
        <div className="alert alert-light p-0 m-2 text-center" role="alert">We've sent a verification code to your registered telephone number. To continue, please enter the code below.</div>
        {this.renderNotificationSection()}
        <FormGroup>
            <Input type="text" 
              name="otpcode" 
              id="otpCode"
              value={this.state.otpCode}
              onChange={this.handleChange}
              placeholder="Verification Code" 
              innerRef={(input) => { this.otpInput = input; }}/>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          isLoading={this.props.isLoginInProgress || this.props.isVerifyOTPInProgress}
          text="Verify Code"
          loadingText="Logging in…"
          className="btn-default"
          disabled={this.state.otpCode === ""}
          />
          <div className="mt-1 w-100 text-right">
            <button id={"resend-verification-code"} className="link-button red-link" onClick={this.handleResendOTPCode}>Resend Verification Code</button> | <button id={"cancel-verification"} className="link-button red-link" onClick={this.handleCancelOTPLinkPress}>Cancel</button>
          </div>
      </Form>
    );
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleLoginSubmit = event => {
    event.preventDefault();
    if(this.state.isPasswordReset){
      this.props.actions.resendLoginOTP({loginSessionId: this.props.loginSessionId})
    } else {
    this.props.actions.login({
      email: this.state.email,
      password: this.state.password
    });
  }
  }

  handleResendOTPCode = event => {
    event.preventDefault();
      this.props.actions.resendLoginOTP({loginSessionId: this.props.loginSessionId});
  }

  handleOTPVerificationSubmit = event => {
    event.preventDefault();
    this.props.actions.verifyOTP({
      loginSessionId: this.props.loginSessionId,
      otpCode: this.state.otpCode,
      push: this.props.history.push,
      redirect: this.state.redirect
    });
  }

  handleCancelOTPLinkPress = event => {
    event.preventDefault();
    this.props.history.push('/login')
    this.props.actions.cancelOTP();
  }

  handleSelectAccount = (accountId) => {
    this.props.actions.selectLoginAccount({loginSessionId: this.props.loginSessionId, accountId: accountId})
  }

  render() {
    if(this.props.linkedAccounts){
      return (<AccountSelection accounts={this.props.linkedAccounts} onSelect={this.handleSelectAccount} />)
    }
    return (
      <div className="container login-container">
        <div className="card login-card">
          <div className="card-body">
            <div className="Login">
              <div className="logo-container">
                <img alt="login" src={logoImg} className="login-logo"/>
              </div>
              {this.props.loginSessionId ? this.renderOTPForm() :this.renderLoginForm()}
            </div>
          </div>
        </div>
      </div>
    );
  }


}

const mapStateToProps = (store) => {
  return {  
    userHasAuthenticated: store.user.userHasAuthenticated,
    error: store.user.error,
    isLoginInProgress: store.user.loginInProgress,
    isVerifyOTPInProgress: store.user.verifyOTPInProgress,
    loginSessionId: store.user.loginSessionId,
    notification: store.user.notification,
    linkedAccounts: store.user.linkedAccounts
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({login, verifyOTP, cancelOTP, logoutSuccess, resendLoginOTP, selectLoginAccount}, dispatch)
  };
};

const connectedComponent = connect(mapStateToProps, mapDispatchToProps)(Login)

export default withRouter(connectedComponent);