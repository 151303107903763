import {
    fetchVendorComplianceSession,
    fetchVendorComplianceSessionSuccess,
    fetchVendorComplianceSessionFailed,
    deleteVendorComplianceSession,
    deleteVendorComplianceSessionSuccess,
    deleteVendorComplianceSessionFailed,
    cancelComplianceSessionInProgressPoller,
    startComplianceSessionInProgressPoller,
} from "../../actions/ComplianceActions";
import { logoutSuccess } from "../../actions/UserActions";

const initialState = {
    complianceSession: null,
    errorMessage: null,
    redirectToComplianceInstructions: false,
    complianceSessionDeleteInProgress: false,
    compliancePollRequests: 0,
    compliancePollingInProgress: false
};

export default function ComplianceReducer(state = initialState, action) {
    switch (action.type) {
        case fetchVendorComplianceSession().type:
            return {...state, errorMessage: null, complianceSessionFetchInProgress: true }
        case fetchVendorComplianceSessionSuccess().type:
            return {...state, complianceSession: action.payload, errorMessage: null, complianceSessionFetchInProgress: false, compliancePollRequests: state.compliancePollRequests+1};
        case fetchVendorComplianceSessionFailed().type:
            return {...state, errorMessage: action.payload?.message ?? "Something went wrong.",  complianceSessionFetchInProgress: false }
        case deleteVendorComplianceSession().type:
            return {...state, complianceSessionDeleteInProgress: true}
        case deleteVendorComplianceSessionSuccess().type:
            return {...state, complianceSession: null, redirectToComplianceInstructions: true, complianceSessionDeleteInProgress: false};
        case deleteVendorComplianceSessionFailed().type:
            return {...state, errorMessage: action.payload?.message ?? "Something went wrong.", complianceSessionDeleteInProgress: false }
        case cancelComplianceSessionInProgressPoller().type:
            return {...state, compliancePollingInProgress: false}
        case startComplianceSessionInProgressPoller().type:
            return {...state, compliancePollRequests: 0, compliancePollingInProgress: true}
        case logoutSuccess().type:
            return {...initialState}
        default:
            return state;
    }
}
