import React, { Component } from 'react';
import { Col, Row, Input, Button } from "reactstrap";
import * as StepRoutes from "../VendorOwnerFormRoutes";

export default class BeforeWeBegin extends Component {

    constructor(props){
        super(props);
        this.state = {
            confirmNewDetailsCheckBoxChecked: false,
            confirmAccessToCurrentDetailsCheckBoxChecked: false
        }
    }

    handleConfirmNewDetailsCheckBoxClick = () => {
        this.setState({
            confirmNewDetailsCheckBoxChecked: !this.state.confirmNewDetailsCheckBoxChecked
        })
    }

    handleConfirmAccessToCurrentDetailsCheckBoxClick = () => {
        this.setState({
            confirmAccessToCurrentDetailsCheckBoxChecked: !this.state.confirmAccessToCurrentDetailsCheckBoxChecked
        })
    }

    render() {
        const { vendorOwner, push, completeBeforeWeBegin } = this.props;
        const { confirmNewDetailsCheckBoxChecked, confirmAccessToCurrentDetailsCheckBoxChecked } = this.state;

        return (
            <div>
                <Row>
                    <Col>
                        <span style={{ fontSize: "18px" }}>Before we begin.....</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span>In order to complete this process you will need access to:</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 12 }} >
                        <ul>
                            <li>Your currently registered telephone number ({vendorOwner.telephoneNumber})</li>
                            <li>Your new contact number and/or email address</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 8, offset: 2 }}>
                    <div onClick={this.handleConfirmAccessToCurrentDetailsCheckBoxClick}>
                        <Input type="checkbox" id="detailsAccess" checked={this.state.confirmAccessToCurrentDetailsCheckBoxChecked} />
                        <span style={{cursor: "default"}}>I confirm that I have access to the above details</span>
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 8, offset: 2 }}>
                    <div onClick={this.handleConfirmNewDetailsCheckBoxClick}>
                        <Input type="checkbox" id="detailsAreOwnedByMe" checked={this.state.confirmNewDetailsCheckBoxChecked} />
                        <span style={{cursor: "default"}}>I confirm that any new details being submitted belong to me</span>
                    </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 8, offset: 2 }}>
                        <Button style={{ width: "100%", cursor: !(confirmNewDetailsCheckBoxChecked && confirmAccessToCurrentDetailsCheckBoxChecked) ? "not-allowed" : "pointer", verticalAlign: "middle" }} 
                        color="secondary" disabled={!(confirmNewDetailsCheckBoxChecked && confirmAccessToCurrentDetailsCheckBoxChecked)} onClick={() => {
                            completeBeforeWeBegin()
                            push(StepRoutes.CONFIRM_YOUR_IDENTITY)
                        }} >Continue</Button>
                        <div className="mt-1 w-100 text-right">
                            <button className="link-button black-link" onClick={() => push(StepRoutes.CANCEL)}>Cancel</button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
