import { fetchBankDetailsSuccess, fetchBankDetailsFailed, fetchBankDetails, fetchEffectiveDate, fetchEffectiveDateSuccess, fetchEffectiveDateFailed, submitOtpCode, submitBankDetails, submitBankDetailsSuccess, submitBankDetailsFailed, submitOtpCodeSuccess, submitOtpCodeFailed, cancelBankDetailsUpdate, cancelBankDetailsUpdateSuccess, cancelBankDetailsUpdateFailed, fetchSigningUrl, fetchSigningUrlSuccess, fetchSigningUrlFailed } from "../../actions/BankDetailsActions";
import { logoutSuccess } from "../../actions/UserActions";
import { List } from "immutable";
const initialState = {
    fetchingBankDetails: false, error: null, bankDetails: List(), submittingOtpCode: false, otpCodeError: null, submittingBankDetails: false,
    bankDetailsError: null, otpCodeRequested: false, submittingCancelBankDetailsUpdate: false, fetchingSigningUrl: false, maxOTPMessagesSent: false,
    bankDetailsFetched: false, bankDetailsSubmited: false
};
/* eslint-disable eqeqeq */
export default function BankDetailsReducer(state = initialState, action) {
    switch (action.type) {
        case fetchBankDetails().type:
            return { ...state, fetchingBankDetails: true, error: null, bankDetails: null, bankDetailsFetched: false }
        case fetchBankDetailsSuccess().type:
            return { ...state, bankDetails: action.payload, fetchingBankDetails: false, error: null, bankDetailsFetched: true };
        case fetchBankDetailsFailed().type:
            return { ...state, error: action.payload, fetchingBankDetails: false, bankDetails: null, bankDetailsFetched: false }
        case fetchEffectiveDate().type:
            return { ...state, fetchingEffectiveDate: true, effectiveDateError: null, bankDetails: state.bankDetails.set(state.bankDetails.findKey(bankDetails => bankDetails.vendorID == action.payload.vendorID), { ...state.bankDetails.get(state.bankDetails.findKey(bankDetails => bankDetails.vendorID == action.payload.vendorID)), effectiveDate: null }) }
        case fetchEffectiveDateSuccess().type:
            return { ...state, fetchingEffectiveDate: false, effectiveDateError: null, bankDetails: state.bankDetails.set(state.bankDetails.findKey(bankDetails => bankDetails.vendorID == action.payload.vendorID), { ...state.bankDetails.get(state.bankDetails.findKey(bankDetails => bankDetails.vendorID == action.payload.vendorID)), effectiveDate: action.payload.effectiveDate }) };
        case fetchEffectiveDateFailed().type:
            return { ...state, fetchingEffectiveDate: false, effectiveDateError: action.payload, bankDetails: state.bankDetails.set(state.bankDetails.findKey(bankDetails => bankDetails.vendorID == action.payload.vendorID), { ...state.bankDetails.get(state.bankDetails.findKey(bankDetails => bankDetails.vendorID == action.payload.vendorID)), effectiveDate: null }) }
        case submitBankDetails().type:
            return { ...state, submittingBankDetails: true, bankDetailsError: null }
        case submitBankDetailsSuccess().type:
            return { ...state, submittingBankDetails: false, bankDetailsError: null, bankDetails: state.bankDetails.set(state.bankDetails.findKey(bankDetails => bankDetails.vendorID === action.payload.invoicingConfiguration.vendorID), { ...action.payload.invoicingConfiguration, effectiveDate: state.bankDetails.find(bankDetails => bankDetails.vendorID === action.payload.invoicingConfiguration.vendorID).effectiveDate }), otpCodeRequested: true, maxOTPMessagesSent: action.payload.maxOTPMessagesSent, bankDetailsSubmited: true }
        case submitBankDetailsFailed().type:
            return { ...state, submittingBankDetails: false, bankDetailsError: action.payload }
        case submitOtpCode().type:
            return { ...state, submittingOtpCode: true, otpCodeError: null }
        case submitOtpCodeSuccess().type:
            return { ...state, submittingOtpCode: false, otpCodeError: null, bankDetails: state.bankDetails.set(state.bankDetails.findKey(bankDetails => bankDetails.vendorID === action.payload.vendorID), { ...action.payload, effectiveDate: state.bankDetails.find(bankDetails => bankDetails.vendorID === action.payload.vendorID).effectiveDate }) }
        case submitOtpCodeFailed().type:
            return { ...state, submittingOtpCode: false, otpCodeError: action.payload }
        case cancelBankDetailsUpdate().type:
            return { ...state, submittingCancelBankDetailsUpdate: true }
        case cancelBankDetailsUpdateSuccess().type:
            return { ...state, submittingCancelBankDetailsUpdate: false }
        case cancelBankDetailsUpdateFailed().type:
            return { ...state, submittingCancelBankDetailsUpdate: false, cancelBankDetailsUpdateError: action.payload }
        case fetchSigningUrl().type:
            return { ...state, fetchingSigningUrl: true, fetchSigningUrlError: null }
        case fetchSigningUrlSuccess().type:
            return { ...state, fetchingSigningUrl: false, bankDetails: state.bankDetails.set(state.bankDetails.findKey(bankDetails => bankDetails.vendorID === action.payload.vendorID), { ...state.bankDetails.find(bankDetails => bankDetails.vendorID === action.payload.vendorID), agreementSigningURL: action.payload.agreementSigningURL }), fetchSigningUrlError: null }
        case fetchSigningUrlFailed().type:
            return { ...state, fetchingSigningUrl: false, fetchSigningUrlError: action.payload }
        case logoutSuccess().type:
            return {...initialState}
        default:
            return state;
    }
}
/* eslint-enable eqeqeq */