import { put, takeLatest, all } from 'redux-saga/effects';
import * as OrderReviewActions from '../../actions/OrderReviewActions';
import ApiClientProvider from '../../utils/api/ApiClientProvider';
import { logout } from '../../actions/UserActions';
import * as Sentry from '@sentry/browser';

function* fetchReviews(action) {
    try{
        let apiInstance = ApiClientProvider.getOrdersControllerApiInstance();
        console.log("Fetching reviews")
        console.log(action.payload)
        const {vendorIDBlacklist, itemsPerPage, currentPage, reviewSentiment} = action.payload

        let reviewSentimentValue = null;
        if(reviewSentiment !== null){
            if(reviewSentiment === "positive"){
                reviewSentimentValue = 'POSITIVE';
            }
            else if(reviewSentiment === "negative"){
                reviewSentimentValue = 'NEGATIVE';
            }
        }

        console.log("Review sentiment value (fetch reviews)", reviewSentimentValue);
        const {data} = yield apiInstance.getOrderReviewsForVendorsUsingGET(
            {
                vendorIDBlacklist: vendorIDBlacklist,
                itemsPerPage: itemsPerPage,
                currentPage: currentPage,
                ...(reviewSentimentValue !== null && { sentiment: reviewSentimentValue })
            }
        );
        const reviewsMappedToIds = data.reviews.map(review => [review.id, review])
        yield put({type: OrderReviewActions.fetchOrderReviewsSuccess().type, payload: {reviews: reviewsMappedToIds, totalReviewsNumber : data.totalResults, currentPage : action.payload.currentPage, reviewSentiment: action.payload.reviewSentiment}})
    } catch(e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: OrderReviewActions.fetchOrderReviewsFailed().type, payload: "Unable to fetch order reviews."});
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        if(e.response.status === 501){
            yield put(OrderReviewActions.orderReviewsDisabled());
            return;
        }
        Sentry.captureException(e);
        yield put({ type: OrderReviewActions.fetchOrderReviewsFailed().type, payload: e.message});
    }
}

function* replyToOrderReview(action){
    try{
        let apiInstance = ApiClientProvider.getOrdersControllerApiInstance()
        let createReplyRequest = {}
        createReplyRequest.content = action.payload.replyText
        const {data} = yield apiInstance.replyToOrderReviewUsingPOST({
            reviewid: action.payload.reviewId,
            apiCreateOrderReviewReplyRequest: createReplyRequest
        })
        action.payload.toggleReplyForm()
        yield put(OrderReviewActions.replyToOrderReviewSuccess(data))
    } catch(e){
        console.log(e)
        if(!e.response){
            Sentry.captureException(e)
            yield put({ type: OrderReviewActions.replyToOrderReviewFailed().type, payload: {error: "Unable to submit reply", reviewId: action.payload.reviewId}})
            return
        }
        if(e.response.status === 401){
            yield put(logout())
            return
        }
        if(e.response.status === 400){
            yield put(OrderReviewActions.replyToOrderReviewFailed({error: "Reply content is not valid. Please check and try again.", reviewId: action.payload.reviewId}))
            return
        }
        Sentry.captureException(e)
        yield put(OrderReviewActions.replyToOrderReviewFailed({error: "Unable to submit reply.  If this problem persists please contact customer support.", reviewId: action.payload.reviewId}))
    }
}

function* editOrderReviewReply(action){
    try{
        let apiInstance = ApiClientProvider.getOrdersControllerApiInstance()
        let editReplyRequest = {}
        editReplyRequest.content = action.payload.replyText
        const {data} = yield apiInstance.editReplyToOrderReviewUsingPUT(
            {
                reviewid: action.payload.reviewId,
                replyid: action.payload.replyId,
                apiCreateOrderReviewReplyRequest: editReplyRequest
            })
        action.payload.toggleEditReplyForm()
        yield put(OrderReviewActions.editOrderReviewReplySuccess(data))
    } catch(e){
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: OrderReviewActions.editOrderReviewReplyFailed().type, payload: {error: "Unable to submit reply", reviewId: action.payload.reviewId}})
            return
        }
        if(e.response.status === 401){
            yield put(logout());
            return
        }
        Sentry.captureException(e)
        yield put(OrderReviewActions.editOrderReviewReplyFailed({error: e.message, reviewId: action.payload.reviewId}))
    }
}

function* deleteOrderReviewReply(action){
    try{
        let apiInstance = ApiClientProvider.getOrdersControllerApiInstance()
        const {data} = yield apiInstance.deleteReplyToOrderReviewUsingDELETE({
            reviewid: action.payload.reviewId,
            replyid: action.payload.replyId
        })
        yield put(OrderReviewActions.deleteOrderReviewReplySuccess(data))
    } catch(e){
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: OrderReviewActions.deleteOrderReviewReplyFailed().type, payload: {error: "Unable to submit reply", reviewId: action.payload.reviewId}})
            return
        }
        if(e.response.status === 401){
            yield put(logout());
            return
        }
        Sentry.captureException(e)
        yield put(OrderReviewActions.deleteOrderReviewReplyFailed({error: e.message, reviewId: action.payload.reviewId}))
    }
}

function* fetchOrderReviewData(action){
    console.log("Fetching order review data: ", action)
    try{
        let vendorControllerApiInstance = ApiClientProvider.getVendorControllerApiInstance();
        let orderReviewsControllerApiInstance = ApiClientProvider.getOrdersControllerApiInstance();
        const {vendorIDBlacklist, itemsPerPage, currentPage, reviewSentiment } = action.payload

        console.log("Review Sentiment: ", reviewSentiment)
        let reviewSentimentValue = null;
        if(reviewSentiment !== null){
            if(reviewSentiment === 'positive'){
                reviewSentimentValue = "POSITIVE";
            }
            else if(reviewSentiment === 'negative'){
                reviewSentimentValue = "NEGATIVE";
            }
        }

        console.log("Review Sentiment Value: ", reviewSentimentValue);
        const [reviewSummaryData, reviewsData] = yield all([
            vendorControllerApiInstance.getReviewScoreSummaryForActivelyOwnedVendorsUsingGET(
                {vendorIDBlacklist: action.payload.vendorIDBlacklist}
            ),
            orderReviewsControllerApiInstance.getOrderReviewsForVendorsUsingGET({
                vendorIDBlacklist: vendorIDBlacklist,
                itemsPerPage: itemsPerPage,
                currentPage: currentPage,
                ...(reviewSentimentValue !== null && { sentiment: reviewSentimentValue })
            })
        ]);
        const reviewsMappedToIds = reviewsData.data.reviews.map(review => [review.id, review])
        yield put(OrderReviewActions.fetchOrderReviewDataSuccess({
            reviewSummaryData: reviewSummaryData.data.reviewScoreSummary,
            reviewsData: {
                reviews: reviewsMappedToIds, 
                totalReviewsNumber : reviewsData.data.totalResults
            }
        }));
    } catch(e){
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: OrderReviewActions.fetchOrderReviewDataFailed().type, payload: "Unable to fetch order reviews."});
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        if(e.response.status === 501){
            yield put(OrderReviewActions.orderReviewsDisabled());
            return;
        }
        Sentry.captureException(e);
        yield put({ type: OrderReviewActions.fetchOrderReviewsFailed().type, payload: e.message});
    }
}

export default function* rootReviewsSaga() {
    yield takeLatest(OrderReviewActions.fetchOrderReviews().type, fetchReviews);
    yield takeLatest(OrderReviewActions.replyToOrderReview().type, replyToOrderReview)
    yield takeLatest(OrderReviewActions.editOrderReviewReply().type, editOrderReviewReply);
    yield takeLatest(OrderReviewActions.deleteOrderReviewReply().type, deleteOrderReviewReply);
    yield takeLatest(OrderReviewActions.fetchOrderReviewData().type, fetchOrderReviewData);
}