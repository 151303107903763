import React from 'react';
import { Redirect, Switch, Route } from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import { fetchVendors, fetchVendorsCompliance } from "./actions/VendorActions";
import { fetchUserDetails } from "./actions/UserActions";
import LoadingSpinner from "./components/loading/LoadingSpinner";
import GA from './utils/analytics/GoogleAnalytics';
import * as Sentry from '@sentry/browser';
import ErrorPage from './components/errors/ErrorPage';
import { isCookiesExists} from './utils/cookie/CookieManager';
import { withRouter } from 'react-router';
import Login from "./containers/Login";
import PasswordResetRequest from "./containers/PasswordResetRequest";
import PasswordReset from "./containers/PasswordReset";
import Orders from "./containers/Orders/Orders";
import OrderDetails from "./containers/Orders/OrderDetails";
import Invoices from "./containers/Invoices/Invoices";
import VendorOwnerDetails from "./containers/vendor-owner-details/VendorOwnerDetailsContainer";
import BankDetails from "./containers/BankDetails/BankDetails";
import OrderReviews from "./containers/order-reviews/OrderReviews";
import Payouts from "./containers/payouts/Payouts";
import Faqs from "./containers/Faqs/Faqs";
import Compliance from "./containers/Compliance/Compliance";
import NoPermission from "./containers/NoPermission"
import * as Steps from "./components/compliance/steps/StepValues";
import {userHasPermission} from "./utils/helpers/User";
import {
  CREATE_BANK_DETAILS, CREATE_COMPLIANCE,
  VIEW_BANK_DETAILS, VIEW_COMPLIANCE, VIEW_CONTACT_DETAILS, VIEW_FAQS,
  VIEW_INVOICES,
  VIEW_ORDERS, VIEW_PAYOUTS,
  VIEW_REVIEWS, VIEW_VENDOR
} from "./utils/helpers/Permission";


function isUserAuthenticated() {
  return isCookiesExists();
}
class Routes extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      initialAuthenticatedRequestsTriggered: false,
      initialRequestsTriggered: false
    }
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({hasError: true});
    Sentry.captureException(error, {extra: info});
  }

  render() {
    const {hasError} = this.state;
    const { userHasAuthenticated, childProps } = this.props;

    if (userHasAuthenticated) {

      const requestsTriggered = this.triggerInitialApiRequestsIfNecessary();

      if(requestsTriggered || (!this.initialApiDataFetched() && this.initialApiRequestsInProgress())){
        // Show a spinner while initial API requests are in progress
        return <LoadingSpinner size={80}/>
      }
    }

    if (hasError) {
      return this.showError(childProps);
    }

    return ([
      <div key="googleAnalyticsRoute">
        {GA.init() &&
            <GA.RouteTracker userHasAuthenticated={userHasAuthenticated}/>}
      </div>,
      this.userRoutes()
    ]);
  }

  userRoutes(){
    if(isUserAuthenticated()) {
      return this.authenticatedUserRoutes()
    } else {
      return this.unauthenticatedUserRoutes()
    }
  }

  /**
   * This handles routing when the user has logged in.
   *
   * If the user doesn't have permission to view a particular page, the page is skipped over, and the user is redirected
   * to a page they have access to further down in this function.
   * @returns {*|JSX.Element}
   */
  authenticatedUserRoutes() {
    const {user, vendors, childProps} = this.props

    return (
        <Switch key="authenticatedRoutes">

          {userHasPermission(user, VIEW_ORDERS) && <AuthenticatedRoute path="/orders" exact component={Orders} props={childProps}/>}
          {userHasPermission(user, VIEW_ORDERS) && <AuthenticatedRoute path="/order/:orderID" exact component={OrderDetails} props={childProps}/>}
          {userHasPermission(user, VIEW_INVOICES) && <AuthenticatedRoute path="/invoices" exact component={Invoices} props={childProps}/>}

          {userHasPermission(user, VIEW_BANK_DETAILS) && currentlyOwnsVendors(vendors) && <AuthenticatedRoute path="/bank-details" exact component={BankDetails} props={childProps}/>}
          {userHasPermission(user, CREATE_BANK_DETAILS) && currentlyOwnsVendors(vendors) && <AuthenticatedRoute path="/bank-details/edit/:vendorID" exact component={BankDetails} props={childProps}/>}
          {userHasPermission(user, CREATE_BANK_DETAILS) && currentlyOwnsVendors(vendors) && <AuthenticatedRoute path="/bank-details/edit/:vendorID/:complete" exact component={BankDetails} props={childProps}/>}

          {userHasPermission(user, VIEW_REVIEWS) && <AuthenticatedRoute path="/order-reviews" component={OrderReviews} props={childProps} />}
          {userHasPermission(user, VIEW_PAYOUTS) && <AuthenticatedRoute path="/payouts" component={Payouts} props={childProps} />}
          {userHasPermission(user, VIEW_CONTACT_DETAILS) && <AuthenticatedRoute path="/user-management" component={VendorOwnerDetails} props={childProps} />}
          {userHasPermission(user, VIEW_FAQS) && <AuthenticatedRoute path="/faqs" component={Faqs} props={{ ...childProps }} />}

          {userHasPermission(user, VIEW_COMPLIANCE) && <AuthenticatedRoute path="/compliance" exact component={Compliance} props={{...childProps, step: Steps.OVERVIEW}} />}
          {userHasPermission(user, CREATE_COMPLIANCE) && <AuthenticatedRoute path="/compliance/:vendorId/:ownershipPeriodId" exact component={Compliance} props={{...childProps, step: Steps.INITIAL_INSTRUCTIONS}} />}
          {userHasPermission(user, CREATE_COMPLIANCE) && <AuthenticatedRoute path="/compliance/:vendorId/:ownershipPeriodId/return" exact component={Compliance} props={{...childProps, step: Steps.RETURN_FROM_ONBOARDING}} />}
          {userHasPermission(user, CREATE_COMPLIANCE) && <AuthenticatedRoute path="/compliance/:vendorId/:ownershipPeriodId/complete" exact component={Compliance} props={{...childProps, step: Steps.COMPLETE}} />}

          {<AuthenticatedRoute path="/unauthorized" exact component={NoPermission} props={{...childProps}} />}

          {/* This handles if the user has tried to access a page they don't have permission to view.
              We walk down a list of some normal things until we find something they can access, and redirect them there */}
          {userHasPermission(user, VIEW_ORDERS) && <Redirect from='/' to='/orders'/>}
          {userHasPermission(user, VIEW_REVIEWS) && <Redirect from='/' to='/order-reviews'/>}
          {userHasPermission(user, VIEW_INVOICES) && <Redirect from='/' to='/invoices'/>}
          {userHasPermission(user, VIEW_PAYOUTS) && <Redirect from='/' to='/payouts'/>}

          {/* If we get here and the user is authenticated, redirect to the unauthorized page */}
          {<Redirect from='/' to='/unauthorized' />}
        </Switch>
    )
  }

  /**
   * This is a bunch of routes the user can access without having fully authenticated.
   *
   * If the user tries any other URL that isn't in here, they are redirected back to the login page.
   * @returns {JSX.Element}
   */
  unauthenticatedUserRoutes() {
    const {location, childProps} = this.props

    return (
        <Switch key="unauthenticatedRoutes">
          <UnauthenticatedRoute path="/passwordreset" exact component={PasswordResetRequest} props={{ ...childProps, isAuthenticated: isUserAuthenticated() }} />
          <UnauthenticatedRoute path="/passwordreset/:passwordResetSessionId/:passwordResetKey" exact component={PasswordReset} props={{ ...childProps, registerMode: false, isAuthenticated: isUserAuthenticated() }} />
          <UnauthenticatedRoute path="/login" exact component={Login} props={{ ...childProps, isAuthenticated: isUserAuthenticated() }} />
          <UnauthenticatedRoute path="/register" exact component={PasswordResetRequest} props={{ registerMode: true, ...childProps }} />
          <UnauthenticatedRoute path="/register/:registrationSessionId/:passwordResetKey" exact component={PasswordReset} props={{ ...childProps, registerMode: true, isAuthenticated: isUserAuthenticated() }} />

          {<Redirect from='/' to={location.pathname === '/login' || location.pathname === '/error' ? `/login` : `/login?redirect=${location.pathname}`}/>}
        </Switch>
    )
  }

  /**
   * This manages the initial API requests. Note that these aren't all parallel, nor all sequential either
   * We need to get the user details first, and then look at permissions to then (potentially) make further API calls
   */
  triggerInitialApiRequestsIfNecessary() {
    const {
      userFetchInProgress,
      user,
      actions,
      vendorsFetched,
      vendorsFetchInProgress,
      vendorsComplianceFetched,
      vendorsComplianceFetchInProgress
    } = this.props;

    let requestsTriggered = false;

    if (!userFetchInProgress && !user) {
      // User has authenticated but we don't know their details yet.
      // Fetch user details + permissions etc and cache in state
      actions.fetchUserDetails();
      requestsTriggered = true;
    }

    if (user) {
      // We now have the user (and know their access level). We now do follow on requests
      if (!vendorsFetched && !vendorsFetchInProgress && userHasPermission(user, VIEW_VENDOR)) {
        actions.fetchVendors();
        requestsTriggered = true;
      }

      // TODO - This could perhaps be removed or updated once compliance stuff is released and turned on globally
      // There might be some alert functionality we'll need to retain, but perhaps the API requests could be moved to the
      // views that need them
      if (!vendorsComplianceFetched && !vendorsComplianceFetchInProgress && userHasPermission(user, VIEW_COMPLIANCE)) {
        actions.fetchVendorsCompliance();
        requestsTriggered = true;
      }
    }

    return requestsTriggered;
  }

  showError(childProps) {
    return (
        <Switch>
          <Route path="/error" exact component={ErrorPage} props={childProps}/>
          <Redirect to="/error"/>
        </Switch>
    );
  }

  initialApiRequestsInProgress() {
    const {
      userFetchInProgress,
      vendorsFetchInProgress,
      vendorsComplianceFetchInProgress
    } = this.props;

    return (userFetchInProgress || vendorsFetchInProgress || vendorsComplianceFetchInProgress)
  }

  /**
   * This returns true if all the API backed objects required to render the partner centre for an authenticated user have
   * been fetched, and false otherwise
   * @returns {boolean}
   */
  initialApiDataFetched() {
    const {user} = this.props;
    return user && this.hasVendorFetchCompleted() && this.hasComplianceFetchCompleted();
  }

  hasVendorFetchCompleted() {
    const {user, vendors} = this.props;
    /* Truthy if vendor information has been fetched (for dropdowns etc). Also true if the user doesn't have permission*/
    return (userHasPermission(user, VIEW_VENDOR) && vendors) || !userHasPermission(user, VIEW_VENDOR);
  }

  hasComplianceFetchCompleted() {
    const {user, vendorsComplianceFetched} = this.props;
    /* Truthy if compliance information has been fetched (for warnings etc). Also true if the user doesn't have permission */
    return (userHasPermission(user, VIEW_COMPLIANCE) && vendorsComplianceFetched) || !userHasPermission(user, VIEW_COMPLIANCE);
  }
}

const mapStateToProps = (store) => {

  return {
    userHasAuthenticated: store.user.userHasAuthenticated,
    user: store.user.user,

    vendorsFetchInProgress: store.vendor.vendorsFetchInProgress,
    vendorsComplianceFetchInProgress: store.vendor.vendorsComplianceFetchInProgress,
    userFetchInProgress: store.user.fetchingUser,

    vendorsFetched: store.vendor.vendorsFetched,
    vendorsComplianceFetched: store.vendor.vendorsComplianceFetched,

    vendors: store.vendor.vendors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ fetchVendors, fetchUserDetails, fetchVendorsCompliance }, dispatch)
  }
}

/**
 * Returns true if there is at least one vendor that's currently owned
 * (users that used to own vendors can still log into the partner centre)
 * @param vendors
 * @returns {boolean}
 */
function currentlyOwnsVendors(vendors) {
  return vendors.filter(vendor => vendor.currentlyOwnedByAuthenticatedOwner).size > 0;
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Routes));