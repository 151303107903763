import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from "react-router-dom";

const defaultExport = ({ children, store }) => (
    <Provider store={store}>
      <Router>
        { children }
      </Router>
    </Provider>
  )

export default defaultExport