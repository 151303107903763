export const SEVEN_DAYS = {
    filterName: "sevenDayFilter",
    headingText: "Orders for the last 7 days"
}
export const TODAYS = {
    filterName: "todaysDateFilter",
    headingText: "Orders for today"
}
export const MONTH = {
    filterName: "monthFilter",
    headingText: "Orders for the last month"
}
export const YEAR = {
    filterName: "yearFilter",
    headingText: "Orders for the last year"
}
export const CUSTOM = {
    filterName: "customFilter"
}