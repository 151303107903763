import React, { Component } from 'react';
import { Row, Col, Button, Alert } from 'reactstrap';
import ContractIcon from '../../../../images/icons/bank-details/contract.svg';
import Loader from 'react-loader-spinner';
export default class SignContract extends Component {

    componentDidMount() {
        this.props.fetchSigningUrl();
    }
    
    render() {
        return (
            <Row>
                <Col xs="12" sm="12" md="12" lg={{ size: 6, offset: 3 }} xl={{ size: 6, offset: 3 }}>
                    <Row className="row justify-content-center">
                        <Col xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                            <img src={ContractIcon} style={{ height: 80, width: 80 }} alt="Contract Icon" />
                        </Col>
                    </Row>
                    <Row className="row justify-content-center">
                        <Col xs="12" sm="12" md="10" lg="8" xl="8">
                            <div style={{ textAlign: "center" }}>
                                {!this.props.fetchingSigningUrl && !this.props.fetchSigningUrlError && <span>You will now be sent to another page to sign the contract which contains your new details.</span>}
                                {this.props.fetchingSigningUrl && !this.props.fetchSigningUrlError && <span>We are generating a contract which contains your new details.  Please wait.</span>}
                                {!this.props.fetchingSigningUrl && this.props.fetchSigningUrlError && <Alert color="danger">Something went wrong while generating the contract.  <button className="link-button black-link" onClick={() => this.props.fetchSigningUrl({ vendorID: this.props.vendorID, guid: this.props.guid })}>Click here</button> to retry.  If this problem continues then you can try again later or get in touch and we will help to resolve the issue.</Alert>}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" sm={{ size: 6, offset: 3 }} md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }} xl={{ size: 6, offset: 3 }}>
                    <div style={{ paddingTop: "20px" }} />
                    {!this.props.fetchingSigningUrl && !this.props.fetchSigningUrlError && <div>
                        <Button block className="btn-default" onClick={() => {
                            window.location.href = this.props.agreementSigningURL
                        }} style={{ cursor: "pointer" }}>
                            Sign Contract
                            </Button>
                        <div className="mt-1 w-100 text-right">
                            <button className="link-button black-link" onClick={() => this.props.cancelInFlightUpdate()}> Cancel</button>
                        </div>
                    </div>}
                    {this.props.fetchingSigningUrl && !this.props.fetchSigningUrlError &&
                        <div style={{ paddingBottom: "30px", textAlign: "center" }}>
                            <Loader type="Oval" color="#e7131a" height={50} width={50} />
                        </div>}
                </Col>
            </Row>
        )
    }
}
