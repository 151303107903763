import '../../css/Orders.css';

import React, { Component } from 'react';
import OrdersListing from "./orders-listing/OrdersListing";
import OrdersDateSelection from './OrdersDateSelection';
import OrdersSummary from './orders-summary/OrdersSummary';
import { Alert } from "reactstrap";
import {connect} from "react-redux";
import withRouter from "react-router-dom/withRouter";
import moment from "moment";

class Orders extends Component {

  render() {
      const { complianceEnabled, complianceDeadline, vendorsCompliance } = this.props
      return (
          <div className="mh-100 minh-100 orders-container" style={{overflow: "auto"}}>
              {
                  complianceEnabled &&
                  complianceDeadline &&
                  moment(complianceDeadline).isSameOrAfter(moment()) &&
                  vendorsCompliance.some(vendor => vendor.vendorComplianceStatus === "NOT_COMPLIANT" && ! vendor.setupPreviouslyCompleted) &&
                  <Alert color="warning" className="mt-2">
                      <strong>Action Required</strong>: To avoid being taken offline, please ensure your
                      account is <a className={"alert-link"} href={"/compliance"}>compliant</a> by {moment(complianceDeadline).format('L')}
                  </Alert>
              }
              {
                  complianceEnabled &&
                  complianceDeadline &&
                  moment(complianceDeadline).isBefore(moment()) &&
                  vendorsCompliance.some(vendor => vendor.vendorComplianceStatus === "NOT_COMPLIANT" && ! vendor.setupPreviouslyCompleted) &&
                  <Alert color="danger" className="mt-2">
                      <strong>Account Offline</strong>: To continue trading on Scoffable, please complete outstanding
                      actions in the <a className={"alert-link"} href={"/compliance"}>compliance</a> area
                  </Alert>
              }
              {
                  complianceEnabled &&
                  vendorsCompliance.some(vendor => vendor.vendorComplianceStatus === "NOT_COMPLIANT" && vendor.setupPreviouslyCompleted) &&
                  <Alert color="danger" className="mt-2">
                      <strong>Payouts Suspended</strong>: To resume payouts, please complete outstanding actions in
                      the <a className={"alert-link"} href={"/compliance"}>compliance</a> area
                  </Alert>
              }
              {
                  complianceEnabled &&
                  vendorsCompliance.some(vendor => vendor.vendorComplianceStatus === "FUTURE_NOT_COMPLIANT" && vendor.setupPreviouslyCompleted) &&
                  <Alert color="warning" className="mt-2">
                      <strong>Action Required</strong>: To avoid payouts being suspended, please ensure your
                      account is <a className={"alert-link"}
                                    href={"/compliance"}>compliant</a> by {moment(vendorsCompliance
                          .find(vendor => vendor.vendorComplianceStatus === "FUTURE_NOT_COMPLIANT" && vendor.setupPreviouslyCompleted)
                          .futureComplianceEnforcementDate)
                          .format('L')}
                  </Alert>
              }
              <OrdersDateSelection/>
              <OrdersSummary/>
              <OrdersListing/>
          </div>
      );
  }
}

const mapStateToProps = (store) => {
    return {
        complianceEnabled: store.vendor.complianceEnabled,
        complianceDeadline: store.vendor.complianceDeadline,
        vendorsCompliance: store.vendor.vendorsCompliance
    }
};

const connectedComponent = connect(mapStateToProps)(Orders)

export default withRouter(connectedComponent);
