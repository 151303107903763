import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import OtpCodeMinimal from './fields/OtpCodeMinimal';
import { Alert } from "reactstrap";

class OtpCodeForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      charCount: 0
    }
  }

  componentDidMount(){
    if(this.otpInput){
      this.otpInput.focus();
    }
  }

  shouldSubmit = (charCount) => {
    if(charCount === 4){
      this.props.handleSubmit();
    }
  }

  render() {
    const { otp, resendingNewDetailsOtpsErrors, resendOtpToNewDetails, fieldError } = this.props;
    let displayNewDetailsType;
    if(this.props.newDetailsType === "TELEPHONE_NUMBER"){
        displayNewDetailsType = "contact number";
    } else if(this.props.newDetailsType === "EMAIL_ADDRESS"){
        displayNewDetailsType = "email address";
    }
    return (
      <form onSubmit={this.props.handleSubmit} style={{marginBottom: "0"}}>
        {otp.verified && <div style={{textAlign: "center", marginTop: "20px"}}><span>Your <span style={{fontWeight: "bold"}}>{displayNewDetailsType}</span> has been </span><span style={{color: "green"}}>verified</span> </div>}
        {!otp.verified && <Field name="otpCode" type="text" component={OtpCodeMinimal} otp={otp} newDetailsType={otp.detailsUpdateType} shouldSubmit={this.shouldSubmit} resendOtpToNewDetails={resendOtpToNewDetails}
        fieldError={fieldError}/>}
        {resendingNewDetailsOtpsErrors.has(otp.guid) && <Alert color="warning">
                        {resendingNewDetailsOtpsErrors.get(otp.guid, "Unable to send verification code.  Please try again.")}
                    </Alert>}
                            <Field
                                name="requestGuid"
                                val={this.props.otp.requestGuid}
                                component={({ input, val }) => {
                                    input.onChange(val)
                                    return <div />
                                }}  />
                                <Field
                                name="otpGuid"
                                val={this.props.otp.guid}
                                component={({ input, val }) => {
                                    input.onChange(val)
                                    return <div />
                                }}  />
      </form>
    )
  }
}

export default reduxForm()(OtpCodeForm)