export function fetchOrderStats(payload) {
    return {
        type: 'FETCH_ORDER_STATS',
        payload: payload
    }
}

export function fetchOrderStatsSuccess(payload) {
    return {
        type: "FETCH_ORDER_STATS_SUCCESS",
        payload: payload
    }
}

export function fetchOrderStatsFailed(error) {
    return {
        type: "FETCH_ORDER_STATS_FAILED",
        payload: error
    }
}

export function clearOrderStats() {
    return {
        type: "CLEAR_ORDER_STATS"
    }
}

export function fetchOrders(payload) {
    return {
        type: "FETCH_ORDERS",
        payload: payload
    }
}

export function fetchOrdersSuccess(payload) {
    return {
        type: "FETCH_ORDERS_SUCCESS",
        payload: payload
    }
}

export function fetchOrdersFailed(error) {
    return {
        type: "FETCH_ORDERS_FAILED",
        payload: error
    }
}

export function clearOrders(){
    return {
        type: "CLEAR_ORDERS"
    }
}

export function fetchOrderDetails(payload){
    return {
        type: "FETCH_ORDER_DETAILS",
        payload: payload
    }
}

export function fetchOrderDetailsSuccess(payload){
    return {
        type: "FETCH_ORDER_DETAILS_SUCCESS",
        payload: payload
    }
}

export function fetchOrderDetailsFailed(error){
    return {
        type: "FETCH_ORDER_DETAILS_FAILED",
        payload: error
    }
}

export function changeActiveFilter(payload){
    return {
        type: "CHANGE_ACTIVE_FILTER",
        payload: payload
    }
}

export function updateOrdersListEndDate(payload){
    return {
        type: "UPDATE_ORDERS_LIST_END_DATE",
        payload: payload
    }
}

export function updateOrdersListStartDate(payload){
    return {
        type: "UPDATE_ORDERS_LIST_START_DATE",
        payload: payload
    }
}

export function updateOrdersListOrdersPerPage(payload){
    return {
        type: "UPDATE_ORDERS_LIST_ORDERS_PER_PAGE",
        payload: payload
    }
}

export function toggleOrderSummaryView(){
    return {
        type: "TOGGLE_ORDERS_SUMMARY_VIEW"
    }
}

export function updateOrdersListActivePage(payload){
    return {
        type: "UPDATE_ORDERS_LIST_ACTIVE_PAGE",
        payload: payload
    }
}

export function showOrders(){
    return {
        type: "SHOW_ORDER_LIST_VIEW"
    }
}

export function showOrderStats(){
    return {
        type: "SHOW_ORDER_STATS_VIEW"
    }
}