import React from 'react';

const OrdersQuestions = () => {
    return([
        {
            question: "How do I re-print an order",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230, where a member of staff will immediately re-send the order to your Scoffable printer.</p>
        },
        {
            question: "How do I change my delivery pricing",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 and let a member of staff know of the new delivery charges, these will be updated as soon as possible (usually within 1 hour).</p>
        },
        {
            question: "How do vouchers work",
            answer: <p>Gift vouchers redeemed by customers are often issued for marketing purposes, as well as competition winners. If issued by Scoffable then these will be fully reimbursed to you in the next available invoice. If you have any questions or concerns please contact our Customer Support Team on 0131 208 2230 where we will be happy to further explain this process.</p>
        },
        {
            question: "How do I take an order off my account",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 where we can arrange to remove an order from your account, and ensure that you are not charged any commission on the affected order.</p>
        },
        {
            question: "If I turn my printer off does it put me offline",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 if you require to go offline, as turning the Scoffable printer off will not affect your availability on the website.</p>
        }
    ])
}

export default OrdersQuestions;
