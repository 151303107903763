const COMMA = ", ";
const FROM_BEGINNING = 0;
const LAST_COMMA = -2;

const defaultExport = (props) => {
    const { address } = props;
    let addressString = "";
    if(!address) {
        return addressString;
    }
    if (address.organisation) {
        addressString = addressString.concat(address.organisation + COMMA);
    }
    if (address.firstLine) {
        addressString = addressString.concat(address.firstLine + COMMA);
    }
    if (address.secondLine) {
        addressString = addressString.concat(address.secondLine + COMMA);
    }
    if (address.locality) {
        addressString = addressString.concat(address.locality + COMMA);
    }
    if (address.town) {
        addressString = addressString.concat(address.town + COMMA);
    }
    if (address.county) {
        addressString = addressString.concat(address.county + COMMA);
    }
    if (address.postcode) {
        addressString = addressString.concat(replaceSpaceWithNbsp(address.postcode) + COMMA);
    }
    if(addressString){
        addressString = addressString.slice(FROM_BEGINNING, LAST_COMMA);
    }
    return addressString
}

export default defaultExport

function replaceSpaceWithNbsp(string){
    return string.replace(/ /g, '\u00a0');
}