import React, { Component } from "react";
import ContractIcon from "../../../../images/icons/bank-details/contract.svg";
import { Row, Col, Button, Alert } from "reactstrap";
import Loader from 'react-loader-spinner';

class SignContract extends Component {

    componentDidMount() {
        const { vendorID, guid } = this.props;
        this.props.fetchSigningUrl({ vendorID, guid });
    }

    render() {
        // eslint-disable-next-line eqeqeq
        return (
            <Row>
                <Col xs="12" sm="12" md="12" lg={{ size: 6, offset: 3 }} xl={{ size: 6, offset: 3 }}>
                    <Row className="row justify-content-center">
                        <Col xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                            <img src={ContractIcon} style={{ height: 80, width: 80 }} alt="Contract Icon" />
                        </Col>
                    </Row>
                    <Row className="row justify-content-center">
                        <Col xs="12" sm="12" md="10" lg="8" xl="8">
                            <div style={{ textAlign: "center" }}>
                                {!this.props.fetchingSigningUrl && !this.props.fetchSigningUrlError && <span id={"bank-details-contract-sign-instructions-text"}>You will now be sent to another page to sign the contract which contains your new bank details.</span>}
                                {this.props.fetchingSigningUrl && !this.props.fetchSigningUrlError && <span id={"bank-details-contract-generation-text"}>We are generating a contract which contains your new bank details. This could take up to a minute. Please bear with us.</span>}
                                {!this.props.fetchingSigningUrl && this.props.fetchSigningUrlError && <Alert color="danger">Something went wrong while generating the contract.  
                                <button className="link-button alert-link" onClick={(e) => {
                                    e.preventDefault()
                                    this.props.fetchSigningUrl({ vendorID: this.props.vendorID, guid: this.props.guid })
                                }}>  Click here to retry</button>.  If this problem continues then you can try again later or get in touch and we will help to resolve the issue.  Alternatively <button className="link-button alert-link" onClick={(e) => {
                                    e.preventDefault()
                                    this.props.handleCancel({ vendorID: this.props.vendorID, guid: this.props.guid })
                                }}>Click here to cancel</button> this bank details update.</Alert>}
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col xs="12" sm={{ size: 6, offset: 3 }} md={{ size: 6, offset: 3 }} lg={{ size: 6, offset: 3 }} xl={{ size: 6, offset: 3 }}>
                    <div style={{ paddingTop: "20px" }} />
                    {!this.props.fetchingSigningUrl && !this.props.fetchSigningUrlError && <div>
                        <Button block className="btn-default" onClick={() => {
                            window.location.href = this.props.agreementSigningURL
                        }} style={{cursor:"pointer"}}>
                            Sign Contract
                            </Button>
                        <div className="mt-1 w-100 text-right">
                            <button className="link-button black-link" onClick={() => this.props.handleCancel({ vendorID: this.props.vendorID, guid: this.props.guid })}>Cancel</button>
                        </div>
                    </div>}
                    {this.props.fetchingSigningUrl && !this.props.fetchSigningUrlError &&
                    <div style={{paddingBottom: "30px", textAlign: "center"}}>
                        <Loader type="Oval" color="#e7131a" height={50} width={50} />
                    </div>}
                </Col>
            </Row>
        );
    }
}
export default SignContract