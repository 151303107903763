import '../css/Login.css';

import React, { Component } from 'react';
import { Container, Form, FormGroup, Input, Alert } from 'reactstrap';
import {connect} from "react-redux";

import LoaderButton from '../components/LoaderButton';
import logoImg from '../images/scoffable_partner_centre_logo.svg';
import { bindActionCreators } from 'redux';
import { requestPasswordResetEmail, requestRegistrationEmail } from "../actions/UserActions";


class PasswordResetRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoading: false,
        email: "",
        telephone: "",
        isResetPasswordError: false
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    if(this.props.registerMode) {
      this.props.actions.requestRegistrationEmail({
        email: this.state.email,
        telephone: this.state.telephone
      });
    } else {
      this.props.actions.requestPasswordResetEmail({
        email: this.state.email,
        telephone: this.state.telephone
      });
    }
  }

  render() {

    let dynamicDisplay;
    let passwordResetDescription;
    if(this.props.registerMode){
      passwordResetDescription = "To create a Scoffable Partner Centre account, please enter the email address and telephone number you registered with Scoffable when signing up.";
    }
    else{
      passwordResetDescription = "To reset your password, please provide your registered email address and mobile phone number below.";
    }
    if(!this.props.passwordResetRequestSent){
      dynamicDisplay = (
        <Form onSubmit={this.handleSubmit}>
        <div className="alert alert-light p-0 m-2 text-center" role="alert">{passwordResetDescription}</div>
        {this.props.error && this.props.error.map((errorMessage, index) => <Alert key={index} color="danger">{errorMessage}</Alert>) }
        <FormGroup>
            <Input type="email" 
              name="email" 
              id="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="Email" />
        </FormGroup>
        <FormGroup>
            <Input type="telephone" 
              name="Mobile Phone Number" 
              id="telephone"
              value={this.state.telephone}
              onChange={this.handleChange}
              placeholder="Mobile Phone Number" />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          isLoading={this.props.resetPasswordInProgress}
          text={this.props.registerMode ? "Create Account" : "Reset Password"}
          loadingText={this.props.registerMode ? "Creating Account..." : "Sending Reset Request..."}
          className="btn-default"
          />
      </Form>
      );
    }
    else{
      if(this.props.registerMode){
        passwordResetDescription = "A confirmation email has been sent to your email address, please follow the link in this email to confirm your account and to set a password.";
      }
      else{
        passwordResetDescription = "A password reset link has been sent to your email address. follow this link to complete your password reset.";
      }

      dynamicDisplay = (
        <Container>
          <div className="alert alert-light p-0 m-2 text-center" role="alert">{passwordResetDescription}</div>
        </Container>
      );

    }

    return (
      <div className="container login-container">
        <div className="card login-card">
          <div className="card-body">
            <div className="Login">
              <div className="logo-container">
                <img alt="login" src={logoImg} className="login-logo"/>
              </div>
              {dynamicDisplay}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    passwordResetRequestSent: store.user.passwordResetRequestSent,
    error: store.user.error,
    resetPasswordInProgress: store.user.resetPasswordInProgress
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({requestPasswordResetEmail, requestRegistrationEmail}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordResetRequest);