import React from "react";
import "../../css/VendorLogoAddressView.css";

const COMMA = ", ";
const FROM_BEGINNING = 0;
const LAST_COMMA = -2;

export default class FirstLinePostcode extends React.Component {

    render() {
        const {address} = this.props;

        let addressString = "";
        if (!address) {
            return addressString;
        }

        if (address.firstLine) {
            addressString = addressString.concat(address.firstLine + COMMA);
        }

        /* We've got an exceptionally dumb thing where the vendor town has forever been put in the secondLine field.
         * So default to second line, and only fall back to town if second line is blank
         */
        if(address.secondLine){
            addressString = addressString.concat(address.secondLine + COMMA)
        } else {
            if(address.town) {
                addressString = addressString.concat(address.town + COMMA)
            }
        }
        if (address.postcode) {
            addressString = addressString.concat(replaceSpaceWithNbsp(address.postcode) + COMMA);
        }
        if (addressString) {
            addressString = addressString.slice(FROM_BEGINNING, LAST_COMMA);
        }

        return (
            <div data-cy="vendor-address" className="vendor_address mt-0">{addressString}</div>
        );
    }
}

function replaceSpaceWithNbsp(string){
    return string.replace(/ /g, '\u00a0');
}