/* eslint-disable eqeqeq */
import { put, takeLeading, throttle } from 'redux-saga/effects';
import * as UserActions from '../../actions/UserActions';
import * as Sentry from '@sentry/browser';
import {setCookies, removeCookies, getCookies} from '../../utils/cookie/CookieManager';
import ApiClientProvider from '../../utils/api/ApiClientProvider';
import Raven from "raven-js";

function* login(action) {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        let request = {
            emailAddress: action.payload.email,
            password: action.payload.password
        }

        const {data} = yield apiInstance.createUserLoginSessionUsingPOST({creationDetails: request})
        console.log(data)
        yield put(UserActions.loginSuccess({loginSessionId: data.id}));
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put(UserActions.loginFailed({ message: "Unable to process login request" }));
            return;
        }
        if (e.response.status === 400 || e.response.status === 401 || e.response.status === 403 || e.response.status === 404 ) {
            yield put(UserActions.loginFailed({ message: "Incorrect username/password." }));
        }
        else {
            Sentry.captureException(e);
            yield put(UserActions.loginFailed({ message: "Unable to process login request." }));
        }
    }
}


function* selectLoginAccount(action) {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        let request = {
            loginSessionId: action.payload.loginSessionId,
            accountId: { value: action.payload.accountId }
        }

        const {data} = yield apiInstance.setLoginAccountUsingPUT(request)

        if(data.authenticationToken?.token) {
            // User authenticated .. set cookies and take user to orders
            setCookies({
                authToken: data.authenticationToken.token,
                userId: data.authenticationToken.userId
            });
            yield put({type: UserActions.selectLoginAccountSuccess().type, userHasAuthenticated: true});
            if (action.payload.redirect) {
                action.payload.push(action.payload.redirect)
                return;
            }
            action.payload.push('/orders');
        } else {
            yield put(UserActions.selectLoginAccountFailed({loginSessionId: data.id}));
        }
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put(UserActions.loginFailed({ message: "Unable to process login request" }));
            return;
        }
        if (e.response.status === 400 || e.response.status === 401 || e.response.status === 403 || e.response.status === 404 ) {
            yield put(UserActions.loginFailed({ message: "Incorrect username/password." }));
        }
        else {
            Sentry.captureException(e);
            yield put(UserActions.loginFailed({ message: "Unable to process login request." }));
        }
    }
}


function* resendLoginOTP(action) {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        const {data} = yield apiInstance.createOtpSendRequestUsingPOST({
            loginSessionId: action.payload.loginSessionId,
        });
        yield put(UserActions.loginSuccess({
            loginSessionId: data.loginSessionId
        }));
    } catch (e) {
        if(!e.response){
            console.log(e)
            Sentry.captureException(e);
            yield put(UserActions.loginFailed({ message: "Unable to process login request" }));
            return;
        }
        if (e.response.status === 429){
            yield put(UserActions.loginFailed({ message: e.response.data.message }));
        }
        else {
            Sentry.captureException(e);
            yield put(UserActions.loginFailed({ message: "Incorrect username/password." }));
        }
    }
}

//  worker Saga: will be fired on 'LOGOUT' actions
function* logout() {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        removeCookies();
        yield apiInstance.logoutUserUsingPOST();
        yield put({ type: UserActions.logoutSuccess().type });
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: UserActions.logoutFailed().type, message: "Unable to process logout request" });
            return;
        }
        if(e.response.status === 401){
            yield put({ type: UserActions.logoutSuccess().type });
            return;
        }
        Sentry.captureException(e);
        yield put({ type: UserActions.logoutFailed().type, message: e.message });
    }
}

function* verifyOTP(action) {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        let requestParams = {
            loginSessionId: action.payload.loginSessionId,
            otpCode: { value: action.payload.otpCode }
        };

        const {data} = yield apiInstance.setOtpCodeUsingPUT(requestParams);

        if(data.authenticationToken?.token) {
            // User authenticated .. set cookie and take user to orders
            setCookies({
                authToken: data.authenticationToken.token,
                userId: data.authenticationToken.userId
            });
            yield put({type: UserActions.verifyOTPSucccess().type, userHasAuthenticated: true});
            if (action.payload.redirect) {
                action.payload.push(action.payload.redirect)
                return;
            }
            action.payload.push('/orders');
        } else if(data.linkedAccounts.length > 0){
                yield put({
                    type: UserActions.verifyOTPSucccess().type,
                    linkedAccounts: data.linkedAccounts,
                    userHasAuthenticated: false
                })
        }
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put(UserActions.verifyOTPFailed({ message: "Unable to process OTP request." }));
            return;
        }
       if(e.response.status === 403 || e.response.status === 401) {
            yield put(UserActions.verifyOTPFailed({ message: e.response.data.message }));
        } else {
            Sentry.captureException(e);
            yield put(UserActions.verifyOTPFailed({ message: "Code could not be verified." }));
        }
    }
}

function* requestRegistrationEmail(action) {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        if(!action.payload.telephone || !action.payload.email){
            yield put({ type: UserActions.requestRegistrationEmailFailed().type, message: [!action.payload.email && "Email address must be provided.", !action.payload.telephone && "Phone number must be provided."] });
        }
        yield apiInstance.createUserRegistrationSessionUsingPOST({
            creationDetails: {
                telephoneNumber: action.payload.telephone.trim(),
                emailAddress: action.payload.email.trim()
            }
        });
        yield put({ type: UserActions.requestRegistrationEmailSuccess().type });
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: UserActions.requestRegistrationEmailFailed().type, message: ["Unable to start registration."] });
            return;
        }
        if(e.response.status === 400){
            const errorMessages = e.response.data.fieldErrors.map(fieldError => fieldError.message);
            yield put(UserActions.requestRegistrationEmailFailed(errorMessages))
            return;
        }
        if(e.response.status === 401){
            yield put(UserActions.logout());
            return;
        }
        if(e.response.status === 404){
            yield put(UserActions.requestRegistrationEmailFailed(["We can't find those details in our system.  Note that you must use the same email and password provided during signup.  If you continue to have problems getting started please give our customer service team a call."]))
            return;
        }
        if(e.response.status === 409){
            yield put(UserActions.requestRegistrationEmailFailed(["There is already a registered account with these details. Please login instead."]))
            return;
        }
        Sentry.captureException(e);
        yield put({ type: UserActions.requestRegistrationEmailFailed().type, message: [e.message] });
    }
}

function* requestPasswordResetEmail(action) {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        if(!action.payload.telephone || !action.payload.email){
            yield put({ type: UserActions.requestPasswordResetEmailFailed().type, message: [!action.payload.email && "Email address must be provided.", !action.payload.telephone && "Phone number must be provided."] });
        }
        yield apiInstance.createUserPasswordResetSessionUsingPOST({
                creationDetails: {
                    telephoneNumber: action.payload.telephone.trim(),
                    emailAddress: action.payload.email.trim()
                }
            });
        yield put({ type: UserActions.requestPasswordResetEmailSuccess().type });
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put({ type: UserActions.requestPasswordResetEmailFailed().type, message: ["Unable to process password reset request."] });
            return;
        }
        if(e.response.status === 400){
            const errorMessages = e.response.data.fieldErrors.map(fieldError => fieldError.message);
            yield put(UserActions.requestPasswordResetEmailFailed(errorMessages))
            return;
        }
        if(e.response.status === 401){
            yield put(UserActions.logout());
            return;
        }
        if(e.response.status === 404){
            yield put(UserActions.requestPasswordResetEmailFailed(["We can't find those details in our system.  Note that you must use the same email and password provided during signup.  If you continue to have problems getting started please give our customer service team a call."]))
            return;
        }
        Sentry.captureException(e);
        yield put({ type: UserActions.requestPasswordResetEmailFailed().type, message: [e.message] });
    }
}

function* handleSetPasswordError(e) {
    if (!e.response) {
        Sentry.captureException(e);
        console.log(e)
        yield put({type: UserActions.setPasswordFailed().type, payload: "Unable to process password reset request."});

    } else if (e.response.status === 401) {
        yield put(UserActions.logout());
    } else if (e.response.status === 404) {
        yield put(UserActions.passwordResetCodeExpired());
    } else {
        Sentry.captureException(e);
        console.log(e);
        yield put({
            type: UserActions.setPasswordFailed().type,
            payload: "Unable to process password reset request."
        });
    }
}

function* register(action) {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        const {data} = yield apiInstance.completeRegistrationUsingPUT({
            registrationSessionId: action.payload.registrationSessionId,
            registrationCompletionDetails: {
                emailVerificationCode: action.payload.passwordResetKey,
                newPassword: action.payload.password
            }
        });
        yield put({ type: UserActions.setPasswordSuccess().type, loginSessionId: data.loginSessionId });
        action.payload.push('/login?passwordReset=true');
    } catch (e) {
        yield* handleSetPasswordError(e);
    }
}


function* resetPassword(action) {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        const {data} = yield apiInstance.completeUserPasswordResetUsingPUT({
            passwordResetSessionId: action.payload.passwordResetSessionId,
            passwordResetCompletionDetails: {
                emailVerificationCode: action.payload.passwordResetKey,
                newPassword: action.payload.password
            }
        });
        yield put({ type: UserActions.setPasswordSuccess().type, loginSessionId: data.loginSessionId });
        action.payload.push('/login?passwordReset=true');
    } catch (e) {
        yield* handleSetPasswordError(e);
    }
}

function* fetchUserDetails() {
    try {
        let apiInstance = ApiClientProvider.getUserControllerApiInstance();
        const {data} = yield apiInstance.getUserUsingGET({userId: getCookies().userId});
        Raven.setUserContext({
            email: data.emailAddress,
            id: data.id
        })
        Sentry.setUser({email: data.emailAddress, id: data.id});
        yield put(UserActions.fetchUserDetailsSuccess(data));
    } catch (e) {
        if (!e.response) {
            Sentry.captureException(e);
            yield put(UserActions.fetchUserDetailsFailed({ message: "Unable to process request for user details" }));
            return;
        }
        if(e.response.status === 401){
            yield put(UserActions.logout());
            return;
        }

        console.log(e)
        Raven.captureException(e);
        yield put(UserActions.fetchUserDetailsFailed());

    }
}

export default function* rootUserSaga() {
    yield throttle(3000, UserActions.fetchUserDetails().type, fetchUserDetails);
    yield takeLeading(UserActions.login().type, login);
    yield takeLeading(UserActions.logout().type, logout);
    yield takeLeading(UserActions.verifyOTP().type, verifyOTP);
    yield takeLeading(UserActions.selectLoginAccount().type, selectLoginAccount);
    yield takeLeading(UserActions.requestRegistrationEmail().type, requestRegistrationEmail);
    yield takeLeading(UserActions.requestPasswordResetEmail().type, requestPasswordResetEmail);
    yield takeLeading(UserActions.setPassword().type, resetPassword);
    yield takeLeading(UserActions.register().type, register);
    yield takeLeading(UserActions.resendLoginOTP().type, resendLoginOTP);
}
/* eslint-enable eqeqeq */