import React from 'react';
import PoorEmoji from '../../../images/reviews/emoji_Terrible.svg';

const EmojiPoor = ({size, style, ...rest}) => {
    console.log(size)
    return (
        <img src={PoorEmoji} style={{height: `${size}px`, width: `${size}px`, ...style}} alt={rest.alt} {...rest}/>
    )
}

export default EmojiPoor;