export function login(payload) {
    return {
      type: 'LOGIN',
      payload: payload
    }
  }

  export function loginSuccess(payload) {
      return {
          type: "LOGIN_SUCCESS",
          payload: payload
      }
  }

export function loginFailed(error) {
    return {
        type: "LOGIN_FAILED",
        payload: error
    }
}

export function logout() {
    return {
      type: 'LOGOUT'
    }
  }

  export function logoutSuccess() {
      return {
          type: "LOGOUT_SUCCESS"
      }
  }

export function logoutFailed(error) {
    return {
        type: "LOGOUT_FAILED",
        payload: error
    }
}

export function resendOTP(){
    return {
        type: "RESEND_OTP"
    }
}

export function verifyOTP(payload){
    return {
        type: "VERIFY_OTP",
        payload: payload
    }
}

export function verifyOTPSucccess(token){
    return {
        type: "VERIFY_OTP_SUCCESS",
        payload: token
    }
}

export function verifyOTPFailed(error){
    return {
        type: "VERIFY_OTP_FAILED",
        payload: error
    }
}

export function cancelOTP(){
    return {
        type: "CANCEL_OTP"
    }
}

export function storePasswordResetKey(resetKey){
    return {
        type: "STORE_PASSWORD_RESET_KEY",
        payload: resetKey
    }
}

export function requestPasswordResetEmail(payload){
    return {
        type: "REQUEST_PASSWORD_RESET_EMAIL",
        payload: payload
    }
}

export function requestRegistrationEmail(payload){
    return {
        type: "REQUEST_REGISTRATION_EMAIL",
        payload: payload
    }
}

export function requestRegistrationEmailSuccess(payload){
    return {
        type: "REQUEST_REGISTRATION_EMAIL_SUCCESS",
        payload: payload
    }
}

export function requestRegistrationEmailFailed(payload){
    return {
        type: "REQUEST_REGISTRATION_EMAIL_FAILED",
        payload: payload
    }
}

export function requestPasswordResetEmailSuccess(payload){
    return {
        type: "REQUEST_PASSWORD_RESET_EMAIL_SUCCESS",
        payload: payload
    }
}

export function requestPasswordResetEmailFailed(payload){
    return {
        type: "REQUEST_PASSWORD_RESET_EMAIL_FAILED",
        payload: payload
    }
}

export function setPassword(payload){
    return {
        type: "SET_PASSWORD",
        payload: payload
    }
}

export function setPasswordSuccess(payload){
    return {
        type: "SET_PASSWORD_SUCCESS",
        payload: payload
    }
}

export function setPasswordFailed(error){
    return {
        type: "SET_PASSWORD_FAILED",
        payload: error
    }
}

export function register(payload){
    return {
        type: "REGISTER",
        payload: payload
    }
}

export function registerSuccess(payload){
    return {
        type: "REGISTER_SUCCESS",
        payload: payload
    }
}

export function registerFailed(error){
    return {
        type: "REGISTER_FAILED",
        payload: error
    }
}



export function resendLoginOTP(payload){
    return {
        type: "RESEND_LOGIN_OTP",
        payload: payload
    }
}

export function passwordResetCodeExpired(){
    return {
        type: "PASSWORD_RESET_CODE_EXPIRED"
    }
}

export function selectLoginAccount(payload){
    return {
        type: "SELECT_LOGIN_ACCOUNT",
        payload: payload
    }
}

export function selectLoginAccountSuccess(payload){
    return {
        type: "SELECT_LOGIN_ACCOUNT_SUCCESS",
        payload: payload
    }
}

export function selectLoginAccountFailed(payload){
    return {
        type: "SELECT_LOGIN_ACCOUNT_FAILED",
        payload: payload
    }
}

export function fetchUserDetails(payload){
    return {
        type: "FETCH_USER_DETAILS",
        payload: payload
    }
}

export function fetchUserDetailsSuccess(payload){
    return {
        type: "FETCH_USER_DETAILS_SUCCESS",
        payload: payload
    }
}

export function fetchUserDetailsFailed(error){
    return {
        type: "FETCH_USER_DETAILS_FAILED",
        payload: error
    }
}
