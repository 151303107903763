import React from 'react';
import OrderReviewSummary from '../../images/reviews/order-review-summary.png';
import { Link } from 'react-router-dom';
import ChooseScoreEmail from '../../images/reviews/rate-order-email.png';
import RateOrderForm from '../../images/reviews/rate-order-form.png';

const ReviewsQuestions = ({isMobile}) => {
    return [
        {
            question: "What is a review",
            answer: <div>
                <p>A review represents an individual rating given by a customer for an order placed to your takeaway.  There are four possible ratings (Terrible, Bad, Good, Great).</p>
                <ul style={{ listStyleType: "circle" }}>
                    <li>Terrible and Bad are considered to be negative ratings.</li>
                    <li>Great and Good are considered to be positive ratings.</li>
                </ul>
                <p>The customer can decide to include a message alongside their rating, which you will be able to view.</p>
            </div>
        },
        {
            question: "What do the customers see",
            answer: <div>
                <p>After a customer places an order, a few hours later they will receive an email inviting them to rate their experience (see the example below).</p>
                <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                    <img src={ChooseScoreEmail} style={{ width: isMobile ? "100%" : "70%",  border: "1px solid #000" }} alt="email screenshot" />
                </div>
                <p>When a customer selects a rating, it will be recorded and they will be given the opportunity to include a message providing more detailed feedback if they so wish.</p>
                <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                    <img src={RateOrderForm} alt="review form screenshot" style={{ width: isMobile ? "100%" : "70%", border: "1px solid #000" }} />
                </div>
            </div>
        },
        {
            question: "How is my Customer Satisfaction Summary calculated",
            answer: <div>
                <p>In the <Link to="/order-reviews">Order Reviews section of Partner Centre</Link>, a Customer Satisfaction Summary will be provided for each takeaway that you own (an example is shown below).</p>
                <div style={{ textAlign: "center", marginTop: "10px", marginBottom: "10px" }}>
                    <img src={OrderReviewSummary} alt="Customer Satisfaction Summary Screenshot" style={{ width: isMobile ? "100%" : "50%", border: "1px solid #000" }} />
                </div>
                <p>The percentage satisfied indicator represents the percentage of reviews that were positive.  In the above example, 83% of customers were satisfied with the orders that they received.</p>
            </div>
        },
        {
            question: "Do reviews expire",
            answer: <p>No - reviews do not expire, but in most circumstances your overall review score will only incorporate reviews from the last 3 months, however this period will be extended if there aren't enough reviews to generate a reliable score.</p>
        },
        {
            question: "Why doesn’t my takeaway have a Customer Satisfaction Summary",
            answer: <p>To ensure that we present a fair and balanced summary of your takeaway, we require at least ten customer reviews (left in the last year) before we calculate a summary.</p>
        },
        {
            question: "Will a customer be able to leave a review for a cancelled order",
            answer: <p>No, customers can only leave reviews for orders that you have accepted.</p>
        },
        {
            question: "Can a customer leave a review without placing an order",
            answer: <p>No, customers are invited to leave a review after placing an order which you have accepted.</p>
        },
        {
            question: "How can I improve my satisfaction rating",
            answer: <p>Your first step should be to read any reviews that include a message.  This will enable you to identify specific issues and determine if there is a common pattern that customers are not happy with.  If you would like some help with this, our customer support team will be happy to take your call.</p>
        },
        {
            question: "Can I reply to reviews",
            answer: <p>If the customer has included a message with their review, you can reply to it in the <Link to="/order-reviews">reviews section of Partner Centre</Link>. </p>
        }
    ]
}

export default ReviewsQuestions;