import React, { Component } from 'react';
import ReviewsCard from './ReviewsCard';
import NoReviews from './NoReviews';
import moment from 'moment';
import "moment/locale/en-gb";
import Masonry from 'react-masonry-css';

moment.locale("en-gb");

export default class ReviewsCards extends Component {

    render() {
        const { reviews, replyToOrderReview, orderReviewsSubmitting, vendors, deleteOrderReviewReply, editOrderReviewReply, orderReviewReplyErrors, clearOrderReviewReplyError, showVendor, user } = this.props;
        if(reviews === null || reviews.size === 0){
            return <NoReviews />
          }
          const reviewsToRender = reviews.toJS().sort((o1, o2) => {
			if(o1.submittedDate == null && o2.submittedDate !== null){
				return moment(o1.firstCreatedDate).isBefore(o2.submittedDate) ? 0 : -1 
			}
			if(o2.submittedDate == null && o1.submittedDate !== null){
				return moment(o1.submittedDate).isBefore(o2.firstCreatedDate) ? 0 : -1;
			}
			if(o1.submittedDate == null && o2.submittedDate == null){
				return moment(o1.firstCreatedDate).isBefore(o2.firstCreatedDate) ? 0 : -1;
            }
			return moment(o1.submittedDate).isBefore(o2.submittedDate) ? 0 : -1;
		}).map((review) =>
          <ReviewsCard
              replyToOrderReview={replyToOrderReview} 
              orderReviewsSubmitting={orderReviewsSubmitting} 
              vendor={vendors.get(review.vendorId)}
              {...review} 
              deleteOrderReviewReply={deleteOrderReviewReply}
              editOrderReviewReply={editOrderReviewReply}
              orderReviewReplyError={orderReviewReplyErrors.get(review.id)}
              clearOrderReviewReplyError={clearOrderReviewReplyError}
              showVendor={showVendor}
              user={user}/>
              );

        return (<Masonry breakpointCols={{default: 2, 900: 1}} className="review-cards" columnClassName="review-cards-column">
                {reviewsToRender}
            </Masonry>
            )

    
    }
}