export function fetchOrderReviews(payload){
    return {
        type: "FETCH_ORDER_REVIEWS",
        payload: payload
    }
}

export function fetchOrderReviewsSuccess(payload){
    return {
        type: "FETCH_ORDER_REVIEWS_SUCCESS",
        payload: payload
    }
}

export function fetchOrderReviewsFailed(error){
    return {
        type: "FETCH_ORDER_REVIEWS_FAILED",
        payload: error
    }
}

export function updateReviewsActivePage(payload){
    return {
        type: "UPDATE_REVIEWS_ACTIVE_PAGE",
        payload: payload
    }
}

export function updateReviewsPerPage(payload){
    return {
        type: "UPDATE_REVIEWS_PER_PAGE",
        payload: payload
    }
}

export function updateReviewSentiment(payload){
    return{
        type: "UPDATE_REVIEW_SENTIMENT",
        payload: payload
    }
}

export function orderReviewsDisabled(){
    return {
        type: "ORDER_REVIEWS_DISABLED"
    }
}

export function replyToOrderReview(payload){
    return {
        type: "REPLY_TO_ORDER_REVIEW",
        payload: payload
    }
}

export function replyToOrderReviewSuccess(payload){
    return {
        type: "REPLY_TO_ORDER_REVIEW_SUCCESS",
        payload: payload
    }
}

export function replyToOrderReviewFailed(error){
    return {
        type: "REPLY_TO_ORDER_REVIEW_FAILED",
        payload: error
    }
}

export function editOrderReviewReply(payload){
    return {
        type: "EDIT_ORDER_REVIEW_REPLY",
        payload: payload
    }
}

export function editOrderReviewReplySuccess(payload){
    return {
        type: "EDIT_ORDER_REVIEW_REPLY_SUCCESS",
        payload: payload
    }
}

export function editOrderReviewReplyFailed(error){
    return {
        type: "EDIT_ORDER_REVIEW_REPLY_FAILED",
        payload: error
    }
}

export function deleteOrderReviewReply(payload){
    return {
        type: "DELETE_ORDER_REVIEW_REPLY",
        payload: payload
    }
}

export function deleteOrderReviewReplySuccess(payload){
    return {
        type: "DELETE_ORDER_REVIEW_REPLY_SUCCESS",
        payload: payload
    }
}

export function deleteOrderReviewReplyFailed(error){
    return {
        type: "DELETE_ORDER_REVIEW_REPLY_FAILED",
        payload: error
    }
}

export function fetchOrderReviewData(payload){
    return {
        type: "FETCH_ORDER_REVIEW_DATA",
        payload: payload
    }
}

export function fetchOrderReviewDataSuccess(payload){
    return {
        type: "FETCH_ORDER_REVIEW_DATA_SUCCESS",
        payload: payload
    }
}

export function fetchOrderReviewDataFailed(error){
    return {
        type: "FETCH_ORDER_REVIEW_DATA_FAILED",
        payload: error
    }
}

export function clearOrderReviewReplyError(payload){
    return {
        type: "CLEAR_ORDER_REVIEW_REPLY_ERROR",
        payload: payload
    }
}