import '../../css/Compliance.css';

import React, { Component } from 'react';
import { connect } from "react-redux";
import withRouter from "react-router-dom/withRouter";
import {Alert, Container, Row} from "reactstrap";
import {Redirect} from "react-router-dom";
import ComplianceCard from "./compliance-listing/ComplianceCard";
import { bindActionCreators } from "redux";
import {
    cancelComplianceSessionInProgressPoller,
    deleteVendorComplianceSession,
    fetchVendorComplianceSession,
    startComplianceSessionInProgressPoller
} from "../../actions/ComplianceActions";
import FixComplianceIssue from "../../components/compliance/FixComplianceIssue";
import * as Steps from "../../components/compliance/steps/StepValues";

class Compliance extends Component {

    render() {

        const {complianceEnabled, vendors, step} = this.props

        if(!complianceEnabled) {
            return <Redirect to="/orders"/>
        }

        return (
            <div className="mh-100 minh-100" style={{overflow: "auto"}}>
                <Row>
                    <div className="col-sm-12">
                        <Row className="summary-title-row">
                            <div className="col-sm-12">
                                <div>
                                    <h1 className="d-md-block heading ml-3">Compliance</h1>
                                    {!this.props.match.params.vendorId && <div className={"ml-3 mt-3"}><p>All businesses on the Scoffable platform need to comply with government regulations.</p>
                                        <p>From time to time, we may need you to provide additional details about yourself
                                        or the structure of your business.</p></div>}
                                </div>
                            </div>
                        </Row>
                    </div>
                </Row>

                {step === Steps.OVERVIEW && !this.props.match.params.vendorId && vendors && vendors.map(v => {
                    return <ComplianceCard key={v.vendorId} vendor={v} {...this.props} />
                })}
                {step === Steps.OVERVIEW && (! vendors || vendors.length === 0) && <Container className={"px-3"}><Alert color={"danger"} className={"col-sm-4 mx-auto my-4"}><strong>No businesses found</strong><br/> Please get in touch if this is incorrect</Alert></Container>}
                {step !== Steps.OVERVIEW && this.props.match.params.vendorId && <FixComplianceIssue {...this.props} />}
            </div>
        );
    }
}

const mapStateToProps = (state) => {

    const vendorsCompliance = state.vendor.vendorsCompliance

    for(let index in vendorsCompliance){

        const vendor = state.vendor.vendors.find(v => vendorsCompliance[index].vendorId === v.vendorID)
        if(vendor){
            vendorsCompliance[index] = {
                ...vendorsCompliance[index],
                ...vendor
            }
        }
    }

    return {
        complianceEnabled: state.vendor.complianceEnabled,
        vendors: vendorsCompliance,
        complianceSession: state.compliance.complianceSession,
        errorMessage: state.compliance.errorMessage,
        redirectToComplianceInstructions: state.compliance.redirectToComplianceInstructions,
        complianceSessionFetchInProgress: state.compliance.complianceSessionFetchInProgress,
        complianceSessionDeleteInProgress: state.compliance.complianceSessionDeleteInProgress,
        compliancePollRequests: state.compliance.compliancePollRequests,
        compliancePollingInProgress: state.compliance.compliancePollingInProgress,
        user: state.user.user
    }


}

const mapActionsToDispatch = (dispatch) => {
    return {
        actions: bindActionCreators({
            fetchVendorComplianceSession,
            deleteVendorComplianceSession,
            startComplianceSessionInProgressPoller,
            cancelComplianceSessionInProgressPoller
        }, dispatch)
    }
}

const connectedComponent = connect(mapStateToProps, mapActionsToDispatch)(Compliance)

export default withRouter(connectedComponent);
