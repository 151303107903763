import React, { Component } from 'react'
import { connect } from 'react-redux'
import "moment/locale/en-gb";
import moment from 'moment';
import { bindActionCreators } from 'redux';
import DatePicker from '../../components/date/DatePicker';
import { getDateRangeForFilter } from '../../utils/dateUtils';
import { changeActiveFilter, updateOrdersListStartDate, updateOrdersListEndDate } from "../../actions/OrderActions";
import { SEVEN_DAYS, TODAYS, MONTH, YEAR, CUSTOM } from './FilterNames';
import { Row } from "reactstrap";
moment.locale("en-gb");

class OrdersDateSelection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customDatePickerOpen: false
        }
    }

    updateFilterSelection = (filterName, startDate, endDate) => {
        this.props.actions.changeActiveFilter(filterName);
        this.props.actions.updateOrdersListStartDate(startDate.toDate());
        if (endDate) {
            this.props.actions.updateOrdersListEndDate(endDate.toDate());
        }
    }

    handleChange = (filter, customStartDate, customEndDate) => {
        if (filter.filterName === CUSTOM.filterName) {
            // Handle custom date range from user input
            this.updateFilterSelection(CUSTOM.filterName, moment(customStartDate).startOf("day"), moment(customEndDate).endOf("day"));
        } else {
            // For standard filters, use the utility function
            const { startDate, endDate } = getDateRangeForFilter(filter.filterName);
            this.updateFilterSelection(filter, moment(startDate), moment(endDate));
        }
    }

    getDateRangeText(){
        const {activeFilterName, ordersListStartDate, ordersListEndDate } = this.props;
        if(activeFilterName === TODAYS.filterName && ordersListEndDate !== null){
            return (<h4 className="date-sub-heading">{moment(ordersListEndDate).format("L")}</h4>)
        }
        if(ordersListStartDate !== null && ordersListEndDate !== null && activeFilterName !== CUSTOM.filterName && activeFilterName !== TODAYS.filterName){
            return (<h4 className="date-sub-heading">{moment(ordersListStartDate).format("L")} - {moment(ordersListEndDate).format("L")}</h4>);
        }
        return null;
    }


    render() {
        const { activeFilterName, ordersListStartDate, ordersListEndDate } = this.props;
        const ordersHeadingText = (this.props.ordersHeadingText === CUSTOM.activeFilterName) ? <span>Orders from <b>{moment(ordersListStartDate).format("L")}</b> to <b>{moment(ordersListEndDate).format("L")}</b></span> : this.props.ordersHeadingText;
        return (
            <Row style={{ margin: "0px" }} className="order-date-selection-wrapper no-padding">
                <div className="col-sm-12" style={{ padding: "0px" }}>
                    <Row className="summary-title-row">
                        <div className="col-sm-12">
                            <div>
                                <h1 data-cy="page-title" className="d-md-block heading" >{ordersHeadingText}</h1>
                                {this.getDateRangeText()}
                            </div>
                        </div>
                    </Row>
                    <div className="container-fluid date-filter-container">
                        <ul className="nav nav-pills justify-content-center date-filter">
                            <li className="nav-item">
                                <button className={"nav-link " + ((activeFilterName === TODAYS.filterName) ? "active" : "")} onClick={(e) => { e.preventDefault(); this.handleChange(TODAYS) }}>Today</button>
                            </li>
                            <li className="nav-item">
                                <button className={"nav-link " + ((activeFilterName === SEVEN_DAYS.filterName) ? "active" : "")} onClick={(e) => { e.preventDefault(); this.handleChange(SEVEN_DAYS) }}>Last 7 Days</button>
                            </li>
                            <li className="nav-item">
                                <button className={"nav-link " + ((activeFilterName === MONTH.filterName) ? "active" : "")} onClick={(e) => { e.preventDefault(); this.handleChange(MONTH) }}>Last Month</button>
                            </li>
                            <li className="nav-item">
                                <button className={"nav-link " + ((activeFilterName === YEAR.filterName) ? "active" : "")} onClick={(e) => { e.preventDefault(); this.handleChange(YEAR) }}>Last Year</button>
                            </li>
                            <li className="nav-item">
                                <button className={"nav-link " + ((activeFilterName === CUSTOM.filterName) ? "active" : "")} onClick={(e) => { e.preventDefault(); this.setState({ customDatePickerOpen: !this.state.customDatePickerOpen }) }}>Custom Date Range</button>
                            </li>
                        </ul>
                        <DatePicker message="Please choose a date range to show orders for" toggle={() => this.setState({ customDatePickerOpen: !this.state.customDatePickerOpen })}
                            open={this.state.customDatePickerOpen} handleChange={this.updateFilterSelection} />
                    </div>
                </div>
            </Row>
        )
    }
}

const mapStateToProps = (state) => ({
    activeFilterName: state.orders.activeFilterName,
    ordersHeadingText: state.orders.ordersHeadingText,
    ordersListEndDate: state.orders.ordersListEndDate,
    ordersListStartDate: state.orders.ordersListStartDate,
    orders: state.orders.orders
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ changeActiveFilter, updateOrdersListEndDate, updateOrdersListStartDate }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDateSelection)
