import '../../../../css/OrdersSummary.css';

import React, { Component } from 'react';
import { RadialChart } from 'react-vis';
import { Card } from 'reactstrap';
import { connect } from "react-redux";

class OrderDeliveryTypeSummary extends Component {

    render() {
        const { summaryData } = this.props;
        let deliveryOrders = 0;
        let collectionOrders = 0;
        if (summaryData != null && summaryData.totalDeliveryOrders != null) {
            deliveryOrders = summaryData.totalDeliveryOrders;
        }

        if (summaryData != null && summaryData.totalCollectionOrders != null) {
            collectionOrders = summaryData.totalCollectionOrders;
        }

        const chartData = [{ angle: deliveryOrders, label: "Delivery", radius: 10, color: "#B8008A" },
        { angle: collectionOrders, label: "Collection", radius: 10, color: "#A37998" }];

        return (
            <div  style={{width: "100%"}}>
                <Card className="order-summary-card h-100">
                    <div className="headline-title">Order Type</div>
                    <div className="row h-100 justify-content-center align-items-center">
                        <div>
                            <div className="container headline-chart">
                                <RadialChart
                                    data={chartData}
                                    width={100}
                                    height={100}
                                    colorType="literal" />
                            </div>
                            <div className="container headline-chart-legend">
                                <span className="headline-chart-legend-block" style={{ backgroundColor: "#B8008A" }} />
                                <span className="headline-chart-legend-label">Delivery</span>
                                <span className="headline-chart-legend-block" style={{ backgroundColor: "#A37998" }} />
                                <span className="headline-chart-legend-label">Collection</span>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    summaryData: state.orders.summaryData
})

export default connect(mapStateToProps)(OrderDeliveryTypeSummary);
