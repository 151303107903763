import * as OrderReviewActions from "../../actions/OrderReviewActions";
import { Map, Set } from "immutable";
const initialState = {
    fetchingOrderReviewsInProgress: false,
    reviews: Map(),
    fetchOrderReviewsError: null,
    totalReviewsNumber: 0,
    currentPage: 1,
    reviewsPerPage: 10,
    reviewsActivePage: 0,
    orderReviewsSubmitting: Set(),
    orderReviewReplyErrors: Map(),
    reviewSummariesFetched: false,
    reviewSummaries: Set(),
    reviewSummariesError: null,
    reviewSentiment: "most recent"
};

export default function OrdersReducer(state = initialState, action){
    switch (action.type){
        case OrderReviewActions.fetchOrderReviews().type:
            return {...state, fetchingOrderReviewsInProgress: true, fetchOrderReviewsError: null}
        case OrderReviewActions.fetchOrderReviewsSuccess().type:
            return {...state, reviews: Map(action.payload.reviews), totalReviewsNumber: action.payload.totalReviewsNumber, fetchingOrderReviewsInProgress: false, fetchOrderReviewsError: null, orderReviewsDisabled: false}
        case OrderReviewActions.fetchOrderReviewsFailed().type:
            return {...state, fetchingOrderReviewsInProgress: false, fetchOrderReviewsError: action.payload, reviews: Map()}
        case OrderReviewActions.updateReviewsActivePage().type:
            return {...state, reviewsActivePage: action.payload}
        case OrderReviewActions.updateReviewsPerPage().type:
            return {...state, reviewsPerPage: action.payload, reviewsActivePage: 0}
        case OrderReviewActions.updateReviewSentiment().type:
            return {...state, reviewSentiment: action.payload}
        case OrderReviewActions.orderReviewsDisabled().type:
            return {...state, orderReviewsDisabled: true}
        case OrderReviewActions.replyToOrderReview().type:
            return {...state, orderReviewsSubmitting: state.orderReviewsSubmitting.add(action.payload.reviewId), orderReviewReplyErrors: state.orderReviewReplyErrors.delete(action.payload.reviewId)}
        case OrderReviewActions.replyToOrderReviewSuccess().type:
            return {...state, orderReviewsSubmitting: state.orderReviewsSubmitting.delete(action.payload.id), reviews: state.reviews.set(action.payload.id, action.payload)}
        case OrderReviewActions.replyToOrderReviewFailed().type:
            return {...state, orderReviewsSubmitting: state.orderReviewsSubmitting.delete(action.payload.reviewId), orderReviewReplyErrors: state.orderReviewReplyErrors.set(action.payload.reviewId, action.payload.error)}
        case OrderReviewActions.editOrderReviewReply().type:
            return {...state, orderReviewsSubmitting: state.orderReviewsSubmitting.add(action.payload.reviewId), orderReviewReplyErrors: state.orderReviewReplyErrors.delete(action.payload.reviewId)}
        case OrderReviewActions.editOrderReviewReplySuccess().type:
            return {...state, orderReviewsSubmitting: state.orderReviewsSubmitting.delete(action.payload.id), reviews: state.reviews.set(action.payload.id, action.payload)}
        case OrderReviewActions.editOrderReviewReplyFailed().type:
            return {...state, orderReviewsSubmitting: state.orderReviewsSubmitting.delete(action.payload.reviewId), orderReviewReplyErrors: state.orderReviewReplyErrors.set(action.payload.reviewId, action.payload.error)}
        case OrderReviewActions.deleteOrderReviewReply().type:
            return {...state, orderReviewsSubmitting: state.orderReviewsSubmitting.add(action.payload.reviewId), orderReviewReplyErrors: state.orderReviewReplyErrors.delete(action.payload.reviewId)}
        case OrderReviewActions.deleteOrderReviewReplySuccess().type:
            return {...state, orderReviewsSubmitting: state.orderReviewsSubmitting.delete(action.payload.id), reviews: state.reviews.set(action.payload.id, action.payload)}
        case OrderReviewActions.deleteOrderReviewReplyFailed().type:
            return {...state, orderReviewsSubmitting: state.orderReviewsSubmitting.delete(action.payload.reviewId), orderReviewReplyErrors: state.orderReviewReplyErrors.set(action.payload.reviewId, action.payload.error)}
        case OrderReviewActions.fetchOrderReviewData().type:
            return {...state, fetchingOrderReviewsInProgress: true, fetchOrderReviewsError: null, reviewSummariesFetched: false, reviewSummaries: Set(), reviewSummariesError: null}
        case OrderReviewActions.fetchOrderReviewDataSuccess().type:
            return {...state, reviews: Map(action.payload.reviewsData.reviews), totalReviewsNumber: action.payload.reviewsData.totalReviewsNumber, fetchingOrderReviewsInProgress: false, fetchOrderReviewsError: null, orderReviewsDisabled: false, reviewSummariesFetched: true, reviewSummaries: Set(action.payload.reviewSummaryData), reviewSummariesError: null}
        case OrderReviewActions.fetchOrderReviewDataFailed().type:
            return {...state, fetchingOrderReviewsInProgress: false, fetchOrderReviewsError: action.payload.message, reviews: Map(), reviewSummariesFetched: false, reviewSummaries: Set(), reviewSummariesError: action.payload.message}
        case OrderReviewActions.clearOrderReviewReplyError().type:
            return {...state, orderReviewReplyErrors: state.orderReviewReplyErrors.delete(action.payload.reviewId)}
        default:
            return state;
    }
}