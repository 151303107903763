import React, { Component } from 'react';
import { Table, Icon } from "semantic-ui-react";
import moment from "moment";
import { FormattedNumber, IntlProvider } from 'react-intl';
import {MdFileDownload} from "react-icons/md";
import {userHasPermissionForVendor} from "../../../utils/helpers/User";
import {VIEW_INVOICES_PDF} from "../../../utils/helpers/Permission";

class InvoicesTableRow extends Component {
  /* eslint-disable react/style-prop-object */
  render() {
    
    const { vendorLogoURL, invoiceNumber, accountNumber, invoiceStartDate, invoiceEndDate, totalCharges, vat, commissionTotal, invoicePDFDownloadURL, estimated } = this.props;
    /* eslint-disable react/style-prop-object */
    return (
      <Table.Row warning={estimated}>
        <Table.Cell textAlign="center" collapsing>
            <img data-cy={`vendor-logo-${invoiceNumber}`}
                 src={vendorLogoURL}
                 alt="Vendor Logo"
                 className="rounded align-middle table-vendor-logo vendor-logo"
                 title={accountNumber}
                 style={{ height: "30px" }}/>
        </Table.Cell>
        <Table.Cell> 
        {!estimated && invoiceNumber}
        {estimated && <div>
      <Icon name='warning circle' />
      <b>This invoice is estimated and is subject to change.</b>
        </div>}
        </Table.Cell>
        <Table.Cell collapsing>{moment(invoiceStartDate).format("L")}</Table.Cell>
        <Table.Cell collapsing>{moment(invoiceEndDate).format("L")}</Table.Cell>
        <Table.Cell collapsing textAlign="right">
        <IntlProvider locale={navigator.language}>
            <FormattedNumber
              value={commissionTotal}
              currency="GBP"
              style="currency"
              minimumFractionDigits={2}
              maximumFractionDigits={2} />
          </IntlProvider>
        </Table.Cell>
        <Table.Cell collapsing textAlign="right">
        <IntlProvider locale={navigator.language}>
            <FormattedNumber
              value={totalCharges - (vat + commissionTotal)}
              currency="GBP"
              style="currency"
              minimumFractionDigits={2}
              maximumFractionDigits={2} />
          </IntlProvider>
        </Table.Cell>
        <Table.Cell collapsing textAlign="right">
        <IntlProvider locale={navigator.language}>
            <FormattedNumber
              value={vat}
              currency="GBP"
              style="currency"
              minimumFractionDigits={2}
              maximumFractionDigits={2} />
          </IntlProvider>
        </Table.Cell>
        <Table.Cell collapsing textAlign="right">
        <IntlProvider locale={navigator.language}>
            <FormattedNumber
              value={totalCharges}
              currency="GBP"
              style="currency"
              minimumFractionDigits={2}
              maximumFractionDigits={2} />
          </IntlProvider>
        </Table.Cell>
      <Table.Cell collapsing textAlign="center">
       { this.showPdfDownloadLink() &&
           <a data-cy={`download-link-${invoiceNumber}`} className="btn btn-secondary" target="_blank" rel="noopener noreferrer" href={invoicePDFDownloadURL} role="button"><MdFileDownload/></a>}
      </Table.Cell>
      </Table.Row>
    )
    /* eslint-enable react/style-prop-object */
  }

    showPdfDownloadLink() {
        const { accountNumber, invoicePDFDownloadURL, estimated, user } = this.props;

        return userHasPermissionForVendor(user, accountNumber, VIEW_INVOICES_PDF) &&
            !estimated &&
            invoicePDFDownloadURL;
    }
}

export default InvoicesTableRow;