import React, { Component } from 'react'
import { Form, Message } from 'semantic-ui-react'
import LoaderButton from '../../components/LoaderButton';
export default class ReplyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPristine: true,
            isError: false,
            errorMessageText: "",
            replyText: "",
            tooltipOpen: false
        }
    }

    handleChange = (event) => {
        if(this.state.isPristine === true){
            this.setState({
                isPristine: false
            })
        }
        if(!event.target.value || event.target.value.length === 0 || !/[^\s]+/.test(event.target.value)){
            this.setState({
                isError: true,
                errorMessageText: "The reply must have content",
                replyText: event.target.value
            })
            return;
        }
        if(event.target.value.length === 3000){
            this.setState({
                isError: true,
                errorMessageText: "The reply must be less than 3000 characters in length"
            })
            return;
        }
        this.setState({
            isError: false,
            errorMessageText: "",
            replyText: event.target.value
        })
    }

    toggleTooltip = () => {
        this.setState({
          tooltipOpen: !this.state.tooltipOpen
        });
      }

    render() {
        const { reviewId, toggleReplyForm, replyToOrderReview, orderReviewsSubmitting, orderReviewReplyError } = this.props;
        const { isPristine, isError, errorMessageText, replyText } = this.state;
        return (
            <div className="reply-edit-section">
                <Form error={orderReviewReplyError || isError} loading={orderReviewsSubmitting.includes(reviewId)}>
                    <Message
                        error
                        content={errorMessageText || orderReviewReplyError} icon="exclamation triangle" />

                    <Form.TextArea placeholder="What would you like to say in reply..." maxLength={3000} onChange={this.handleChange} />
                    <p className="submit-reply-info">
                            To ensure your reply is published publicly please comply with our <a href="https://www.scoffable.com/review/guidelines" target="_blank" rel="noopener noreferrer">guidelines</a>.</p>
                    <LoaderButton
                        block
                        data-cy="submit-button"
                        disabled={isPristine || isError}
                        text="Submit Reply"
                        className="btn-default"
                        onClick={() => replyToOrderReview({reviewId: reviewId, replyText: replyText, toggleReplyForm: toggleReplyForm})} />
                    <div className="mt-1 w-100 text-right" style={{marginBottom: "10px"}} >
                        <button data-cy="cancel-button" className="link-button black-link" style={{marginBottom: "10px"}} onClick={() => toggleReplyForm()}>Cancel</button>
                    </div>
                </Form>
            </div>
        )
    }
}
