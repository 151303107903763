import React, { Component } from "react";
import * as Steps from "./forms/VendorOwnerFormSteps";
import BeforeWeBegin from "./forms/steps/BeforeWeBegin";
import ConfirmYourIdentity from "./forms/steps/ConfirmYourIdentity";
import ChangeYourDetails from "./forms/steps/ChangeYourDetails";
import ConfirmYourDetails from "./forms/steps/ConfirmYourDetails";
import SignContract from "./forms/steps/SignContract";
import Complete from "./forms/steps/Complete";
import Error from "./forms/steps/Error";
import StepProgressBar from "./forms/progress-bar/StepProgressBar";

class VendorOwnerDetailsEditForm extends Component {

    constructor(props) {
        super(props);
        if(!props.inFlightVendorOwnerChange.isEmpty()){
            this.props.completeBeforeWeBegin();
        }
        this.state = {
            step: Steps.BEFORE_WE_BEGIN
        }
    }

    static getDerivedStateFromProps(props) {
        if(isPathnameEditVendorOwnerDetails(props)){
            props.toggleEditState();
            return;
        }
        if(isPathnameComplete(props)){
            return { step: Steps.COMPLETE }
        }
        if(props.vendorOwnerDetailsUpdateErrorMessage){
            return {step: Steps.SOMETHING_WENT_WRONG }
        }
        
        if(isAgreementSigned(props)){
            return { step: Steps.COMPLETE }
        }
        console.log(props.inFlightVendorOwnerChange.get("detailsUpdateResponse"))
        if(detailsUpdateResponseExists(props) && props.inFlightVendorOwnerChange.get("detailsUpdateResponse").every(detailsUpdate => {
            console.log(detailsUpdate)
            return detailsUpdate.get("otp") && detailsUpdate.getIn(["otp", "verified"])
        })){
            return { step: Steps.SIGN_CONTRACT }
        }
        if(detailsUpdateResponseExists(props)){
            return { step: Steps.CONFIRM_YOUR_DETAILS }
        }
        if(vendorOwnerIdentityOtpExists(props) && isVendorOwnerIdentityOtpVerified(props)){
            return { step: Steps.CHANGE_YOUR_DETAILS }
        }
        if(isBeforeWeBeginCompleted(props)){
            return {step: Steps.CONFIRM_YOUR_IDENTITY }
        }
        return { step: Steps.BEFORE_WE_BEGIN }
    }

    submitOtpCode = (values) => {
        this.props.submitOtpCodeForRegisteredPhone({...values, push: this.props.push})
        
    }

    submitNewDetails = (values) => {
        const { telephoneNumber, emailAddress } = this.props.vendorOwner;
        let detailsUpdates = [];
        if(values.phoneNumber !== telephoneNumber){
            detailsUpdates.push({
                detailsUpdateRequestType: "TELEPHONE_NUMBER",
                newDetails: values.phoneNumber
            })
        }
        if(values.emailAddress !== emailAddress){
            detailsUpdates.push({
                detailsUpdateRequestType: "EMAIL_ADDRESS",
                newDetails: values.emailAddress
            })
        }
        if(detailsUpdates.length !== 0){

            this.props.submitNewDetails({detailsUpdates: detailsUpdates,
                 push: this.props.push});
        }
    }

    submitConfirmNewDetails = (values) => {
        this.props.submitOtpCode(values);
    }

    render() {
        const { step } = this.state;
        const { vendorOwner, requestOtpToRegisteredPhone, inFlightVendorOwnerChange, requestingOtpToRegisteredPhone, otpCodeRequested, submitOtpForRegisteredPhoneError,
            submittingOtpForRegisteredPhone, submittingOtpCodes, fetchSigningUrlError, fetchingSigningUrl, fetchSigningUrl, completeVendorOwnerDetailsUpdate, updatingVendorOwner,
            requestOtpToRegisteredPhoneError, resendOtpToRegisteredPhone, completeBeforeWeBegin, vendorOwnerDetailsUpdateErrorMessage, cancelEditVendorOwnerDetails, otpCodeErrors,
            resendingNewDetailsOtps, resendingNewDetailsOtpsErrors, resendOtpToNewDetails, submittingNewDetails, cancelInFlightUpdate, otpHasExpired, requestOtpToRegisteredPhoneRateLimited, 
            resendOtpToRegisteredPhoneRateLimited, fetchingVendorOwnerAfterUpdate, submitNewDetailsError } = this.props;
        return (
            <div className="wizard-form">
                <StepProgressBar activeStep={this.state.step} style={{ paddingBottom: "20px" }} />
                {step === Steps.BEFORE_WE_BEGIN && <BeforeWeBegin vendorOwner={vendorOwner} push={this.props.push} completeBeforeWeBegin={completeBeforeWeBegin} />}
                {step === Steps.CONFIRM_YOUR_IDENTITY && <ConfirmYourIdentity vendorOwner={vendorOwner} push={this.props.push} requestOtpToRegisteredPhone={requestOtpToRegisteredPhone}
                onSubmit={this.submitOtpCode} inFlightVendorOwnerChange={inFlightVendorOwnerChange} requestingOtpToRegisteredPhone={requestingOtpToRegisteredPhone} otpCodeRequested={otpCodeRequested}
                submitOtpForRegisteredPhoneError={submitOtpForRegisteredPhoneError} submittingOtpForRegisteredPhone={submittingOtpForRegisteredPhone} requestOtpToRegisteredPhoneError={requestOtpToRegisteredPhoneError}
                resendOtpToRegisteredPhone={resendOtpToRegisteredPhone} cancelInFlightUpdate={cancelInFlightUpdate} requestOtpToRegisteredPhoneRateLimited={requestOtpToRegisteredPhoneRateLimited}
                resendOtpToRegisteredPhoneRateLimited={resendOtpToRegisteredPhoneRateLimited} />}
                {step === Steps.CHANGE_YOUR_DETAILS && <ChangeYourDetails initialValues={{phoneNumber: vendorOwner.telephoneNumber, emailAddress: vendorOwner.emailAddress}} push={this.props.push}
                onSubmit={this.submitNewDetails} submittingNewDetails={submittingNewDetails} cancelInFlightUpdate={cancelInFlightUpdate} submitNewDetailsError={submitNewDetailsError}/>}
                {step === Steps.CONFIRM_YOUR_DETAILS && <ConfirmYourDetails inFlightVendorOwnerChange={inFlightVendorOwnerChange} onSubmit={this.submitConfirmNewDetails} submittingOtpCodes={submittingOtpCodes}
                push={this.props.push} otpCodeErrors={otpCodeErrors} resendingNewDetailsOtps={resendingNewDetailsOtps} resendingNewDetailsOtpsErrors={resendingNewDetailsOtpsErrors} resendOtpToNewDetails={resendOtpToNewDetails}
                cancelInFlightUpdate={cancelInFlightUpdate} otpHasExpired={otpHasExpired}/>}
                {step === Steps.SIGN_CONTRACT && <SignContract fetchSigningUrlError={fetchSigningUrlError} fetchingSigningUrl={fetchingSigningUrl} agreementSigningURL={inFlightVendorOwnerChange.get("agreementUrl")}
                push={this.props.push} fetchSigningUrl={fetchSigningUrl} cancelInFlightUpdate={cancelInFlightUpdate} />}
                {step === Steps.COMPLETE && <Complete vendorOwner={vendorOwner} push={this.props.push} completeVendorOwnerDetailsUpdate={completeVendorOwnerDetailsUpdate} updatingVendorOwner={updatingVendorOwner}
                fetchingVendorOwnerAfterUpdate={fetchingVendorOwnerAfterUpdate}/>}
                {step === Steps.SOMETHING_WENT_WRONG && <Error vendorOwnerDetailsUpdateErrorMessage={vendorOwnerDetailsUpdateErrorMessage} push={this.props.push} updatingVendorOwner={updatingVendorOwner}
                cancelEditVendorOwnerDetails={cancelEditVendorOwnerDetails} />}
            </div>
        );
    }
}
export default VendorOwnerDetailsEditForm;

function isPathnameComplete(props){
    return props.pathname === "/user-management/edit/complete"
}

function isBeforeWeBeginCompleted(props) {
    return props.beforeWeBeginCompleted;
}

function isPathnameEditVendorOwnerDetails(props) {
    return props.pathname === "/user-management";
}

function isVendorOwnerIdentityOtpVerified(props) {
    return props.inFlightVendorOwnerChange.getIn(["otp", "verified"]);
}

function vendorOwnerIdentityOtpExists(props) {
    return props.inFlightVendorOwnerChange.get("otp");
}

function detailsUpdateResponseExists(props) {
    return props.inFlightVendorOwnerChange.get("detailsUpdateResponse");
}

function isAgreementSigned(props) {
    return props.inFlightVendorOwnerChange.get("agreementSigned");
}
