import { fetchInvoices, fetchInvoicesSuccess, fetchInvoicesFailed, updateInvoicesListActivePage, updateInvoicesPerPage } from "../../actions/InvoiceActions";
import { logoutSuccess } from "../../actions/UserActions";
const initialState = {fetchInvoicesInProgress: false, error: null, invoicesFetched: false, invoiceData: null, 
    totalInvoiceNumber: 0, currentPage: 1, invoicesListInvoicesPerPage: 10, invoicesListActivePage: 0};

export default function InvoicesReducer(state = initialState, action){
    switch (action.type){
        case fetchInvoices().type:
            return {...state, fetchInvoicesInProgress: true, error: null}
        case fetchInvoicesSuccess().type:
            return {...state, fetchInvoicesInProgress: false, error: null, invoiceData: action.payload.invoiceData, totalInvoiceNumber: action.payload.totalInvoiceNumber, currentPage: action.payload.currentPage};
        case fetchInvoicesFailed().type:
            return {...state, fetchInvoicesInProgress: false, error: action.payload}
        case updateInvoicesListActivePage().type:
            return {...state, invoicesListActivePage: action.payload}
        case updateInvoicesPerPage().type:
            return {...state, invoicesListInvoicesPerPage: action.payload, invoicesListActivePage: 0}
        case logoutSuccess().type:
            return {...initialState}
        default:
            return state;
    }
}