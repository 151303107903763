import React, {Component} from 'react';
import {Table} from "semantic-ui-react";
import DateDisplay from "../../../components/date/DateDisplay";
import CurrencyDisplay from "../../../components/money/CurrencyDisplay";

class PayoutTableRow extends Component {
    /* eslint-disable react/style-prop-object */
    render() {
        const {
            id,
            vendorId,
            vendorLogoUrl,
            timestamp,
            amountMinorUnits,
            accountNumber,
            sortCode,
        } = this.props;
        return (
            <Table.Row>
                <Table.Cell textAlign="center" collapsing>
                    <img src={vendorLogoUrl}
                         alt="Vendor Logo"
                         className="rounded align-middle table-vendor-logo vendor-logo"
                         title={vendorId}
                         style={{height: "30px"}}/>
                </Table.Cell>
                <Table.Cell collapsing>
                    {id}
                </Table.Cell>
                <Table.Cell collapsing>
                    <DateDisplay date={timestamp} />
                </Table.Cell>
                <Table.Cell collapsing>{accountNumber}</Table.Cell>
                <Table.Cell collapsing>{sortCode}</Table.Cell>
                <Table.Cell textAlign="right">
                    <CurrencyDisplay amountMinorUnits={amountMinorUnits} />
                </Table.Cell>
            </Table.Row>
        )
        /* eslint-enable react/style-prop-object */
    }
}

export default PayoutTableRow;