export function fetchVendors() {
    return {
        type: 'FETCH_VENDORS'
    }
}

export function fetchVendorsSuccess(payload) {
    return {
        type: "FETCH_VENDORS_SUCCESS",
        payload: payload
    }
}

export function fetchVendorsFailed(error) {
    return {
        type: "FETCH_VENDORS_FAILED",
        payload: error
    }
}

export function toggleVendorChecked(payload) {
    return {
        type: "TOGGLE_VENDOR_CHECKED",
        payload: payload
    }
}

export function fetchVendorsReviewSummaries(payload) {
    return {
        type: "FETCH_VENDORS_REVIEW_SUMMARIES",
        payload: payload
    }
}

export function fetchVendorsReviewSummariesSuccess(payload) {
    return {
        type: "FETCH_VENDORS_REVIEW_SUMMARIES_SUCCESS",
        payload: payload
    }
}

export function fetchVendorsReviewSummariesFailed(error) {
    return {
        type: "FETCH_VENDORS_REVIEW_SUMMARIES_FAILED",
        payload: error
    }
}

export function fetchVendorsCompliance() {
    return {
        type: 'FETCH_VENDORS_COMPLIANCE'
    }
}

export function fetchVendorsComplianceSuccess(payload) {
    return {
        type: "FETCH_VENDORS_COMPLIANCE_SUCCESS",
        payload: payload
    }
}

export function fetchVendorsComplianceFailed(error) {
    return {
        type: "FETCH_VENDORS_COMPLIANCE_FAILED",
        payload: error
    }
}