import React, { Component } from "react";
import EnterBankDetails from "./steps/EnterBankDetails";
import ConfirmBankDetails from "./steps/ConfirmBankDetails";
import SignContract from "./steps/SignContract";
import Complete from "./steps/Complete";
import { Jumbotron, Container } from "reactstrap";
import * as Steps from "./steps/StepValues";
import StepProgressBar from "./steps/StepProgressBar";
import VendorLogoAddressView from "../../vendor/VendorLogoAddressView";
import LoadingSpinner from "../../loading/LoadingSpinner";

class EditBankDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: Steps.ENTER_BANK_DETAILS
        }
    }

    componentDidMount() {
        if(this.props.bankDetails && this.props.bankDetails.size > 0){
        this.props.actions.fetchEffectiveDate(this.props.match.params.vendorID);
        }
    }

    static getDerivedStateFromProps(props, state) {
        // eslint-disable-next-line eqeqeq
        const vendorBankDetails = props.bankDetails.find(bankDetails => bankDetails.vendorID == props.match.params.vendorID);
        if(props.match.params.complete){
            props.updateHeading("Bank Details Updated")
            return {
                step: Steps.COMPLETE
            }
        }
        if (!vendorBankDetails.inProgressUpdate) {
            props.updateHeading("Update Bank Details")
            return {
                step: Steps.ENTER_BANK_DETAILS
            }
        } else if (vendorBankDetails.inProgressUpdate && !vendorBankDetails.otpVerified) {
            props.updateHeading("Confirm New Bank Details")
            return {
                step: Steps.CONFIRM_BANK_DETAILS
            }
        } else if (vendorBankDetails.inProgressUpdate && vendorBankDetails.otpVerified) {
            props.updateHeading("Sign Contract")
            return {
                step: Steps.SIGN_CONTRACT
            }
        }
    }

    submitBankDetailsForm = (values) => {
        this.props.actions.submitBankDetails({ formValues: values });
    }

    resubmitBankDetails = (values) => {
        this.props.actions.submitBankDetails({ formValues: values });
    }

    submitConfirmBankDetailsForm = (values) => {
        this.props.actions.submitOtpCode({...values});
    }

    handleCancel = (payload) => {
        this.props.actions.cancelBankDetailsUpdate({ ...payload, push: this.props.history.push });
    }

    render() {
        const { step } = this.state;
        // eslint-disable-next-line eqeqeq
        const vendorBankDetails = this.props.bankDetails.find(bankDetails => bankDetails.vendorID == this.props.match.params.vendorID);

        if(!vendorBankDetails){
            return <LoadingSpinner />
        }
        return (
            <Jumbotron fluid style={{ backgroundColor: "white", borderRadius: "5px", paddingTop: "2px", paddingBottom: "2px" }}>
            <div className="wizard-form">
                <Container fluid>
                <StepProgressBar activeStep={this.state.step} style={{ paddingBottom: "20px" }} />
                    {step !== Steps.COMPLETE && <VendorLogoAddressView vendorID={vendorBankDetails.vendorID} />}
                    {step === Steps.ENTER_BANK_DETAILS && <EnterBankDetails onSubmit={this.submitBankDetailsForm}
                        effectiveDate={vendorBankDetails.effectiveDate} fetchEffectiveDate={this.props.actions.fetchEffectiveDate}
                        vendorID={vendorBankDetails.vendorID} effectiveDateError={this.props.effectiveDateError}
                        fetchingEffectiveDate={this.props.fetchingEffectiveDate} handleCancel={this.handleCancel}
                        push={this.props.history.push} submittingBankDetails={this.props.submittingBankDetails} 
                        bankDetailsError={this.props.bankDetailsError}/>}
                    {step === Steps.CONFIRM_BANK_DETAILS && <ConfirmBankDetails accountNumber={vendorBankDetails.accountNumber}
                        sortCode={vendorBankDetails.sortCode} bankName={vendorBankDetails.bankName} guid={vendorBankDetails.guid}
                        effectiveDate={vendorBankDetails.effectiveDate} handleCancel={this.handleCancel} resubmitBankDetails={this.resubmitBankDetails}
                        fetchingEffectiveDate={this.props.fetchingEffectiveDate} otpCodeRequested={this.props.otpCodeRequested} 
                        vendorID={vendorBankDetails.vendorID} onSubmit={this.submitConfirmBankDetailsForm} submittingOtpCode={this.props.submittingOtpCode}
                        maxOTPMessagesSent={this.props.maxOTPMessagesSent} submittingBankDetails={this.props.submittingBankDetails}
                        otpCodeError={this.props.otpCodeError}/>}
                    {step === Steps.SIGN_CONTRACT && <SignContract vendorID={vendorBankDetails.vendorID} guid={vendorBankDetails.guid}
                        handleCancel={this.handleCancel} fetchSigningUrl={this.props.actions.fetchSigningUrl} agreementSigningURL={vendorBankDetails.agreementSigningURL}
                        fetchingSigningUrl={this.props.fetchingSigningUrl} fetchSigningUrlError={this.props.fetchSigningUrlError} />}
                    {step === Steps.COMPLETE && <Complete vendorID={vendorBankDetails.vendorID} accountNumber={vendorBankDetails.accountNumber}
                        sortCode={vendorBankDetails.sortCode} bankName={vendorBankDetails.bankName} startDate={vendorBankDetails.startDate} push={this.props.history.push} />}
                </Container>
                </div>
            </Jumbotron>
        );
    };
}

export default EditBankDetails