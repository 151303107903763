import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row } from "reactstrap";
import { Message, Icon } from 'semantic-ui-react';
import moment from "moment";
import { FormattedNumber, IntlProvider } from 'react-intl';
import {MdFileDownload} from "react-icons/md";
import "moment/locale/en-gb";
import {userHasPermissionForVendor} from "../../../utils/helpers/User";
import {VIEW_INVOICES_PDF} from "../../../utils/helpers/Permission";
moment.locale("en-gb");
class InvoiceCard extends Component {
/* eslint-disable react/style-prop-object */

    formatTextElement(label, element) {
        if (element != null) {
            return (
                <Row className="order-detail-row">
                    <div className="col-6 order-detail-col-heading">
                        {label}
                    </div>
                    <div className="col-6 order-detail-col-val">
                        <b>{element}</b>
                    </div>
                </Row>
            );
        }
    }

    formatDateElement(label, element) {
        if (element != null) {
            return (
                <Row className="order-detail-row">
                    <div className="col-6 order-detail-col-heading">
                        {label}
                    </div>
                    <div className="col-6 order-detail-col-val">
                        <b>{moment(element).format("DD/MM/YY")}</b>
                    </div>
                </Row>
            );
        }
    }

    presentVendorLogo() {
        const { vendorLogoURL, invoiceNumber, estimated } = this.props;
        if (vendorLogoURL != null) {
            return (
                <div style={{marginBottom: "10px"}}>
                <img src={vendorLogoURL} alt="Vendor Logo" className="rounded summary-vendor-logo vendor-logo" style={{height: "30px"}} />
                {!estimated && <h5 className="card-title" style={{display: "inline-block", paddingLeft: "10px", margin: "0px"}}>Invoice-{invoiceNumber}</h5>}
                </div>
            );
        }
    }

    renderCurrency(total) {
        /* eslint-disable react/style-prop-object */
        return (
            <div>
                <IntlProvider locale={navigator.language}>
                    <FormattedNumber
                        value={total}
                        currency="GBP"
                        style="currency"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2} />
                </IntlProvider>
            </div>
        )
        /* eslint-enable react/style-prop-object */
    }

    render() {
        const { invoiceNumber, invoiceStartDate, invoiceEndDate, totalCharges, vat, commissionTotal, invoicePDFDownloadURL, estimated, accountNumber, user } = this.props;
        return (
            <div style={{paddingLeft: "0", paddingRight: "0"}}>
            <div data-cy={`invoice-card-${invoiceNumber}`} className="card mb-2 order-summary-info-card">
                <div className="card-body">
                    {this.presentVendorLogo()}
                    <Container>
                        {this.formatDateElement("Start Date", invoiceStartDate)}
                        {this.formatDateElement("End Date", invoiceEndDate)}
                        {this.formatTextElement("Commission", this.renderCurrency(commissionTotal))}
                        {this.formatTextElement("Service Fee", this.renderCurrency(totalCharges - (vat + commissionTotal)))}
                        {this.formatTextElement("VAT", this.renderCurrency(vat))}
                        {this.formatTextElement("Total Charges", this.renderCurrency(totalCharges))}
                        {userHasPermissionForVendor(user, accountNumber, VIEW_INVOICES_PDF) &&
                            !estimated &&
                            invoicePDFDownloadURL &&
                            <a className="btn btn-secondary" target="_blank" rel="noopener noreferrer" href={invoicePDFDownloadURL} role="button" style={{width: "100%", marginTop: "20px"}}><MdFileDownload/> Download</a>}
                        {estimated && <Message warning>
                        <Icon name='warning circle' />
                        This invoice is estimated and is subject to change.
                        </Message>}
                    </Container>
                </div>
            </div>
            </div>
        );
    }
}

export default withRouter(InvoiceCard);