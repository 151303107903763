import React, { Component } from 'react'

export default class NewDetailsDescription extends Component {
  render() {

    const { otp } = this.props;

    switch(otp.detailsUpdateType){
      case "EMAIL_ADDRESS":
        return <li>Your new email address (<b>{otp.destination}</b>)</li>;
      case "TELEPHONE_NUMBER":
        return <li>Your new telephone number (<b>{otp.destination}</b>)</li>;
      default:
        return <li>Your new details (<b>{otp.destination}</b>)</li>;
    }
  }
}
