import React, { Component } from 'react'
import {FaQuestionCircle} from 'react-icons/fa';
import { Tooltip } from "reactstrap";

export default class AddressTooltip extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         id: `addressExpiredTooltip${props.id}`,
         addressToolTipOpen: false
      }
    }

    toggleAddressTooltip = () => {
        this.setState({
            addressToolTipOpen: !this.state.addressToolTipOpen
        })
    }

    addressString = (address) => {
        var maxChars = 100;
        var text = "";
        if (address !== undefined) {
            text = (undefined !== address.organisation ? address.organisation + ", " : "")
                + (address.firstLine !== undefined ? address.firstLine + ", " : "")
                + (address.secondLine !== undefined ? address.secondLine + ", " : "")
                + (address.locality !== undefined ? address.locality + ", " : "")
                + (address.town !== undefined ? address.town + ", " : "")
                + (address.county !== undefined ? address.county + ", " : "")
                + (address.postcode !== undefined ? address.postcode + " " : "");

            if (text.length > maxChars) {
                text = text.substr(0, maxChars) + "...";
            }
        }
        return text;
    }
    
  render() {
    const { type, address } = this.props;

    if (type === "COLLECTION") {
        return "N/A"
    }
    if (!address) {
        return <span id={this.state.id} style={{color: "grey"}}><FaQuestionCircle  style={{ marginRight: "5px" }} />
        <Tooltip isOpen={this.state.addressToolTipOpen} placement="right" toggle={this.toggleAddressTooltip} target={this.state.id}>
        User details will expire after 24hrs in order to comply with data privacy laws
</Tooltip> Expired</span>
    }
    return this.addressString(address);
  }
}