import React, { Component } from 'react';
import { Container, Row } from "reactstrap";
import moment from "moment";
import "moment/locale/en-gb";
import DateDisplay from "../../../components/date/DateDisplay";
import CurrencyDisplay from "../../../components/money/CurrencyDisplay";
moment.locale("en-gb");

export default class PayoutCard extends Component {

    render() {
        const {
            id,
            vendorLogoUrl,
            timestamp,
            amountMinorUnits,
            accountNumber,
            sortCode,
        } = this.props;


        return (
            <div data-cy="payout-card" style={{paddingLeft: "0", paddingRight: "0"}}>

                <div className="card mb-2 order-summary-info-card">
                    <div className="card-body">
                        <div style={{marginBottom: "10px"}}>
                            <img src={vendorLogoUrl} data-cy="vendorLogo" alt="Vendor Logo"
                                 className="rounded summary-vendor-logo vendor-logo" style={{height: "30px"}}/>
                            <h5 data-cy="payoutId" className="card-title"
                                style={{display: "inline-block", paddingLeft: "10px", margin: "0px"}}>Payout-{id}</h5>
                        </div>

                        <Container>
                            {this.formatDateElement("Date", timestamp)}
                            {this.formatTextElement("Account", accountNumber)}
                            {this.formatTextElement("Sort Code", sortCode)}
                            {this.formatCostElement("Amount", amountMinorUnits)}
                        </Container>
                    </div>
                </div>
            </div>
        );

    }

    formatCostElement(label, element) {
        if (element != null) {
            return (
                <Row className="order-detail-row">
                    <div data-cy={label + " Label"} className="col-6 order-detail-col-heading">
                        {label}
                    </div>
                    <div data-cy={label + " Data"} className="col-6 order-detail-col-val">
                        <b><CurrencyDisplay amountMinorUnits={element}/></b>
                    </div>
                </Row>
            );
        }

    }

    formatTextElement(label, element) {
        if (element != null) {
            return (
                <Row className="order-detail-row">
                    <div data-cy={label + " Label"} className="col-6 order-detail-col-heading">
                        {label}
                    </div>
                    <div data-cy={label + " Data"} className="col-6 order-detail-col-val">
                        <b>{element}</b>
                    </div>
                </Row>
            );
        }

    }

    formatDateElement(label, element) {
        if (element != null) {
            return (
                <Row className="order-detail-row">
                    <div data-cy={label + " Label"} className="col-6 order-detail-col-heading">
                        {label}
                    </div>
                    <div data-cy={label + " Data"} className="col-6 order-detail-col-val">
                        <b><DateDisplay date={element}/></b>
                    </div>
                </Row>
            );
        }
    }

}
