import React, { Component } from 'react'
import { Container, Jumbotron, Button } from 'reactstrap'
import * as Sentry from '@sentry/browser';
import SadComputer from '../../images/icons/error/sad-computer.svg';

export default class ErrorPage extends Component {
    render() {
        return (
            <Container fluid>
                <Jumbotron fluid style={{backgroundColor: "#F6F6F6", textAlign: "center"}}>
                    <img src={SadComputer} style={{height: "200px", width: "200px", marginBottom: "50px"}} alt="Sad Computer" />
                    <p className="lead">The application has experienced an error.  We have been notified that this has happened and will work to make sure it does not happen again.</p>
                    <p className="lead">
                    <div className="mt-2 d-block mx-auto">
                        {Sentry.lastEventId() && <Button onClick={() => {
                            if (Sentry.lastEventId()) {
                                Sentry.showReportDialog();
                            } 
                        }} color="secondary" style={{marginTop: "10px"}}>Let us know in more detail what went wrong</Button>}
                        <Button className="ml-2" color="primary" onClick={() => {
                            window.location.href = "https://partners.scoffable.com/"
                        }} style={{marginTop: "10px"}}>Refresh application</Button>
                        </div>
                    </p>
                </Jumbotron>
            </Container>
        )
    }
}
