import { put, takeLatest } from 'redux-saga/effects';
import * as OrderActions from '../../actions/OrderActions';
import ApiClientProvider from '../../utils/api/ApiClientProvider';
import { logout } from '../../actions/UserActions';
import * as Sentry from '@sentry/browser';

// worker Saga: will be fired on 'FETCH_ORDERS_STATS' actions
function* fetchOrderStats(action) {
    try {
        let apiInstance = ApiClientProvider.getOrdersControllerApiInstance();
        const {toDate, vendorIDBlacklist} = action.payload.opts;
        const {data} = yield apiInstance.getVendorOrderStatisticsUsingGET({
            fromDate: action.payload.fromDate,
            vendorIDBlacklist: vendorIDBlacklist,
            ...(toDate !== null && { toDate: toDate })
        });
        let dailyOrderNumberTotals = [];
        let dailyOrderValueTotals = [];
        for (let i = 0; i < data.dailyTotalsSummary.dailyTotals.length; i++) {
            let dailyOrderTotal = data.dailyTotalsSummary.dailyTotals[i];
            if (dailyOrderTotal != null) {
                dailyOrderNumberTotals.push(dailyOrderTotal.totalOrders != null ? dailyOrderTotal.totalOrders : 0);
                dailyOrderValueTotals.push(dailyOrderTotal.totalOrderValue != null ? dailyOrderTotal.totalOrderValue : 0);
            }
            else {
                dailyOrderNumberTotals.push(0);
                dailyOrderValueTotals.push(0);
            }
        }
        yield put(OrderActions.fetchOrderStatsSuccess({
            dailyOrderNumberTotals: dailyOrderNumberTotals, 
            dailyOrderValueTotals: dailyOrderValueTotals, 
            summaryData: data
        }));
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put(OrderActions.fetchOrderStatsFailed("Unable to process order stats request."));
            return;
        }
        if(e.response.status === 404){
            yield put(OrderActions.clearOrderStats());
            return;
        } else if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put(OrderActions.fetchOrderStatsFailed(e));
    }
}

function* fetchOrders(action) {
    try{
        let apiInstance = ApiClientProvider.getOrdersControllerApiInstance();
        const {toDate, vendorIDBlacklist, ordersPerPage, currentPage} = action.payload.opts;
        const {data} = yield apiInstance.getVendorOrdersUsingGET({
            fromDate: action.payload.fromDate,
            vendorIDBlacklist: vendorIDBlacklist,
            ordersPerPage: ordersPerPage,
            currentPage: currentPage,
            ...(toDate !== null && { toDate: toDate })
        });
        yield put(OrderActions.fetchOrdersSuccess({
            tabledata: data.orders, 
            totalOrderNumber: data.summary.totalNumOrders, 
            currentPage: 1
        }));
    } catch(e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put(OrderActions.fetchOrdersFailed("Unable to process orders request."));
            return;
        }
        if(e.response.status === 404){
            yield put(OrderActions.clearOrders());
            return;
        } else if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put(OrderActions.fetchOrdersFailed(e));
    }
}

function* fetchOrderDetails(action) {
    try {
        let apiInstance = ApiClientProvider.getOrdersControllerApiInstance();
        const {data} = yield apiInstance.getOrderDetailsByIDUsingGET({
            orderID: action.payload.orderID
        });
        yield put(OrderActions.fetchOrderDetailsSuccess(data));
    } catch(e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put(OrderActions.fetchOrderDetailsFailed("Unable to process order details request."));
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put(OrderActions.fetchOrderDetailsFailed(e));
    }
}

export default function* rootUserSaga() {
    yield takeLatest(OrderActions.fetchOrderStats().type, fetchOrderStats);
    yield takeLatest(OrderActions.fetchOrders().type, fetchOrders);
    yield takeLatest(OrderActions.fetchOrderDetails().type, fetchOrderDetails)
}