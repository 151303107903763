import { Map, Set, fromJS } from "immutable";
import * as VendorOwnerActions from "../../actions/VendorOwnerActions";
const initialState = { inFlightVendorOwnerChange: Map(),
    requestingOtpToRegisteredPhone: false, requestOtpToRegisteredPhoneError: null, submittingOtpForRegisteredPhone: false, submitOtpForRegisteredPhoneError: null, otpCodeRequested: false,
    submittingNewDetails: null, submitNewDetailsError: null, fetchingInFlightVendorOwnerDetailsUpdate: null, submittingOtpCodes: Set(), otpCodeErrors: Map(), fetchingSigningUrl: false, signingUrl: null,
    fetchSigningUrlError: null, updatingVendorOwner: false, cancellingInFlightUpdate: false, cancelInFlightUpdate: null, beforeWeBeginCompleted: false, vendorOwnerDetailsUpdateErrorMessage: null,
    resendingNewDetailsOtps: Set(), resendingNewDetailsOtpsErrors: Map(), otpHasExpired: false, isVendorOwnerLocked: false, fetchingVendorOwnerLockedStatus: false};

export default function VendorOwnerReducer(state = initialState, action){
    switch (action.type){
        case VendorOwnerActions.requestOtpToRegisteredPhone().type:
            return {...state, requestingOtpToRegisteredPhone: true, requestOtpToRegisteredPhoneError: null, inFlightVendorOwnerChange: Map(), otpCodeRequested: false, requestOtpToRegisteredPhoneRateLimited: false, resendOtpToRegisteredPhoneRateLimited: false }
        case VendorOwnerActions.requestOtpToRegisteredPhoneSuccess().type:
            return {...state, requestingOtpToRegisteredPhone: false, requestOtpToRegisteredPhoneError: null, inFlightVendorOwnerChange: Map(fromJS(action.payload)), otpCodeRequested: true}
        case VendorOwnerActions.requestOtpToRegisteredPhoneFailed().type:
            return {...state, requestingOtpToRegisteredPhone: false, requestOtpToRegisteredPhoneError: action.payload, otpCodeRequested: true }
        case VendorOwnerActions.rateLimitRequestOtpToRegisteredPhone().type:
            return{...state, requestingOtpToRegisteredPhone: false, requestOtpToRegisteredPhoneRateLimited: true}
        case VendorOwnerActions.resendOtpToRegisteredPhone().type:
            return {...state, requestOtpToRegisteredPhoneError: null, requestingOtpToRegisteredPhone: true, resendOtpToRegisteredPhoneRateLimited: false}
        case VendorOwnerActions.resendOtpToRegisteredPhoneSuccess().type:
            return {...state, requestOtpToRegisteredPhoneError: null, inFlightVendorOwnerChange: Map(fromJS(action.payload)), requestingOtpToRegisteredPhone: false}
        case VendorOwnerActions.resendOtpToRegisteredPhoneFailed().type:
            return {...state, requestOtpToRegisteredPhoneError: action.payload, requestingOtpToRegisteredPhone: false}
        case VendorOwnerActions.rateLimitResendOtpToRegisteredPhone().type:
            return {...state, requestingOtpToRegisteredPhone: false, resendOtpToRegisteredPhoneRateLimited: true}
        case VendorOwnerActions.submitOtpCodeForRegisteredPhone().type:
            return {...state, submittingOtpForRegisteredPhone: true, submitOtpForRegisteredPhoneError: null}
        case VendorOwnerActions.submitOtpCodeForRegisteredPhoneSuccess().type:
            return {...state, submittingOtpForRegisteredPhone: false, inFlightVendorOwnerChange: state.inFlightVendorOwnerChange.set("otp", fromJS(action.payload)), submitOtpForRegisteredPhoneError: null}
        case VendorOwnerActions.submitOtpCodeForRegisteredPhoneFailed().type:
            return {...state, submittingOtpForRegisteredPhone: false, submitOtpForRegisteredPhoneError: action.payload}
        case VendorOwnerActions.wrongOtpCodeSubmittedForVendorIdentity().type:
            return {...state, submittingOtpForRegisteredPhone: false, submitOtpForRegisteredPhoneError: "Incorrect code submitted"}
        case VendorOwnerActions.submitNewDetails().type:
            return {...state, submittingNewDetails: true, submitNewDetailsError: null}
        case VendorOwnerActions.submitNewDetailsSuccess().type:
            return {...state, submittingNewDetails: false, submitNewDetailsError: null, inFlightVendorOwnerChange: Map(fromJS(action.payload)) }
        case VendorOwnerActions.submitNewDetailsFailed().type:
            return {...state, submittingNewDetails: false, submitNewDetailsError: action.payload}
        case VendorOwnerActions.fetchInFlightVendorOwnerDetailsUpdate().type:
            return {...state, fetchingInFlightVendorOwnerDetailsUpdate: true}
        case VendorOwnerActions.fetchInFlightVendorOwnerDetailsUpdateSuccess().type:
            return {...state, fetchingInFlightVendorOwnerDetailsUpdate: false, inFlightVendorOwnerChange: Map(fromJS(action.payload))}
        case VendorOwnerActions.vendorOwnerDetailsUpdateNotFound().type:
            return {...state, fetchingInFlightVendorOwnerDetailsUpdate: false, inFlightVendorOwnerChange: Map()}
        case VendorOwnerActions.submitOtpCode().type:
            return {...state, submittingOtpCodes: state.submittingOtpCodes.add(action.payload.otpGuid), otpCodeErrors: state.otpCodeErrors.set(action.payload.otpGuid, null), resendingNewDetailsOtpsErrors: state.resendingNewDetailsOtpsErrors.delete(action.payload.otpGuid), otpHasExpired: false}
        case VendorOwnerActions.submitOtpCodeSuccess().type:
            return {...state, submittingOtpCodes: state.submittingOtpCodes.remove(action.payload.otpGuid), otpCodeErrors: state.otpCodeErrors.set(action.payload.otpGuid, null), inFlightVendorOwnerChange: Map(fromJS(action.payload.inFlightVendorOwnerChange))}
        case VendorOwnerActions.submitOtpCodeFailed().type:
            return {...state, submittingOtpCodes: state.submittingOtpCodes.remove(action.payload.otpGuid), otpCodeErrors: state.otpCodeErrors.set(action.payload.otpGuid, action.payload.error)}
        case VendorOwnerActions.fetchSigningUrl().type:
            return {...state, fetchingSigningUrl: true, fetchSigningUrlError: null}
        case VendorOwnerActions.fetchSigningUrlSuccess().type:
            return {...state, fetchingSigningUrl: false, inFlightVendorOwnerChange: Map(fromJS(action.payload)), fetchSigningUrlError: null}
        case VendorOwnerActions.fetchSigningUrlFailed().type:
            return {...state, fetchingSigningUrl: false, fetchSigningUrlError: action.payload}
        case VendorOwnerActions.lockVendorOwner().type:
            return {...state, vendorOwner: {...state.vendorOwner, vendorLocked: true}}
        case VendorOwnerActions.unlockVendorOwner().type:
            return {...state, vendorOwner: {...state.vendorOwner, vendorLocked: false}}
        case VendorOwnerActions.completeVendorOwnerDetailsUpdate().type:
            return {...state, updatingVendorOwner: true, vendorOwnerDetailsUpdateErrorMessage: null}
        case VendorOwnerActions.completeVendorOwnerDetailsUpdateSuccess().type:
            return {...state, updatingVendorOwner: false, inFlightVendorOwnerChange: Map(), vendorOwnerDetailsUpdateErrorMessage: null}
        case VendorOwnerActions.completeVendorOwnerDetailsUpdateFailed().type:
            return {...state, updatingVendorOwner: false, vendorOwnerDetailsUpdateErrorMessage: action.payload}
        case VendorOwnerActions.cancelInFlightUpdate().type:
            return {...state, cancellingInFlightUpdate: true, cancelInFlightUpdateError: null}
        case VendorOwnerActions.cancelInFlightUpdateSuccess().type:
            return {...state, inFlightVendorOwnerChange: Map(), cancellingInFlightUpdate: false, cancelInFlightUpdate: null}
        case VendorOwnerActions.cancelInFlightUpdateFailed().type:
            return {...state, cancellingInFlightUpdate: false, cancelInFlightUpdate: action.payload}
        case VendorOwnerActions.completeBeforeWeBegin().type:
            return {...state, beforeWeBeginCompleted: true}
        case VendorOwnerActions.resendOtpToNewDetails().type:
            return {...state, resendingNewDetailsOtps: state.resendingNewDetailsOtps.add(action.payload.otpGuid), resendingNewDetailsOtpsErrors: state.resendingNewDetailsOtpsErrors.delete(action.payload.otpGuid)}
        case VendorOwnerActions.resendOtpToNewDetailsSuccess().type: 
            return {...state, inFlightVendorOwnerChange: Map(fromJS(action.payload.inFlightVendorOwnerChange)), resendingNewDetailsOtps: state.resendingNewDetailsOtps.remove(action.payload.oldOtpGuid), resendingNewDetailsOtpsErrors: state.resendingNewDetailsOtpsErrors.delete(action.payload.oldOtpGuid)}
        case VendorOwnerActions.resendOtpToNewDetailsFailed().type:
            return {...state, resendingNewDetailsOtps: state.resendingNewDetailsOtps.remove(action.payload.guid), resendingNewDetailsOtpsErrors: state.resendingNewDetailsOtpsErrors.set(action.payload.guid, action.payload.error)}
        case VendorOwnerActions.clearErrors().type:
            return {...state, submitOtpForRegisteredPhoneError: null, submitNewDetailsError: null, otpCodeErrors: Map(), fetchSigningUrlError: null, resendingNewDetailsOtpsErrors: Map(), vendorOwnerDetailsUpdateErrorMessage: ""}
        case VendorOwnerActions.otpExpired().type:
            return {...state, submittingOtpCodes: state.submittingOtpCodes.remove(action.payload)}
        case VendorOwnerActions.otpExpiredSuccess().type:
            return {...state, inFlightVendorOwnerChange: Map(fromJS(action.payload.inFlightVendorOwnerChange)), otpHasExpired: true}
        case VendorOwnerActions.fetchVendorOwnerLockedStatus().type:
            return {...state, fetchingVendorOwnerLockedStatus: true, vendorOwnerLockedStatusError: ""}
        case VendorOwnerActions.fetchVendorOwnerLockedStatusSuccess().type:
            return {...state, fetchingVendorOwnerLockedStatus: false, isVendorOwnerLocked: action.payload.isVendorOwnerLocked}
        case VendorOwnerActions.fetchVendorOwnerLockedStatusFailed().type:
            return {...state, fetchingVendorOwnerLockedStatus: false, isVendorOwnerLocked: true, vendorOwnerLockedStatusError: action.payload}
        case VendorOwnerActions.fetchVendorOwnerAfterUpdate().type:
            return {...state, fetchingVendorOwnerAfterUpdate: true}
        case VendorOwnerActions.fetchVendorOwnerAfterUpdateSuccess().type:
            return {...state, fetchingVendorOwnerAfterUpdate: false, vendorOwner: action.payload}
        case VendorOwnerActions.fetchVendorOwnerAfterUpdateFailed().type:
            return {...state, fetchingVendorOwnerAfterUpdate: false}
        default:
            return {...state};
    }
}