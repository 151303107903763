import React from "react";
import SideMenu from "../components/SideMenu";
import { Route } from "react-router-dom";
import NavBar from "../components/navbar/NavBar";


const MainLayout = ({ component: Component, ...rest }) => {
  console.log("Rendering main layout")
  return (
    <Route {...rest} render={matchProps => (
      <div className="mh-100 minh-100 main-layout-container">
        <SideMenu />
        <NavBar />
        <div className="container-fluid mh-100 minh-100 px-0">
          <div className="ml-md-10 ml-lg-15 mh-100 minh-100 page-container">
            <Component {...rest} />
          </div>
        </div>
      </div>
    )} />
  )
};

export default MainLayout;