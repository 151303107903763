import {FormattedNumber, IntlProvider} from "react-intl";
import React, { Component } from "react";

export default class CurrencyDisplay extends Component {

    render() {
        const {amountMinorUnits, style="currency"} = this.props;

        const float = (!amountMinorUnits) ? 0.0 : amountMinorUnits / 100

        return (
            <IntlProvider locale={navigator.language}>
                <FormattedNumber
                    value={float}
                    currency="GBP"
                    style={style}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}/>
            </IntlProvider>
        )
    }
}
