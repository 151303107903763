import BankDetailsReducer from './bank-details/BankDetailsReducer';
import UserReducer from './user/UserReducer';
import VendorsReducer from "./vendor/VendorReducer";
import { combineReducers } from 'redux';
import OrdersReducer from './orders/OrderReducer';
import InvoicesReducer from './invoices/InvoicesReducer';
import VendorOwnerReducer from './vendor-owner/VendorOwnerReducer';
import OrdersReviewsReducer from './order-reviews/OrderReviewsReducer';
import { reducer as formReducer } from 'redux-form';
import ComplianceReducer from "./compliance/ComplianceReducer";
import PayoutsReducer from "./payouts/PayoutsReducer"

const Reducer = combineReducers({
  bankDetails: BankDetailsReducer,
  user: UserReducer,
  vendor: VendorsReducer,
  orders: OrdersReducer,
  invoices: InvoicesReducer,
  form: formReducer,
  vendorOwnerState: VendorOwnerReducer,
  reviews: OrdersReviewsReducer,
  compliance: ComplianceReducer,
  payouts: PayoutsReducer,
})

export default Reducer;