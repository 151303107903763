import '../../../../css/OrdersSummary.css';

import React, { Component } from 'react';
import { FormattedNumber, IntlProvider } from 'react-intl';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import { Card, CardBody } from 'reactstrap';
import { connect } from "react-redux";

class OrderCostSummary extends Component {

    render() {
        const { dailyOrderValueTotals, summaryData } = this.props;
        let dailyCostSummary = [];
        let summaryValue = 0;
        if(summaryData != null && summaryData.totalOrderValue != null){
            summaryValue = summaryData.totalOrderValue;
        }
        
        if(dailyOrderValueTotals != null){
            dailyCostSummary = dailyOrderValueTotals;
        }
        return (
            <div style={{width: "100%", height: "100%"}}>
            <IntlProvider locale={navigator.language}>
                <Card className="order-summary-card h-100">
                    <div className="headline-title">Total Order Value</div>
                    <CardBody>
                        <div className="headline-value">
                        {/* eslint-disable react/style-prop-object */
                            <FormattedNumber
                                value={summaryValue}
                                currency="GBP"
                                style="currency"
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}/>
                                /* eslint-enable react/style-prop-object */}
                        </div>
                        <div className="chart-wrapper">
                            <div className="headline-spark-chart">
                                    {dailyCostSummary.length > 1 && <Sparklines data={dailyCostSummary} 
                                        height={40}>
                                    <SparklinesLine style={{fill:"#e7131a", stroke:"#e7131a"}} />
                                    <SparklinesSpots style={{fill:"#e7131a"}}/>
                                    </Sparklines>}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </IntlProvider>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    summaryData: state.orders.summaryData,
    dailyOrderValueTotals: state.orders.dailyOrderValueTotals
})

export default connect(mapStateToProps)(OrderCostSummary)