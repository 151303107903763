import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { showOrderStats } from '../../actions/OrderActions';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import sizeMe from 'react-sizeme';
import FaqSection from './FaqSection';
import QuestionAndAnswer from './QuestionAndAnswer';
import ReviewsQuestions from './ReviewsQuestions';
import AccountQuestions from './AccountQuestions';
import OrderQuestions from './OrdersQuestions';
import MenuQuestions from './MenuQuestions';

const sizeMeHOC = sizeMe();

class Faqs extends Component {

    render() {
        const faqsHeadingText = "FAQs";
        const { size } = this.props;
        const isMobile = size.width < 768;
        const {showOrderStats} = this.props.actions;
        return (
            <div className="mh-100 minh-100 orders-container" style={{overflow: "auto"}}>
                <div className="mh-100 minh-100" style={{ overflow: "auto", paddingLeft: "20px", paddingRight: "20px" }}>
                    <Row className="summary-title-row">
                        <Col>
                            <div style={{ paddingRight: "60px", paddingTop: "10px", paddingBottom: "10px" }}>
                                <h1 className="d-none d-md-block" style={{ padding: "0px", color: "grey" }}>{faqsHeadingText}</h1>
                                <h3 className="d-md-none" style={{ padding: "0px", color: "grey", marginTop: "0px" }}>{faqsHeadingText}</h3>
                            </div>
                        </Col>
                    </Row>
                    <FaqSection title="Account"
                        questionsAndAnswers={AccountQuestions({showOrderStats}).map((question, index) =>
                            <QuestionAndAnswer
                                key={index}
                                question={question.question}
                                answer={question.answer}
                                id="account"
                            />
                        )}
                        id="account"
                    />
                    <FaqSection title="Orders"
                        questionsAndAnswers={OrderQuestions().map((question, index) =>
                            <QuestionAndAnswer
                                key={index}
                                question={question.question}
                                answer={question.answer}
                                id="orders"
                            />
                        )}
                        id="orders"
                    />
                    <FaqSection title="Menu"
                        questionsAndAnswers={MenuQuestions().map((question, index) =>
                            <QuestionAndAnswer
                                key={index}
                                question={question.question}
                                answer={question.answer}
                                id="menu"
                            />
                        )}
                        id="menu"
                    />
                    <FaqSection title="Order Reviews"
                        questionsAndAnswers={ReviewsQuestions({isMobile}).map((question, index) =>
                            <QuestionAndAnswer
                                key={index}
                                question={question.question}
                                answer={question.answer}
                                id="reviews"
                            />
                        )}
                        id="reviews"
                    />
                </div>
            </div>
        )
    }
}


  
  const mapActionsToDispatch = (dispatch) => {
    return {
      actions: bindActionCreators({ showOrderStats }, dispatch)
    }
  }

export default connect(null, mapActionsToDispatch)(sizeMeHOC(Faqs));