import React, { Component } from "react";
import {FaSpinner} from "react-icons/all";
import {Alert} from "reactstrap";
import LoaderButton from "../../LoaderButton";
import {Redirect} from "react-router-dom";

class Return extends Component {

    componentWillUnmount() {
        this.props.actions.cancelComplianceSessionInProgressPoller()
    }

    render() {

        const {
            complianceSession,
            vendor,
            actions,
            complianceSessionDeleteInProgress,
            compliancePollRequests,
            compliancePollingInProgress
        } = this.props;

        if(complianceSession && complianceSession.status === "COMPLIANCE_CHECKS_IN_PROGRESS") {
            // Ok, verification in progress, let's poll!
            if(! compliancePollingInProgress) {

                actions.startComplianceSessionInProgressPoller({
                    vendorId: vendor.vendorId,
                    ownershipPeriodId: vendor.ownershipPeriodId
                })
            }

            if(compliancePollRequests >= 24){
                return (<Redirect to="/compliance" />)
            }
        }

        return (
            <div className={"mb-3"}>

                {complianceSession.status === "COMPLIANCE_CHECKS_IN_PROGRESS" &&
                <div className={"d-flex justify-content-center align-items-center"}>
                    <FaSpinner className={"rotate"} style={{height: 40, width: 40}}
                               alt="Verification In Progress Icon"/>
                    <p id={"compliance-return-message"} className={"ml-2"}>Verification checks in progress,
                        please wait.</p>
                </div>}

                {/* Not compliant, scoffable compliance issues, so effectively dead */}
                {complianceSession.status === "NOT_COMPLIANT" && complianceSession.complianceIssues && complianceSession.complianceIssues.length > 0 &&
                <div>
                    <Alert id="scoffable-non-compliance-issues" color="danger" className="my-3">
                        The following problems were detected with your submission:
                        <ul className="mb-0 pl-4">
                            {complianceSession.complianceIssues.map(issue => <li key={issue}
                                                                                 className="ml-1">{issue}</li>)}
                        </ul>
                    </Alert>

                    <div id="scoffable-non-compliance-instructions">
                        <p className="mb-1">To complete this process online, the details entered must match.</p>

                        <ul className="pt-0" style={{"listStyleType": "circle"}}>
                            <li>If you've accidentally entered the wrong details, you can start again, ensuring the
                                details match this time.
                            </li>

                            <li>If the discrepancy is legitimate, please get in touch
                                on <a href="tel:01312082230">0131 208 2230</a> to
                                organise an in-person visit to sort this out. This can't be fixed online.
                            </li>
                        </ul>
                    </div>

                    <div className={"text-center mt-4"}>
                        <LoaderButton id={"delete-and-start-again-button"}
                                      className="fix-compliance-button btn-secondary"
                                      onClick={() => actions.deleteVendorComplianceSession({
                                          vendorId: vendor.vendorId,
                                          ownershipPeriodId: vendor.ownershipPeriodId
                                      })
                                      }
                                      text="Start Again" loadingText="Processing"
                                      isLoading={complianceSessionDeleteInProgress}
                        />
                    </div>


                </div>}

                {/* Not compliant, no scoffable compliance issues though, so should be fixable */}
                {(complianceSession.status === "NOT_COMPLIANT" || complianceSession.status === "FUTURE_NOT_COMPLIANT") &&
                !(complianceSession.complianceIssues && complianceSession.complianceIssues.length > 0) &&
                <div>

                    <p id={"compliance-return-message"}>Your account
                        { complianceSession.status === "NOT_COMPLIANT" && " isn't compliant" }
                        { complianceSession.status === "FUTURE_NOT_COMPLIANT" && " still has issues" }
                        . Please return to Stripe to supply outstanding information.</p>

                    <div className={"text-center mt-4"}>
                        <a id={"back-to-stripe-button"} className="fix-compliance-button btn-secondary"
                           href={complianceSession.url}>Return to Stripe</a>
                    </div>
                </div>}

            </div>
        );
    }

}

export default Return