
import React, { Component } from "react";
import { Responsive, Pagination, Dropdown, Segment } from "semantic-ui-react";
import OrdersTableView from "./desktop/OrdersTableView";
import OrdersCardView from "./mobile/OrdersCardView";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchOrders, updateOrdersListOrdersPerPage, updateOrdersListActivePage, toggleOrderSummaryView } from "../../../actions/OrderActions";
import FirstPaginationIcon from '../../../images/icons/pagination/first_page.svg';
import LastPaginationIcon from '../../../images/icons/pagination/last_page.svg';
import NextPaginationIcon from '../../../images/icons/pagination/next_page.svg';
import PreviousPaginationIcon from '../../../images/icons/pagination/previous_page.svg';
import LoadingSpinner from "../../../components/loading/LoadingSpinner";

const dropdownOptions = [
  { key: 10, text: "10", value: 10 },
  { key: 25, text: "25", value: 25 },
  { key: 30, text: "30", value: 30 },
  { key: 50, text: "50", value: 50 }
]

class OrdersListing extends Component {

  constructor(props){
    super(props);
    this.state = {
      currentPage: 1
    }
  }

  componentDidMount() {
    const [...disabledVendors] = this.props.vendors.filter((vendor) => { return vendor.selected === false }).values();
    this.props.actions.fetchOrders({
      fromDate: this.props.ordersListStartDate,
      opts: {
        'vendorIDBlacklist': disabledVendors.map(vendor => vendor.vendorID),
        'ordersPerPage': this.props.ordersListOrdersPerPage,
        'currentPage': this.props.ordersListActivePage,
        'toDate': this.props.ordersListEndDate
      }
    });
  }

  componentDidUpdate(lastProps) {
    if (lastProps.ordersListStartDate !== this.props.ordersListStartDate || lastProps.ordersListEndDate !== this.props.ordersListEndDate
      || lastProps.ordersListOrdersPerPage !== this.props.ordersListOrdersPerPage || lastProps.ordersListActivePage !== this.props.ordersListActivePage
    || lastProps.vendors !== this.props.vendors) {
        const [...disabledVendors] = this.props.vendors.filter((vendor) => { return vendor.selected === false }).values();
        this.props.actions.fetchOrders({
          fromDate: this.props.ordersListStartDate,
          opts: {
            'vendorIDBlacklist': disabledVendors.map(vendor => vendor.vendorID),
            'ordersPerPage': this.props.ordersListOrdersPerPage,
            'currentPage': this.props.ordersListActivePage,
            'toDate': this.props.ordersListEndDate
          }
        });
        if(this.props.ordersListActivePage !== this.state.currentPage){
          this.setState({currentPage: this.props.ordersListActivePage + 1})
        }
    }
  }

  render() {
    const { tableData, totalOrderNumber, ordersListOrdersPerPage, fetchOrdersInProgress, showOrderSummaryView, user } = this.props;
    const { currentPage } = this.state
    
    if (!tableData) {
      return <LoadingSpinner />
    }

    return (
      <Segment basic loading={fetchOrdersInProgress} className="order-table-wrapper">
        {!showOrderSummaryView && <Responsive {...Responsive.onlyMobile} loading={fetchOrdersInProgress.toString()} onUpdate={() => {
          if(!showOrderSummaryView){
            this.props.actions.toggleOrderSummaryView();
          }
        }}>
          <Dropdown compact downward
                    placeholder={"" + ordersListOrdersPerPage}
                    selection options={dropdownOptions}
                    className="items-dropdown tablet"
                    onChange={(event, data) => this.props.actions.updateOrdersListOrdersPerPage(data.value)} />
          <div className="clear"/>
          <OrdersCardView tableData={tableData} user={user} />
          <Pagination
              activePage={currentPage}
              totalPages={Math.ceil(totalOrderNumber / ordersListOrdersPerPage) || 1}
              className="orders-pagination-control tablet"
              onPageChange={(event, data) => {
                this.props.actions.updateOrdersListActivePage(data.activePage - 1);
                this.setState({currentPage: data.activePage});
                }}
              nextItem={false}
              prevItem={false}
              lastItem={false}
              firstItem={false}
          />

        </Responsive>}
        <Responsive {...Responsive.onlyTablet} loading={fetchOrdersInProgress.toString()} onUpdate={() => {
          if(!showOrderSummaryView){
            this.props.actions.toggleOrderSummaryView();
          }
        }}>
          <OrdersTableView tableData={tableData} user={user} />
          <Pagination
              activePage={currentPage}
              totalPages={Math.ceil(totalOrderNumber / ordersListOrdersPerPage) || 1}
              className="orders-pagination-control"
              onPageChange={(event, data) => {
                this.props.actions.updateOrdersListActivePage(data.activePage - 1);
                this.setState({currentPage: data.activePage});
                }}
              firstItem={{content:(<img src={FirstPaginationIcon} alt={"Navigate to first page"} />), 'aria-label': 'First Page'}}
              lastItem={{content: (<img src={LastPaginationIcon} alt={"Navigate to last page"} />), 'aria-label': 'Last Page'}}
              nextItem={{content: (<img src={NextPaginationIcon} alt={"Navigate to next page"} />), 'aria-label': 'Next Page'}}
              prevItem={{content: (<img src={PreviousPaginationIcon} alt={"Navigate to previous page"} />), 'aria-label': "Previous Page"}}
          />
          <Dropdown compact upward placeholder={"" + ordersListOrdersPerPage} selection options={dropdownOptions} style={{ float: "left", marginLeft: "10px", marginTop: "10px", marginBottom: "10px" }}
            onChange={(event, data) => this.props.actions.updateOrdersListOrdersPerPage(data.value)} />
        </Responsive>
        <Responsive {...Responsive.onlyComputer} loading={fetchOrdersInProgress.toString()} onUpdate={() => {
          if(!showOrderSummaryView){
            this.props.actions.toggleOrderSummaryView();
          }
        }}>
          <OrdersTableView tableData={tableData} user={user} />
          <Pagination
              activePage={currentPage}
              totalPages={Math.ceil(totalOrderNumber / ordersListOrdersPerPage) || 1}
              className="orders-pagination-control"
              onPageChange={(event, data) => {
              this.props.actions.updateOrdersListActivePage(data.activePage - 1);
              this.setState({currentPage: data.activePage});
              }}
              firstItem={{content:(<img src={FirstPaginationIcon} alt={"Navigate to first page"} />), 'aria-label': 'First Page'}}
              lastItem={{content: (<img src={LastPaginationIcon} alt={"Navigate to last page"} />), 'aria-label': 'Last Page'}}
              nextItem={{content: (<img src={NextPaginationIcon} alt={"Navigate to next page"} />), 'aria-label': 'Next Page'}}
              prevItem={{content: (<img src={PreviousPaginationIcon} alt={"Navigate to previous page"} />), 'aria-label': "Previous Page"}}
              />
          <Dropdown compact upward placeholder={"" + ordersListOrdersPerPage} selection options={dropdownOptions} style={{ float: "left", marginLeft: "10px", marginTop: "10px", marginBottom: "10px" }}
            onChange={(event, data) => this.props.actions.updateOrdersListOrdersPerPage(data.value)} />
        </Responsive>
        </Segment>
    );
  }
}

const mapStateToProps = (state) => {
    return {
        vendors: state.vendor.vendors,
        tableData: state.orders.tabledata,
        totalOrderNumber: state.orders.totalOrderNumber,
        currentPage: state.orders.currentPage,
        ordersListEndDate: state.orders.ordersListEndDate,
        ordersListStartDate: state.orders.ordersListStartDate,
        ordersListOrdersPerPage: state.orders.ordersListOrdersPerPage,
        ordersListActivePage: state.orders.ordersListActivePage,
        fetchOrdersInProgress: state.orders.fetchOrdersInProgress,
        showOrderSummaryView: state.orders.showOrderSummaryView,
        user: state.user.user
    }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchOrders, updateOrdersListOrdersPerPage, updateOrdersListActivePage, toggleOrderSummaryView }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersListing)