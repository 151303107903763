import React, {Component} from 'react'
import {Form, Message} from 'semantic-ui-react'
import moment from 'moment'
import {Modal, ModalHeader, ModalBody, ModalFooter, Tooltip, Container, Button} from 'reactstrap'
import '../../css/OrderReviews.css'
import LoaderButton from '../../components/LoaderButton'
import trashIcon from '../../images/icons/reviews/trash_icon.svg';
import editReplyIcon from '../../images/icons/reviews/edit_reply_icon.svg';
import editReplyDisabledIcon from '../../images/icons/reviews/edit_reply_disabled_icon.svg';
import rejectedReplyIcon from '../../images/icons/reviews/rejected_reply_icon.svg';
import replyModerationIcon from '../../images/icons/reviews/reply_moderation_icon.svg';
import {userHasPermissionForVendor} from "../../utils/helpers/User";
import {CREATE_REVIEWS_REPLIES} from "../../utils/helpers/Permission";

export default class ReplyCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            editedContent: props.reply.content,
            isError: false,
            errorMessageText: "",
            isPristine: true,
            deleteModalOpen: false,
            tooltipOpen: false,
            editNotPermittedTooltipOpen: false,
        }
    }

    toggleEditNotPermittedTooltip() {
        if(!this.props.reply.editable) {
            this.setState({
                editNotPermittedTooltipOpen: !this.state.editNotPermittedTooltipOpen
            });
        }
    }

    handleOnTextChanged = (value) => {
        const {isPristine} = this.state;
        if (isPristine) {
            this.setState({isPristine: false})
        } else {
            if (value === this.props.reply.content) {
                this.setState({isPristine: true})
            }
        }
        if (!value || value === "" || !/[^\s]+/.test(value)) {
            console.log("Empty reply value")
            this.setState({isError: true, errorMessageText: "Reply must have content", editedContent: value})
        } else if (value.length >= 3000) {
            this.setState({iserror: true, errorMessageText: "Reply must be less than 3000 characters"})
        } else {
            this.setState({editedContent: value, isError: false, errorMessageText: ""})
        }
    }

    toggleEditReplyForm = () => {
        this.setState({editing: !this.state.editing})
    }

    toggleDeleteModal = () => {
        this.setState({deleteModalOpen: !this.state.deleteModalOpen})
    }

    toggleTooltip = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        const {reply, reviewId, deleteOrderReviewReply, editOrderReviewReply, orderReviewsSubmitting, orderReviewReplyError, vendor, user} = this.props;
        const {editing, editedContent, isPristine, isError, errorMessageText} = this.state;
        const deleteModalOpen = this.state.deleteModalOpen
        return (
            <Form loading={orderReviewsSubmitting.includes(reviewId)} error={isError || orderReviewReplyError != null}>
                <Container className="no-padding no-margin">
                    {!editing &&
                    <Container className="no-margin no-padding">
                        <div className="flex-nowrap no-padding">
                            <div className="no-padding no-margin">
                                <div className="reply-container">
                                    <div className="reply-content">
                                    <h2>Your Reply</h2>
                                    <div className="reply-content-text">
                                        {reply.content.split('\n').map((content, i) => <p key={i}>{content}</p>)}
                                    </div>
                                    {reply.moderated && !reply.acceptable && !editing &&
                                    <Message negative size='mini' icon="exclamation triangle" className="reply-rejected-warning">
                                        <img src={rejectedReplyIcon} alt="Rejected Reply"/>
                                        <p>This reply has been rejected as it does not
                                            comply with our <a href="https://www.scoffable.com/review/guidelines" target="_blank" rel="noopener noreferrer">guidelines</a>.
                                            Please edit the reply and resubmit to ensure it can be displayed
                                            publicly.</p>
                                    </Message>}

                                    {!reply.moderated && !editing &&
                                    <Message negative size='mini' icon="exclamation triangle" className="reply-moderation-message">
                                        <img src={replyModerationIcon} alt="Reply needs to be moderated"/>
                                        <p>This reply needs to be moderated before it can be displayed publicly. This is normally completed within 48
                                            hours.</p>
                                    </Message>
                                    }
                                    </div>

                                    { userHasPermissionForVendor(user, vendor.vendorID, CREATE_REVIEWS_REPLIES) &&
                                        reply.finalised !== true &&
                                        <div className="reply-edit-controls">
                                            <button data-cy='edit-review-reply-button' id={`reply-edit-button-${reply.id}`} className={`reply-edit-control ${!reply.editable ? "disabled" : ""}`} onClick={() => {
                                                reply.editable ? this.setState({editing: true}) : this.toggleEditNotPermittedTooltip();
                                            }}><img src={reply.editable ? editReplyIcon : editReplyDisabledIcon} alt="Edit Reply"/>
                                            </button>

                                            <Tooltip isOpen={this.state.editNotPermittedTooltipOpen} placement="bottom" toggle={this.toggleEditNotPermittedTooltip.bind(this)} target={`#reply-edit-button-${reply.id}`}>
                                                You can only edit recent replies
                                            </Tooltip>

                                            <button data-cy='delete-review-reply-button' className="reply-edit-control" onClick={() => {
                                                this.toggleDeleteModal()
                                            }}><img src={trashIcon} alt="Delete Reply"/>
                                            </button>
                                        </div>
                                    }

                                    <div className="reply-date">{moment(reply.submittedDate).format("dddd, MMMM Do YYYY, h:mm a")}</div>
                                </div>
                            </div>
                        </div>
                    </Container>}

                    {editing && <div className="reply-edit-section">
                        <Message
                            error
                            content={errorMessageText || orderReviewReplyError} icon="exclamation triangle"/>
                        <Form.TextArea value={editedContent}
                                       onChange={(e, {_, value}) => this.handleOnTextChanged(value)}/>
                        <LoaderButton
                            block
                            disabled={isPristine || isError}
                            data-cy="submit-review-reply-edit-button"
                            text="Submit Reply"
                            className="btn-default"
                            onClick={() => editOrderReviewReply({
                                reviewId: reviewId,
                                replyId: reply.id,
                                replyText: editedContent,
                                toggleEditReplyForm: this.toggleEditReplyForm
                            })}/>
                        <div className="mt-1 w-100 text-right">
                            <button className="link-button black-link" style={{marginBottom: "10px"}}
                                    onClick={() => this.setState({editing: false})}> Cancel
                            </button>
                        </div>
                    </div>}
                </Container>

                <Modal isOpen={deleteModalOpen} toggle={this.toggleDeleteModal} centered>
                    <ModalHeader toggle={this.toggleDeleteModal}>Are you sure?</ModalHeader>
                    <ModalBody>
                        Please confirm that you want to delete this reply?
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-secondary btn-destructive" onClick={() => {
                            deleteOrderReviewReply({reviewId: reviewId, replyId: reply.id})
                        }}>Delete</Button>{' '}
                        <Button className="btn-secondary cancel-button" onClick={this.toggleDeleteModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </Form>)
    }
}
