export function fetchPayouts(payload){
    return {
        type: "FETCH_PAYOUTS",
        payload: payload
    }
}

export function fetchPayoutsSuccess(payload){
    return {
        type: "FETCH_PAYOUTS_SUCCESS",
        payload: payload
    }
}

export function fetchPayoutsFailed(error){
    return {
        type: "FETCH_PAYOUTS_FAILED",
        payload: error
    }
}