/* eslint-disable eqeqeq */
import { put, takeLatest, call } from 'redux-saga/effects';
import * as VendorOwnerActions from '../../actions/VendorOwnerActions';
import * as UserActions from '../../actions/UserActions';
import ApiClientProvider from '../../utils/api/ApiClientProvider';
import Raven from 'raven-js'
import * as Sentry from '@sentry/browser';
import * as VendorOwnerFormRoutes from '../../components/vendor-owner-details/forms/VendorOwnerFormRoutes';
import delayP from "@redux-saga/delay-p";
const SECOND = 1000;


function* requestOtpCodeToRegisteredPhone(action) {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.createInFlightVendorOwnerDetailsUpdateRequestUsingPUT({});
        yield put(VendorOwnerActions.requestOtpToRegisteredPhoneSuccess(data));
    } catch (e) {
        if (!e.response) {
            console.log(e.response)
            Sentry.captureException(e);
            yield put(VendorOwnerActions.requestOtpToRegisteredPhoneFailed({ message: "Unable to process request for verification code." }));
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            case (423): {
                console.log(e.response.data)
                yield put(VendorOwnerActions.requestOtpToRegisteredPhoneFailed(e.response.data.message));
                break;
            }
            case (429): {
                yield put(VendorOwnerActions.rateLimitRequestOtpToRegisteredPhone());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.requestOtpToRegisteredPhoneFailed());
            }
        }
    }
}

function* submitOtpCodeForRegisteredPhone(action) {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.verifyIdentityForVendorOwnerDetailsUpdateRequestUsingPATCH({
            otpValidationRequest: {
                guid: action.payload.otpGuid,
                otpCode: action.payload.otpCode
            }
        });
        yield put(VendorOwnerActions.submitOtpCodeForRegisteredPhoneSuccess(data));
        action.payload.push(VendorOwnerFormRoutes.CHANGE_YOUR_DETAILS);
    } catch (e) {
        if (!e.response) {
            Sentry.captureException(e);
            yield put(VendorOwnerActions.submitOtpCodeForRegisteredPhoneFailed({ message: "Unable to process request to submit verification code." }));
            return;
        }
        switch (e.response.status) {
            case 401: {
                yield put(UserActions.logout());
                break;
            }
            case 403: {
                yield put(VendorOwnerActions.wrongOtpCodeSubmittedForVendorIdentity());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.submitOtpCodeForRegisteredPhoneFailed( { message: "Unable to process request to submit verification code." } ));
                break;
            }
        }
    }
}

function* submitNewDetails(action) {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.addNewDetailsVendorOwnerDetailsUpdateRequestUsingPATCH({
            vendorOwnerDetailsUpdateRequest: {
                detailsToUpdate: action.payload.detailsUpdates
            }
        });
        yield put(VendorOwnerActions.submitNewDetailsSuccess(data));
        action.payload.push(VendorOwnerFormRoutes.CONFIRM_YOUR_DETAILS);
    } catch (e) {
        if (!e.response) {
            Sentry.captureException(e);
            yield put(VendorOwnerActions.submitNewDetailsFailed({ message: "Unable to process request to submit new details." }));
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            case (400): {
                yield put(VendorOwnerActions.submitNewDetailsFailed(e.response.data.message));
                break;
            }
            case 423: {
                yield put(VendorOwnerActions.lockVendorOwner());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.submitNewDetailsFailed({message: "Unable to process request to submit new details"}));
                break;
            }
        }
    }
}

function* fetchInFlightVendorOwnerDetailsUpdate() {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.getInFlightVendorOwnerDetailsUpdateRequestsForCurrentUserUsingGET();
        yield put(VendorOwnerActions.fetchInFlightVendorOwnerDetailsUpdateSuccess( data));
    } catch (e) {
        if (!e.response) {
            Sentry.captureException(e);
            yield put(VendorOwnerActions.fetchInFlightVendorOwnerDetailsUpdateFailed({ message: "Unable to process request for in flight vendor owner update." }));
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            case (404): {
                yield put(VendorOwnerActions.vendorOwnerDetailsUpdateNotFound());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.fetchInFlightVendorOwnerDetailsUpdateFailed(e));
                break;
            }
        }
    }
}

function* submitOtpCode(action) {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.verifyNewDetailsForVendorOwnerDetailsUpdateRequestUsingPATCH(
            {
                updateRequestGuid: action.payload.requestGuid,
                otpValidationRequest: {
                    otpCode: action.payload.otpCode,
                    guid: action.payload.otpGuid
                }
            });
        yield put(VendorOwnerActions.submitOtpCodeSuccess({inFlightVendorOwnerChange: data, otpGuid: action.payload.otpGuid}));
    } catch (e) {
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            case 403: {
                yield put(VendorOwnerActions.submitOtpCodeFailed({ error: "verification code provided is incorrect.", otpGuid: action.payload.otpGuid }))
                break;
            }
            case (422): {
                yield put(VendorOwnerActions.otpExpired(action.payload));
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.submitOtpCodeFailed({ error: "Unable to submit verification code.", otpGuid: action.payload.otpGuid }));
                break;
            }
        }
    }
}

function* otpExpired(action) {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.resendOtpForNewDetailsUsingPUT({
            updateRequestGuid: action.payload.requestGuid,
            otpGuid: action.payload.otpGuid
        });
        yield put(VendorOwnerActions.otpExpiredSuccess({ inFlightVendorOwnerChange: data, otpGuid: action.payload.otpGuid }));
    } catch (e) {
        if (!e.response) {
            Sentry.captureException(e);
            yield put(VendorOwnerActions.submitOtpCodeFailed({ message: "Unable to process request to submit verification code." }));
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            case (422): {
                yield put(VendorOwnerActions.otpExpired(action.payload.otpGuid));
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.submitOtpCodeFailed({ error: "Unable to process request to submit verification code.", otpGuid: action.payload.otpGuid }));
                break;
            }
        }
    }
}

function* fetchSigningUrl(action) {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        let response;
        for (let x = 0; x < 60; x++) {
            response = yield apiInstance.generateContractUrlUsingPOST();
            if (response.data.agreementUrl) {
                break;
            }
            if (x === 59) {
                yield put(VendorOwnerActions.fetchSigningUrlFailed(new Error("Contract generation timed out.")));
                break;
            }
            yield call(delayP, SECOND); // wait 1 second
        }
        yield put(VendorOwnerActions.fetchSigningUrlSuccess(response.data));
    } catch (e) {
        if (!e.response) {
            console.log(e)
            Sentry.captureException(e);
            yield put(VendorOwnerActions.fetchSigningUrlFailed({ message: "Unable to process request to fetch contract signing URL." }));
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.fetchSigningUrlFailed(e));
                break;
            }
        }
    }
}

function* completeVendorOwnerDetailsUpdate() {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        yield apiInstance.completeVendorOwnerUpdateUsingPATCH();
        yield put(VendorOwnerActions.completeVendorOwnerDetailsUpdateSuccess());
    } catch (e) {
        if (!e.response) {
            Sentry.captureException(e);
            yield put(VendorOwnerActions.completeVendorOwnerDetailsUpdateFailed("Unable to process request to complete vendor owner details update."));
            return;
        }
        switch (e.response.status) {
            case 401: {
                yield put(UserActions.logout());
                break;
            }
            case 404: {
                yield put(VendorOwnerActions.completeVendorOwnerDetailsUpdateFailed("Could not find vendor owner details request update to complete.  Request may have expired."))
                yield put(VendorOwnerActions.fetchVendorOwnerAfterUpdate());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.completeVendorOwnerDetailsUpdateFailed("Unable to process request to complete vendor owner details update."));
                break;
            }
        }
    }
}

function* resendOtpCodeToRegisteredPhone(action) {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.createInFlightVendorOwnerDetailsUpdateRequestUsingPUT({});
        yield put(VendorOwnerActions.resendOtpToRegisteredPhoneSuccess(data));
    } catch (e) {
        if (!e.response) {
            Sentry.captureException(e);
            yield put(VendorOwnerActions.resendOtpToRegisteredPhoneFailed({ message: "Unable to process request to resend verification code." }));
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            case (429): {
                yield put(VendorOwnerActions.rateLimitResendOtpToRegisteredPhone());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.resendOtpToRegisteredPhoneFailed("Unable to process request to resend verification code."));
            }
        }
    }
}

function* cancelInFlightUpdate(action) {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        yield apiInstance.cancelInFlightVendorOwnerDetailsUpdateRequestsForCurrentUserUsingDELETE();
        yield put(VendorOwnerActions.cancelInFlightUpdateSuccess());
    } catch (e) {
        if (!e.response) {
            Sentry.captureException(e);
            yield put(VendorOwnerActions.cancelInFlightUpdateFailed({ message: "Unable to process request to cancel vendor owner details update." }));
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.cancelInFlightUpdateFailed("Unable to process request to cancel vendor owner details update."));
            }
        }
    }
}

function* resendOtpToNewDetails(action) {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.resendOtpForNewDetailsUsingPUT({
            updateRequestGuid: action.payload.updateRequestGuid,
            otpGuid: action.payload.otpGuid
        });
        yield put(VendorOwnerActions.resendOtpToNewDetailsSuccess({ inFlightVendorOwnerChange: data, oldOtpGuid: action.payload.otpGuid }));
    } catch (e) {
        if (!e.response) {
            console.log(e)
            Sentry.captureException(e);
            yield put(VendorOwnerActions.resendOtpToNewDetailsFailed({ guid: action.payload.otpGuid, error: "Unable to resend verification code." }));
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            case (429): {
                yield put(VendorOwnerActions.resendOtpToNewDetailsFailed({ guid: action.payload.otpGuid, error: e.response.data.message }));
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.resendOtpToNewDetailsFailed({ guid: action.payload.otpGuid, error: "Unable to resend verification code." }));
            }
        }
    }
}

function* fetchVendorOwnerLockedStatus() {
    try {
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.fetchVendorOwnerAssociatedLocksUsingGET();
        yield put(VendorOwnerActions.fetchVendorOwnerLockedStatusSuccess(data)); 
    } catch (e) {
        if (!e.response) {
            Sentry.captureException(e);
            yield put(VendorOwnerActions.fetchVendorOwnerLockedStatusFailed());
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.fetchVendorOwnerLockedStatusFailed());
            }
        }
    }
}

function* fetchVendorOwnerAfterUpdate(){
    try{
        let apiInstance = ApiClientProvider.getVendorOwnerControllerApiInstance();
        const {data} = yield apiInstance.getVendorOwnerDetailsUsingGET();
        yield put(VendorOwnerActions.fetchVendorOwnerAfterUpdateSuccess(data));
    } catch(e){
        if (!e.response) {
            Sentry.captureException(e);
            yield put(VendorOwnerActions.fetchVendorOwnerAfterUpdateFailed({ message: "Unable to process request for vendor owner details" }));
            return;
        }
        switch (e.response.status) {
            case (401): {
                yield put(UserActions.logout());
                break;
            }
            default: {
                console.log(e)
                Raven.captureException(e);
                yield put(VendorOwnerActions.fetchVendorOwnerAfterUpdateFailed());
            }
        }
    }
}

export default function* rootVendorOwnerSaga() {
    yield takeLatest(VendorOwnerActions.requestOtpToRegisteredPhone().type, requestOtpCodeToRegisteredPhone);
    yield takeLatest(VendorOwnerActions.submitOtpCodeForRegisteredPhone().type, submitOtpCodeForRegisteredPhone);
    yield takeLatest(VendorOwnerActions.submitNewDetails().type, submitNewDetails);
    yield takeLatest(VendorOwnerActions.fetchInFlightVendorOwnerDetailsUpdate().type, fetchInFlightVendorOwnerDetailsUpdate);
    yield takeLatest(VendorOwnerActions.submitOtpCode().type, submitOtpCode);
    yield takeLatest(VendorOwnerActions.otpExpired().type, otpExpired);
    yield takeLatest(VendorOwnerActions.fetchSigningUrl().type, fetchSigningUrl);
    yield takeLatest(VendorOwnerActions.completeVendorOwnerDetailsUpdate().type, completeVendorOwnerDetailsUpdate);
    yield takeLatest(VendorOwnerActions.resendOtpToRegisteredPhone().type, resendOtpCodeToRegisteredPhone);
    yield takeLatest(VendorOwnerActions.cancelInFlightUpdate().type, cancelInFlightUpdate);
    yield takeLatest(VendorOwnerActions.resendOtpToNewDetails().type, resendOtpToNewDetails);
    yield takeLatest(VendorOwnerActions.fetchVendorOwnerLockedStatus().type, fetchVendorOwnerLockedStatus);
    yield takeLatest(VendorOwnerActions.fetchVendorOwnerAfterUpdate().type, fetchVendorOwnerAfterUpdate);
}
/* eslint-enable eqeqeq */