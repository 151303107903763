import React from 'react';

const addressString = (address) => {
    var maxChars = 100;
    var text = "";
    if (address !== undefined) {
        text = (undefined !== address.organisation ? address.organisation + ", " : "")
            + (address.firstLine !== undefined ? address.firstLine + ", " : "")
            + (address.secondLine !== undefined ? address.secondLine + ", " : "")
            + (address.locality !== undefined ? address.locality + ", " : "")
            + (address.town !== undefined ? address.town + ", " : "")
            + (address.county !== undefined ? address.county + ", " : "")
            + (address.postcode !== undefined ? address.postcode + " " : "");

        if (text.length > maxChars) {
            text = text.substr(0, maxChars) + "...";
        }
    }
    return text;
}

const Address = props => {
    return (
        <span>{addressString(props)}</span>
    )
}

export default Address;
