import React, { Component } from 'react';
import { Card, CardText, Row, Col, Button } from 'reactstrap';
import CompleteIcon from "../../../../images/icons/bank-details/complete.svg";
import Loader from 'react-loader-spinner';
export default class Complete extends Component {

    componentDidMount() {
        // this.props.completeVendorOwnerDetailsUpdate();
    }

    render() {
        const { vendorOwner, push, updatingVendorOwner, fetchingVendorOwnerAfterUpdate } = this.props;
        return (
            <Row>
                <Col xs="12" sm="12" md="12" lg={{ size: 6, offset: 3 }} xl={{ size: 6, offset: 3 }}>
                    {(updatingVendorOwner || fetchingVendorOwnerAfterUpdate) && <Row className="row justify-content-center"><Loader
                        type="Puff"
                        color="#e7131a"
                        height="50"
                        width="50" /></Row>}
                    {(!updatingVendorOwner && !fetchingVendorOwnerAfterUpdate) && <Card body style={{padding: "0px"}}>
                        <CardText>
                            <Row className="row justify-content-center">
                                <span>Details succesfully updated</span>
                            </Row>
                            <Row className="row justify-content-center">
                                    <Col xs={{ size: "auto", offset: 0 }} sm={{ size: "auto", offset: 0 }} md={{ size: "auto", offset: 0 }} lg={{ size: "auto", offset: 0 }} xl={{ size: "auto", offset: 0 }}>
                                        <Row className="row justify-content-center" style={{padding: "0px"}}>
                                            <img src={CompleteIcon} style={{ height: 80, width: 80 }} alt="Complete Icon" />
                                        </Row>
                                    </Col>
                                    <Col xs={{ size: "auto", offset: 0 }} sm={{ size: "auto", offset: 0 }} md={{ size: "auto", offset: 0 }} lg={{ size: "auto", offset: 0 }} xl={{ size: "auto", offset: 0 }} style={{padding: "0px"}}>
                                        <span>
                                            <span style={{ display: "block" }}><b>Email Address:</b></span> <span style={{ display: "block" }}>{vendorOwner.emailAddress}</span>
                                            <span style={{ display: "block" }}><b>Contact Number:</b></span> <span style={{ display: "block" }}>{vendorOwner.telephoneNumber}</span>
                                        </span>
                                    </Col>
                            </Row>
                            <div style={{ paddingTop: "20px", margin: "10px" }}>
                                <Button block className="btn-default" style={{ cursor: "pointer" }} onClick={() => push("/user-management")}>
                                    Return to User Management
                            </Button>
                            </div>
                        </CardText>
                    </Card>}
                </Col>
            </Row>
        )
    }
}
