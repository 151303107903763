import React, { Component } from 'react';
import "../../css/ReviewPercentage.css";
import 'react-circular-progressbar/dist/styles.css';

export default class ReviewPercentage extends Component {
  render() {
    const { reviewSummary } = this.props;

      return <div className="customer-satisfaction">
        <h3>Customer Satisfaction</h3>
        <div className="percent-positive">{reviewSummary.displayFormattedPositivePercentage ? reviewSummary.displayFormattedPositivePercentage : "--%"}</div>
        <div className="based-on-text">{reviewSummary.displayFormattedPositivePercentage ? `Based on ${reviewSummary.reviewCount} most recent reviews` : 'Not enough recent reviews to generate a score'}</div>
      </div>;
  }
}