import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import Raven from 'raven-js';
import 'bootstrap/dist/css/bootstrap.css';
import 'semantic-ui-css/semantic.min.css';
import './index.css';
import App from './App';
import packageInfo from '../package.json';

const suppressErrorsInDevelopment = true

if(suppressErrorsInDevelopment && process.env.NODE_ENV === "development") {
    console.log("Not initialising Sentry as running in development")
} else {
    Raven.config('https://b6a8fba688ce4c01bf3564d4f42329bf@sentry.io/1242218', {
        autoBreadcrumbs: false,
        environment: process.env.NODE_ENV,
        release: packageInfo.version
    }).install();
    Sentry.init({
        dsn: 'https://b6a8fba688ce4c01bf3564d4f42329bf@sentry.io/1242218',
        environment: process.env.NODE_ENV,
        debug: process.env.NODE_ENV === 'development',
        release: packageInfo.version
    });
}
ReactDOM.render(<App />,document.getElementById("root"));