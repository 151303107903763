const defaultExport = values => {
    const errors = {};
    const accountNumberRegex = new RegExp(/^\d{7,8}$/);
    const sortCodeRegex = new RegExp(/^\d{6}$/);
    if(!values.accountNumber){
        errors.accountNumber = "Account number is required";
    }
    if(values.accountNumber && !accountNumberRegex.test(values.accountNumber)){
        errors.accountNumber = "Account number has to have either 7 or 8 digits"
    }
    if(!values.sortCode){
        errors.sortCode = "Sort code is required";
    }
    if(values.sortCode && !sortCodeRegex.test(values.sortCode)){
        errors.sortCode = "Sort code has to have 6 digits."
    }
    return errors;
}

export default defaultExport