import React, { Component } from 'react';
import { Grid, Container } from 'semantic-ui-react';
import ReviewPercentage from './ReviewPercentage';
import ReviewSummaryChart from "./ReviewSummaryChart";
import VendorLogoAddressView from '../../components/vendor/VendorLogoAddressView';

export default class VendorReviewSummary extends Component {
    render() {
        const { reviewSummary, isMobile, width } = this.props;
        return (
            <Container className="no-padding no-margin">
                <Grid textAlign="center" className="no-padding no-margin">
                    <Grid.Row key={reviewSummary.vendorId} columns={isMobile ? 1 : 'equal'} style={{margin: '0 0 10px 0', padding: '0 0 10px 0'}} centered>
                        <Grid.Column mobile={16} tablet={16} computer={8} className="no-padding no-margin">
                            <VendorLogoAddressView vendorID={reviewSummary.vendorId}/>
                            <Grid className="customer-satisfaction-summary-grid">
                                    <Grid.Column textAlign="center" className="customer-satisfaction-summary-grid-content no-padding no-margin" >
                                        <ReviewPercentage reviewSummary={reviewSummary} isMobile={isMobile} emoticon={reviewSummary.emoticon} />
                                    </Grid.Column>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column mobile={16} tablet={16} computer={8} className="no-padding no-margin">
                            <ReviewSummaryChart reviewSummary={reviewSummary} isMobile={isMobile} width={width}/>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }
}
