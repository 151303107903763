import React, { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import LoaderButton from '../../../LoaderButton';
import { Field, reduxForm } from 'redux-form';
import PhoneNumber from '../fields/PhoneNumber';
import EmailAddress from '../fields/EmailAddress';

class ChangeYourDetails extends Component {
  render() {
    const { submitNewDetailsError, submittingNewDetails } = this.props;
    return (
      <div>
                <Row>
                    <Col>
                        <span style={{ fontSize: "18px" }}>Change your details</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <form onSubmit={this.props.handleSubmit}>
                            <Field name="phoneNumber" type="text" component={PhoneNumber} />
                            <Field name="emailAddress" type="text" component={EmailAddress} />

                            {submitNewDetailsError && <Col >
                            <Alert style={{textAlign: "center"}} color="danger">
                                {submitNewDetailsError}
                            </Alert>
                            </Col>}
                            <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                                <LoaderButton
                                    block
                                    type="submit"
                                    isLoading={submittingNewDetails}
                                    text="Submit"
                                    loadingText="Submitting…"
                                    className="btn-default"
                                    style={{ cursor: this.props.pristine ? "default" : "pointer"}}
                                    disabled={this.props.pristine} />
                                <div className="mt-1 w-100 text-right">
                                    <button className="link-button black-link" onClick={() => this.props.cancelInFlightUpdate()}> Cancel</button>
                                </div>
                            </Col>
                        </form>
                    </Col>
                </Row>
            </div>
    )
  }
}

export default reduxForm({
  form: "EnterNewDetails"
})(ChangeYourDetails)