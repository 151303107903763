import React, { Component } from 'react';
import { Segment, Responsive } from 'semantic-ui-react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/Carousel.css"
import '../../css/SummaryCard.css'

import VendorReviewSummary from "./VendorReviewSummary"

export default class VendorReviewSummaries extends Component {

  constructor(props){
    super();
    this.state = {}
  }

  handleOnUpdate = (e, { width }) => this.setState({ width })

  render() {
    const { width } = this.state;
    const isMobile = width <= 768;
    const { reviewSummaries } = this.props;
    const isReviewSummariesEmpty = reviewSummaries === null || reviewSummaries.size === 0;
    if(isReviewSummariesEmpty){
      return <div />
    }
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centreMode: true
    };

    const slides = reviewSummaries.map((reviewSummary) => {
      return (
          <VendorReviewSummary key={reviewSummary.vendorId} reviewSummary={reviewSummary} isMobile={isMobile} width={width} />
      );
    });
    if (reviewSummaries.size > 1) {
      return (
         <Responsive as={Segment} fireOnMount={true} onUpdate={this.handleOnUpdate} className="summary-section">
          <Slider {...settings} style={{color: '#000'}}>
            {slides}
          </Slider>
        </Responsive>
      )
    } else {
      return (
      <Responsive as={Segment} fireOnMount={true} onUpdate={this.handleOnUpdate} className="summary-section" >
        <VendorReviewSummary reviewSummary={reviewSummaries.first()} isMobile={isMobile} width={width}/>
      </Responsive>);
    }

  }
}
