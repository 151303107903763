import {race, call, put, take, takeLatest, delay} from 'redux-saga/effects'
import * as ComplianceActions from '../../actions/ComplianceActions';
import * as UserActions from '../../actions/UserActions';
import ApiClientProvider from '../../utils/api/ApiClientProvider';
import * as Sentry from '@sentry/browser';

function* fetchVendorComplianceSession(action) {
    const apiInstance = ApiClientProvider.getComplianceControllerApiInstance();
    const requestData = {
        ownershipPeriodId: action.payload.ownershipPeriodId,
        vendorId: action.payload.vendorId,
        request: {}
    };

    try {
        const {data} = yield apiInstance.getComplianceSessionUsingGET(requestData);
        yield put({ type: ComplianceActions.fetchVendorComplianceSessionSuccess().type, payload: data});
    } catch (e) {
        if(e.response && e.response.status === 404 ){
            try {
                const {data} = yield apiInstance.addNewComplianceSessionUsingPOST(requestData)
                yield put({ type: ComplianceActions.fetchVendorComplianceSessionSuccess().type, payload: data});
            } catch (e) {
                yield* handleFetchException(e)
            }
        } else{
            yield* handleFetchException(e)
        }
    }
}

function* deleteVendorComplianceSession(action) {
    const apiInstance = ApiClientProvider.getComplianceControllerApiInstance();
    try {
        const {data} = yield apiInstance.deleteComplianceSessionUsingDELETE({
            vendorId: action.payload.vendorId,
            ownershipPeriodId: action.payload.ownershipPeriodId
        });
        yield put({ type: ComplianceActions.deleteVendorComplianceSessionSuccess().type, payload: data});
    } catch (e) {
        Sentry.captureException(e);
        yield put(ComplianceActions.deleteVendorComplianceSessionFailed());
    }
}

function* handleFetchException(e){
    if(!e.response){
        Sentry.captureException(e);
        yield put(ComplianceActions.fetchVendorComplianceSessionFailed());
        return;
    }
    if(e.response.status === 401){
        yield put(UserActions.logout());
    }
    Sentry.captureException(e);
    yield put(ComplianceActions.fetchVendorComplianceSessionFailed());
}


function* startComplianceSessionInProgressPoller(action) {
    yield race({
        task: call(compliancePoller, action),
        cancel: take(ComplianceActions.cancelComplianceSessionInProgressPoller().type)
    })
}

function* compliancePoller(action) {
    while (true) {
        try {
            yield call(fetchVendorComplianceSession, action);
            yield delay(5000)
        } catch (err) {
            console.error("Error in poller: " + err)
            yield put({type: ComplianceActions.cancelComplianceSessionInProgressPoller().type, err});
        }
    }
}


export default function* rootComplianceSaga() {
    yield takeLatest(ComplianceActions.fetchVendorComplianceSession().type, fetchVendorComplianceSession);
    yield takeLatest(ComplianceActions.deleteVendorComplianceSession().type, deleteVendorComplianceSession);
    yield takeLatest(ComplianceActions.startComplianceSessionInProgressPoller().type, startComplianceSessionInProgressPoller);
}