import React from 'react';

const MenuQuestions = () => {
return ([
    {
        question: "How do I update my menu",
        answer: <p>Please send any menu updates to menu@scoffable.com - you will receive a confirmation email when our menu team has received the updates. Full menu updates are usually completed within 7 days.</p>
    },
    {
        question: "How do I disable an item for tonight",
        answer: <p>Please contact our Customer Support Team on 0131 208 2230 and let a member of staff know the item(s) you require disabled, and we will get this processed immediately. We ask that you give us another call to confirm when the items are available again.</p>
    },
    {
        question: "How do I see what items are currently disabled on my menu",
        answer: <p>Please contact our Customer Support Team on 0131 208 2230 where a member of staff will go through any items currently disabled on the menu - and enable any items that are available again. Alternatively you can email menu@scoffable.com with your restaurant name and request that we email a list of currently disabled menu items.</p>
    },
    {
        question: "How long does it take for a menu update",
        answer: <p>We ask for 7 days to complete any full menu updates, however we do aim to complete these as quickly as possible.</p>
    }
])
}

export default MenuQuestions;
