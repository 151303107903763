import React, { Component } from "react";
import InitialInstructions from "./steps/InitialInstructions";
import ReturnFromOnboarding from "./steps/Return";
import Complete from "./steps/Complete";
import {Jumbotron, Container, Alert} from "reactstrap";
import * as Steps from "./steps/StepValues";
import StepProgressBar from "./steps/StepProgressBar";
import LoadingSpinner from "../loading/LoadingSpinner";
import {Redirect} from "react-router-dom";

class FixComplianceIssue extends Component {

    componentDidMount() {
        console.log("Fetching vendor compliance details")
        this.props.actions.fetchVendorComplianceSession({
            vendorId: this.props.match.params.vendorId,
            ownershipPeriodId: this.props.match.params.ownershipPeriodId
        } );
    }

    render() {
        const {
            complianceSession,
            errorMessage,
            match,
            vendors,
            step,
            complianceSessionDeleteInProgress,
            redirectToComplianceInstructions
        } = this.props;
        const vendor = vendors.find(vendor => "" + vendor.vendorID === match.params.vendorId);

        // If the vendor is already compliant, they shouldn't be starting the process
        if (step === Steps.INITIAL_INSTRUCTIONS &&
            vendor.vendorComplianceStatus === "COMPLIANT") return (<Redirect to={"/compliance"}/>)

        if (step !== Steps.INITIAL_INSTRUCTIONS && redirectToComplianceInstructions) {

            this.props.actions.fetchVendorComplianceSession({
                vendorId: this.props.match.params.vendorId,
                ownershipPeriodId: this.props.match.params.ownershipPeriodId
            } );
            return <Redirect to={"/compliance/" + match.params.vendorId + "/" + match.params.ownershipPeriodId} />
        }

        if (errorMessage) {
            return (
                <Container className={"px-4"}>
                    <Alert id={"compliance-error"}
                           className={"col-sm-4 mx-auto my-2"}
                           color={"danger"}><p>{errorMessage}</p>
                        <p>Please contact our Customer Support Team on 0131 208 2230 if you continue to experience
                            problems.</p>
                    </Alert>
                </Container>
            )
        }

        // Wait for the compliance session to be obtained
        if (!complianceSession) {
            return (<LoadingSpinner/>)
        }

        // If there are checks in progress, we've done the onboarding part, and we just need to wait on these
        // checks finishing
        if(step !== Steps.RETURN_FROM_ONBOARDING &&
            complianceSession.status === "COMPLIANCE_CHECKS_IN_PROGRESS"){
            return (<Redirect to={"/compliance/" + match.params.vendorId + "/" + match.params.ownershipPeriodId + "/return"}/>)
        }

        // If there are fatal issues with the data entered, we force the user to remove the Stripe account and start again
        // This only applies for new accounts being setup, and the population logic for this happens in the webhook
        if(step !== Steps.RETURN_FROM_ONBOARDING &&
            complianceSession.status === "NOT_COMPLIANT" && complianceSession.complianceIssues && complianceSession.complianceIssues.length > 0) {
            return (<Redirect to={"/compliance/" + match.params.vendorId + "/" + match.params.ownershipPeriodId + "/return"}/>)
        }

        if(step === Steps.RETURN_FROM_ONBOARDING &&
            complianceSession.status === "COMPLIANT"){
            return (<Redirect to={"/compliance/" + match.params.vendorId + "/" + match.params.ownershipPeriodId + "/complete"}/>)
        }

        return (
            <Container className={"px-4"}>
            <Jumbotron fluid style={{ backgroundColor: "white", borderRadius: "5px", paddingTop: "2px", paddingBottom: "2px" }}>
                <div className="wizard-form">
                        <Container fluid className={"pt-2"}>
                            <StepProgressBar activeStep={step} className={"pb-2 mb-4"} />
                            {step === Steps.COMPLETE && <Complete complianceSession={complianceSession} vendor={vendor} />}
                            {step === Steps.RETURN_FROM_ONBOARDING && <ReturnFromOnboarding actions={this.props.actions}
                                                                                            complianceSession={complianceSession} vendor={vendor}
                                                                                            complianceSessionDeleteInProgress={complianceSessionDeleteInProgress}
                                                                                            compliancePollRequests={this.props.compliancePollRequests}
                                                                                            compliancePollingInProgress={this.props.compliancePollingInProgress} />}
                            {step === Steps.INITIAL_INSTRUCTIONS && <InitialInstructions complianceSession={complianceSession} vendor={vendor} />}
                        </Container>
                    </div>
                </Jumbotron>
            </Container>
        );
    };
}

export default FixComplianceIssue