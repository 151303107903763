import '../../css/OrderDetails.css';
import '../../css/VendorDashboard.css';
import 'react-dates/lib/css/_datepicker.css';

import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Container, Row } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchOrderDetails } from "../../actions/OrderActions";

import OrderDetailsCustomerDetailsCard from './OrderDetailsCustomerDetailsCard';
import OrderDetailsItemsCard from './OrderDetailsItemsCard';
import OrderDetailsSummaryCard from './OrderDetailsSummaryCard';

class OrderDetails extends Component {

  componentDidMount() {
    this.getOrderDetailsFromAPI();
  }

  getOrderDetailsFromAPI(){
    const {match, actions} = this.props;
    const orderID = match.params.orderID;
    actions.fetchOrderDetails({orderID});
  }
  
  render() {
    const { orderNotFound, orderData, user } = this.props;

    let pageContent;

    if(orderNotFound){
      pageContent = (
        <Container fluid className="page-content">
          <Row className="summary-title-row">
            <div className="col-sm-12">
            <h1 className="d-none d-md-block" style={{padding: "0px", color: "grey"}}>Order not Found</h1>
            <h3 className="d-md-none" style={{padding: "0px", color: "grey", marginTop: "0px"}}>Order not Found</h3>
            </div>
          </Row>
        </Container>
      );
    }
    else if(orderData !=null){
      pageContent = (
        <Container fluid className="page-content">
          <Row className="summary-title-row">
            <div className="col-sm-12">
            <h1 className="d-none d-md-block" style={{padding: "0px", color: "grey"}}>SCOFF-{orderData.id}</h1>
            <h3 className="d-md-none" style={{padding: "0px", color: "grey", marginTop: "0px"}}>SCOFF-{orderData.id}</h3>
            </div>
          </Row>
          <Row>
            <div className="col-lg-6">
                <OrderDetailsSummaryCard orderData={orderData} />
                <OrderDetailsCustomerDetailsCard orderData={orderData} user={user} />
            </div>
            <div className="col-lg-6">
              <OrderDetailsItemsCard orderData={orderData} />
            </div>
          </Row>
        </Container>
      );
    }
    else{
      pageContent = (
        <Container fluid className="page-content">
          <Row className="summary-title-row">
            <div className="col-sm-12">
              <h1>Retrieving Order Data...</h1> 
            </div>
          </Row>
        </Container>
      );
    }

    return (
        <IntlProvider locale={navigator.language}>
          {pageContent}
        </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderData: state.orders.orderData,
    orderNotFound: state.orders.orderNotFound,
    user: state.user.user
  }
}

const mapDispatchToActions = (dispatch) => {
  return {
    actions: bindActionCreators({fetchOrderDetails}, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToActions)(OrderDetails);