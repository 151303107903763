import { all } from 'redux-saga/effects';
import userSaga from "./user-sagas/UserSaga";
import vendorSaga from "./vendor-sagas/VendorSaga";
import orderSaga from "./order-sagas/OrderSaga";
import invoicesSaga from "./invoices-sagas/InvoicesSaga";
import bankDetailsSaga from "./bank-details-sagas/BankDetailsSaga";
import vendorOwnerSaga from "./vendor-owner-sagas/VendorOwnerSaga";
import OrderReviewSaga from "./order-reviews-sagas/OrderReviewsSaga";
import ComplianceSaga from "./compliance-sagas/ComplianceSaga";
import PayoutsSaga from "./payouts-sagas/PayoutsSaga";

export default function* rootSaga() {
    yield all([
        userSaga(),
        vendorSaga(),
        orderSaga(),
        invoicesSaga(),
        bankDetailsSaga(),
        vendorOwnerSaga(),
        OrderReviewSaga(),
        ComplianceSaga(),
        PayoutsSaga()
    ])
}