import '../css/SideMenu.css';

import React, { Component } from 'react';
import {TiDocumentText} from 'react-icons/ti';
import {GiNetworkBars} from 'react-icons/gi';
import {MdAccountBalance} from "react-icons/md";
import {FaUser, FaClipboardCheck} from "react-icons/fa";
import ReviewsIcon from "../images/icons/reviews/reviews.svg"
import PaymentsIcon from "../images/icons/payments/payments.svg"
import { Link } from 'react-router-dom';
import withRouter from 'react-router-dom/withRouter';
import { Nav, NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import FaqIcon from '../images/icons/faq/faqs.svg';
import {userHasPermission} from "../utils/helpers/User";
import {
    VIEW_FAQS,
    VIEW_INVOICES,
    VIEW_ORDERS,
    VIEW_PAYOUTS,
} from "../utils/helpers/Permission";
import {showCompliance, showBankDetails, showContactDetails, showReviews} from "../utils/helpers/Navhelper";
class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        const { user } = this.props

        return (
            <Nav vertical className="vertical-dash-nav col-md-1-2">
                <div className="menu-wrapper">

                    { this.showOrderInfoSideHeader() &&
                    <NavItem data-cy="nav-order-info-header" className="side-menu-section-header" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <span className="side-menu-section-header-text">Order Info</span>
                        </div>
                    </NavItem>}

                    { userHasPermission(user, VIEW_ORDERS) &&
                        <NavItem data-cy="nav-orders" className={this.getSideMenuItemClass("/orders")}>
                        <Link to="/orders">
                            <span className="side-menu-link-icon">
                                <GiNetworkBars size="25" />
                            </span>

                            <span className="side-menu-link-text" style={{paddingTop: "3px"}}>Orders</span>
                        </Link>
                    </NavItem>}

                    { showReviews(this.props) &&
                            <NavItem data-cy="nav-order-reviews" className={this.getSideMenuItemClass("/order-reviews")}>
                        <Link to="/order-reviews">
                            <span className="side-menu-link-icon">
                            <img src={ReviewsIcon} style={{ height: 25, width: 25 }} alt="Reviews Icon" />
                            </span>

                            <span className="side-menu-link-text">Reviews</span>
                        </Link>
                    </NavItem>}

                    { userHasPermission(user, VIEW_INVOICES) &&

                        <NavItem data-cy="nav-invoices" className={this.getSideMenuItemClass("/invoice")}>
                        <Link to="/invoices">
                            <span className="side-menu-link-icon">
                                <TiDocumentText size="25" />
                            </span>
                            <span className="side-menu-link-text">
                                Invoices
                            </span>
                        </Link>
                    </NavItem>}

                    { userHasPermission(user, VIEW_PAYOUTS) && <NavItem data-cy="nav-payouts"
                        className={this.getSideMenuItemClass("/payouts")}>
                        <Link to="/payouts">
                            <span className="side-menu-link-icon">
                            <img src={PaymentsIcon} style={{height: 25, width: 25, color: '#e7131a'}}
                                 alt="Payouts Icon"/>
                            </span>
                            <span className="side-menu-link-text">
                                Payouts
                    </span>
                        </Link>
                    </NavItem>}


                    {this.showManagementSideHeader() &&
                        <NavItem data-cy="nav-management-header" className="side-menu-section-header" style={{ paddingLeft: "10px", paddingRight: "10px" }}>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <span className="side-menu-section-header-text">Management</span>
                        </div>
                    </NavItem>}

                    { showBankDetails(this.props) && <NavItem data-cy="nav-bank-details" className={this.getSideMenuItemClass("/bank-details")}>
                        <Link to="/bank-details">
                            <span className="side-menu-link-icon">
                                <MdAccountBalance size="25" />
                            </span>
                            <span className="side-menu-link-text">
                                Bank Details
                    </span>
                        </Link>
                    </NavItem>}

                    { showCompliance(this.props) && <NavItem data-cy="nav-compliance" className={this.getSideMenuItemClass("/compliance")}>
                        <Link to="/compliance">
                            <span className="side-menu-link-icon">
                                <FaClipboardCheck size="25" />
                            </span>
                            <span className="side-menu-link-text">
                                Compliance
                    </span>
                        </Link>
                    </NavItem>}

                    { showContactDetails(this.props) && <NavItem data-cy="nav-contact-details" className={this.getSideMenuItemClass("/user-management")}>
                        <Link to="/user-management">
                            <span className="side-menu-link-icon">
                                <FaUser size="25" />
                            </span>
                            <span className="side-menu-link-text">
                                Contact Details
                    </span>
                        </Link>
                    </NavItem>}
                    { userHasPermission(user, VIEW_FAQS) && <NavItem data-cy="nav-faqs" className={this.getSideMenuItemClass("/faqs")}>
                        <Link to="/faqs">
                            <span className="side-menu-link-icon">
                            <img src={FaqIcon} style={{ height: 25, width: 25, color: '#e7131a' }} alt="Faq Icon" />
                            </span>
                            <span className="side-menu-link-text">
                                FAQs
                    </span>
                        </Link>
                    </NavItem>}
                </div>
            </Nav>
        );
    }

    /**
     * Only show the 'Order Info' side header if there are visible nav items within
     */
    showOrderInfoSideHeader() {
        const { user } = this.props;

        return userHasPermission(user, VIEW_ORDERS) ||
            showReviews(this.props) ||
            userHasPermission(user, VIEW_INVOICES) ||
            userHasPermission(user, VIEW_PAYOUTS);
    }

    /**
     * Only show the 'Management' side header if there are visible nav items within
     */
    showManagementSideHeader() {
        const { user } = this.props;

        return showBankDetails(this.props) ||
            showCompliance(this.props) ||
            showContactDetails(this.props) ||
            userHasPermission(user, VIEW_FAQS)
    }

    /**
     * Decides if the current item should have the "selected" CSS class or not, based on the URL path
     * @param path
     * @returns {string}
     */
    getSideMenuItemClass(path){
        return "side-menu-item " + (this.props.location.pathname.includes(path) ? "menu-item-selected" : "")
    }


}

const mapStateToProps = (store) => {
    return {
        user: store.user.user,
        vendors: store.vendor.vendors,
        orderReviewsDisabled: store.reviews.orderReviewsDisabled,
        complianceEnabled: store.vendor.complianceEnabled
    }
};

const connectedComponent = connect(mapStateToProps)(SideMenu)

export default withRouter(connectedComponent);