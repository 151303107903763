import React, { Component } from 'react';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import { DateRangePicker } from 'react-dates';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { CUSTOM } from '../../containers/Orders/FilterNames';

const sizeMeHOC = sizeMe();

class DatePicker extends Component {

    constructor(props){
        super(props);
        this.state = {
            customDateStart: moment(this.props.ordersListStartDate).startOf("day"),
            customDateEnd: moment(this.props.ordersListEndDate).endOf("day"),
            focusedInput: null
        }
    }

  render() {
      const { message, toggleOpen, open, toggle, handleChange, size } = this.props;
      const { customDateStart, customDateEnd, focusedInput } = this.state;
      var datePickerOrientation = size.width < 767.98 ? "vertical" : "horizontal";

    return (
      <Modal className="custom-date-range-selector-modal" isOpen={open} toggle={toggleOpen}>
          <ModalHeader toggle={toggleOpen}>Custom Date Range</ModalHeader>
          <ModalBody>
            <p>{message}</p>
            <div className="date-range-picker-wrapper">
              <DateRangePicker
                startDate={customDateStart} // momentPropTypes.momentObj or null,
                endDate={customDateEnd} // momentPropTypes.momentObj or null,
                startDateId="startDate"
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  if(startDate !== null && endDate !== null){
                    this.setState({ customDateStart: moment(startDate).startOf("day"), customDateEnd: moment(endDate).endOf("day") })
                  }
                }
                } // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                displayFormat="DD/MM/YY"
                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                isOutsideRange={() => { return false }}
                hideKeyboardShortcutsPanel={true}
                minimumNights={0}
                orientation={datePickerOrientation}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggle}>Cancel</Button>{' '}
            <Button color="success" onClick={() => {
                handleChange(CUSTOM, moment(this.state.customDateStart), moment(this.state.customDateEnd));
                toggle();
                }}>OK</Button>
          </ModalFooter>
        </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
    ordersListStartDate: state.orders.ordersListStartDate,
    ordersListEndDate: state.orders.ordersListEndDate
})

const connectedComponent = connect(mapStateToProps)(DatePicker);

export default sizeMeHOC(connectedComponent);