import React from "react";
import { Label, Input, FormGroup, Col, Row, Alert } from "reactstrap";

let hasHadError = false;

const defaultExport = ({ input, label, type, meta: { touched, error }, ...props }) => {
    let valid;
    let displayNewDetailsType;
    if (props.newDetailsType === "TELEPHONE_NUMBER") {
        displayNewDetailsType = "contact number";
    } else if (props.newDetailsType === "EMAIL_ADDRESS") {
        displayNewDetailsType = "email address";
    }
    if (!touched) {
        valid = true
    } else if (error) {
        hasHadError = true;
        valid = false;
    } else {
        valid = true;
    }

    return (<FormGroup style={{ marginBottom: "0" }}>
        <Row>
            <Col xs="12" sm="6" style={{ textAlign: "center" }}>
                <Label for={"otpCodeInput" + props.id} style={{ color: "#818182" }}>Please enter the code we sent to your <b>{`${displayNewDetailsType}`}</b>.</Label>
            </Col>
            <Col xs="12" sm="6">
                <Input innerRef={(input) => props.otpInput = input} type="text" name={"otpCode" + props.id} id={"otpCodeInput" + props.id} placeholder={`Verification code for new ${displayNewDetailsType}`}
                    invalid={!valid} valid={hasHadError && valid} maxLength={4} {...input} />
                <div className="mt-1 w-100"><button className="link-button black-link" onClick={() => props.resendOtpToNewDetails({ updateRequestGuid: props.otp.requestGuid, otpGuid: props.otp.guid })}>Resend Verification Code</button></div>
            </Col>
        </Row>
        <Row>
            <Col size={12}>
                {touched && (error && <Alert color="danger">* {error}</Alert>)}
                {props.fieldError && <Alert color="danger">* {props.fieldError}</Alert>}
            </Col>
        </Row>
    </FormGroup>);
}

export default defaultExport