export function fetchVendorComplianceSession(payload) {
    return {
        type: 'FETCH_VENDOR_COMPLIANCE_SESSION',
        payload: payload
    }
}

export function fetchVendorComplianceSessionSuccess(payload) {
    return {
        type: 'FETCH_VENDOR_COMPLIANCE_SESSION_SUCCESS',
        payload: payload
    }
}

export function fetchVendorComplianceSessionFailed(error) {
    return {
        type: 'FETCH_VENDOR_COMPLIANCE_SESSION_ERROR',
        payload: error
    }
}

export function deleteVendorComplianceSession(payload) {
    return {
        type: 'DELETE_VENDOR_COMPLIANCE_SESSION',
        payload: payload
    }
}

export function deleteVendorComplianceSessionSuccess(payload) {
    return {
        type: 'DELETE_VENDOR_COMPLIANCE_SESSION_SUCCESS',
        payload: payload
    }
}

export function deleteVendorComplianceSessionFailed(error) {
    return {
        type: 'DELETE_VENDOR_COMPLIANCE_SESSION_ERROR',
        payload: error
    }
}

export function startComplianceSessionInProgressPoller(payload) {
    return {
        type: 'START_COMPLIANCE_SESSION_IN_PROGRESS_POLLER',
        payload: payload
    }
}

export function cancelComplianceSessionInProgressPoller(payload) {
    return {
        type: 'CANCEL_COMPLIANCE_SESSION_IN_PROGRESS_POLLER',
        payload: payload
    }
}