import React from "react";
import "../../css/VendorLogoAddressView.css";
import SingleLineAddressView from "../address/SingleLineAddressView";
import { Grid } from 'semantic-ui-react';
import { connect } from "react-redux";

class VendorLogoAddressView extends React.Component {
    render() {
        const { vendors, vendorID } = this.props;
        const vendor = vendors.get(vendorID);
        return (
            <Grid.Row columns={2} className="row-nowrap center vendor-info-grid mt-2">
                <Grid.Column className="vendor-info-grid-content">
                    <img data-cy="vendor-image" src={vendor.logoURL} alt="vendor logo" className="rounded vendor_logo vendor-logo"  />
                    <div data-cy="vendor-name" className="vendor_name" >{vendor.name}</div>
                    <div data-cy="vendor-address" className="vendor_address"><SingleLineAddressView address={vendor.address} /></div>
                </Grid.Column>
            </Grid.Row>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        vendors: store.vendor.vendors
    }
}

export default connect(mapStateToProps)(VendorLogoAddressView);