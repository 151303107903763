export const VIEW_VENDOR = "view.vendor";
export const VIEW_ORDERS = "view.orders";
export const VIEW_ORDERS_CUSTOMER_INFO = "view.orders.customer-info";
export const VIEW_COMPLIANCE = "view.compliance";
export const CREATE_COMPLIANCE = "create.compliance";
export const VIEW_REVIEWS = "view.reviews";
export const CREATE_REVIEWS_REPLIES = "create.reviews.replies";
export const VIEW_INVOICES = "view.invoices";
export const VIEW_INVOICES_PDF = "view.invoices.pdf";
export const VIEW_BALANCES = "view.balances";
export const VIEW_PAYOUTS = "view.payouts";
export const VIEW_BANK_DETAILS = "view.bank-details";
export const CREATE_BANK_DETAILS = "create.bank-details";
export const VIEW_CONTACT_DETAILS = "view.contact-details";
export const CREATE_CONTACT_DETAILS = "create.contact-details";
export const VIEW_FAQS = "view.faqs";