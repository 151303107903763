import React, { Component } from 'react';
import OrdersCard from "./OrdersCard";

export default class OrdersCardView extends Component {
    

    renderOrderCards = (orders, user) => {
        return orders.map(order => <OrdersCard key={order.id} user={user} {...order} />)
    }

  render() {
    const { tableData, user } = this.props;

    return (
        <div>
          {this.renderOrderCards(tableData, user)}
          </div>
    )
  }
}
