import React, { Component } from 'react';
import cardIcon from "../../../../images/order_icons/card_icon.svg";
import cashIcon from "../../../../images/order_icons/cash_icon.svg";

export class PaymentMethod extends Component {

  render() {
    const { paymentMethod } = this.props;
    var paymentIcon = paymentMethod === "CARD" ? cardIcon : cashIcon;
    var paymentType = paymentMethod === "CARD" ? "Credit / Debit Card" : "Cash";
    
    return (
    <img src={paymentIcon} alt={paymentType} title={paymentType} style={{width: "30px", height: "20px"}} />
  );
  }
}

export default PaymentMethod;
