import React, { Component } from 'react';
const document = window.document;
const {SVG} = require('@svgdotjs/svg.js');

export default class VendorReviewSummary extends Component {

    componentDidMount(){
        this.drawGraph(this.props.reviewSummary, this.props.isMobile, this.props.width);
    }

    componentDidUpdate(prevProps) {
        if(this.props.isMobile !== prevProps.isMobile || this.props.width !== prevProps.width){
            this.drawGraph(this.props.reviewSummary, this.props.isMobile, this.props.width);
        }
    }

    drawGraph(reviewSummary, isMobile) {
        if(reviewSummary != null) {
            let noData = reviewSummary.reviewCount === 0

            let element = document.getElementById(`review-chart-${reviewSummary.vendorId}`);
            if(element === null){
                return;
            }
            element.innerHTML = "";
            let elementWidth = element.offsetWidth;
            let maxCanvasWidth = 345;
            let canvasWidth = elementWidth < maxCanvasWidth ? elementWidth : maxCanvasWidth;
            let canvasHeight = isMobile ? 200 : 257;

            // create canvas
            const canvas = SVG().addTo(`#review-chart-${reviewSummary.vendorId}`).size(canvasWidth, canvasHeight);

            let terribleCount = noData ? 5 : reviewSummary.terribleCount;
            let badCount = noData ? 10 : reviewSummary.badCount;
            let goodCount = noData ? 15 : reviewSummary.goodCount;
            let greatCount = noData ? 20 : reviewSummary.greatCount;

            let terriblePercentageText = reviewSummary.percentageScores != null ? reviewSummary.percentageScores.percentageTerrible : null;
            let badPercentageText = reviewSummary.percentageScores != null ? reviewSummary.percentageScores.percentageBad : null;
            let goodPercentageText = reviewSummary.percentageScores != null ? reviewSummary.percentageScores.percentageGood : null;
            let greatPercentageText = reviewSummary.percentageScores != null ? reviewSummary.percentageScores.percentageGreat : null;

            // X axis line
            // let line = canvas.line(0, 0, isMobile ? canvasWidthMobile : canvasWidthDesktop, 0).move(0, isMobile ? canvasHeightMobile : canvasHeightDesktop);
            // line.stroke({color: '#ff0000', width: 2});

            // The number of reviews in the largest bar
            let maxReviews = Math.max(terribleCount, badCount, goodCount, greatCount);

            // Dark red bar
            this.drawBar(canvas, noData ? '#EEEEEE' : '#E7131A', terribleCount, maxReviews, 3, isMobile, canvasWidth, canvasHeight, terriblePercentageText, noData);

            // Orange bar
            this.drawBar(canvas, noData ? '#EEEEEE' : '#F5A001', badCount, maxReviews, 2, isMobile, canvasWidth, canvasHeight, badPercentageText, noData);

            // Light green bar
            this.drawBar(canvas, noData ? '#EEEEEE' : '#70C750', goodCount, maxReviews, 1, isMobile, canvasWidth, canvasHeight, goodPercentageText, noData);

            // Dark green bar
            this.drawBar(canvas, noData ? '#EEEEEE' : '#12E288', greatCount, maxReviews, 0, isMobile, canvasWidth, canvasHeight, greatPercentageText, noData);

            this.drawFaces(canvas, canvasWidth, canvasHeight, noData);

        }

    }

    drawFaces(canvas, canvasWidth, canvasHeight, noDataMode){
        let facesYPosition = canvasHeight - 45;
        let paddedCanvasWidth = this.getPaddedCanvasWidth(canvasWidth);
        let facesSVG = `<?xml version="1.0" encoding="UTF-8"?>'<?xml version="1.0" encoding="UTF-8"?>
                <svg width="${canvasWidth}px" height="${canvasHeight}px" viewBox="0 0 ${canvasWidth} ${canvasHeight}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                  <g id="Custom-Preset-5" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g id="image" transform="translate(0.000000, ${facesYPosition})">
                      <g id="emoji_Terrible" transform="translate(${this.getFaceIconStartX(paddedCanvasWidth, 3)}, 0.000000)">
                        <path d="M11.8568537,0.0274322174 L11.9723586,0.184994228 C12.8258355,1.28904476 14.1632248,2 15.6666667,2 C16.0108748,2 16.346379,1.96273413 16.6693648,1.89201691 C16.667255,1.92703596 16.6666667,1.96344928 16.6666667,2 C16.6666667,3.84094917 18.1590508,5.33333333 20,5.33333333 C20.4119685,5.33333333 20.8064817,5.25859816 21.1707214,5.12194591 C21.2016234,5.19632607 21.2305128,5.27230388 21.2576381,5.34897221 C22.281076,8.21398212 22.0484764,11.9146199 21.7228371,14.8592134 C21.5367575,17.4058889 19.6294416,19.5546464 17.2104068,20.8677759 C16.4195684,21.3054858 15.3030908,21.5044448 14.4192127,21.7034038 C12.6514565,22.0217382 11.8606182,22.0217382 10.1393818,21.9819464 C8.3251057,21.9421546 6.18519026,21.7431956 4.60351363,20.9075677 C2.13795889,19.5148546 0.602802163,17.4456807 0.277162857,14.8592134 C-0.0484764489,11.9146199 -0.281075953,8.21398212 0.742361865,5.34897221 C1.02148127,4.47355252 1.62623998,3.59813282 2.27751859,2.88188034 C4.72908961,0.387168282 8.29442208,-0.136481757 11.8568537,0.0274322174 Z" id="Combined-Shape" fill="${noDataMode ? '#EEEEEE' : '#E7131A'}" fill-rule="nonzero"></path>
                       <path d="M5.33333333,10.6666667 C5.88888889,10.4444444 6.44444444,10.3333333 7,10.3333333 C7.55555556,10.3333333 8.11111111,10.4444444 8.66666667,10.6666667" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round" transform="translate(7.000000, 10.500000) scale(1, -1) translate(-7.000000, -10.500000) "></path>
                       <path d="M13.3333333,10.6666667 C13.8888889,10.3333333 14.4444444,10.1666667 15,10.1666667 C15.5555556,10.1666667 16.1111111,10.3333333 16.6666667,10.6666667" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round" transform="translate(15.000000, 10.416667) scale(1, -1) translate(-15.000000, -10.416667) "></path>
                       <path d="M8,16.1486175 C8.76923077,16.6478298 9.76923077,16.8974359 11,16.8974359 C12.2307692,16.8974359 13.2307692,16.6478298 14,16.1486175" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round" transform="translate(11.000000, 16.523027) scale(1, -1) translate(-11.000000, -16.523027) "></path>
                      </g>
                      <g id="emoji_Great" transform="translate(${this.getFaceIconStartX(paddedCanvasWidth, 0)}, 0.000000)">
                       <path d="M11.8568537,0.0274322174 L11.9723586,0.184994228 C12.8258355,1.28904476 14.1632248,2 15.6666667,2 C16.0108748,2 16.346379,1.96273413 16.6693648,1.89201691 C16.667255,1.92703596 16.6666667,1.96344928 16.6666667,2 C16.6666667,3.84094917 18.1590508,5.33333333 20,5.33333333 C20.4119685,5.33333333 20.8064817,5.25859816 21.1707214,5.12194591 C21.2016234,5.19632607 21.2305128,5.27230388 21.2576381,5.34897221 C22.281076,8.21398212 22.0484764,11.9146199 21.7228371,14.8592134 C21.5367575,17.4058889 19.6294416,19.5546464 17.2104068,20.8677759 C16.4195684,21.3054858 15.3030908,21.5044448 14.4192127,21.7034038 C12.6514565,22.0217382 11.8606182,22.0217382 10.1393818,21.9819464 C8.3251057,21.9421546 6.18519026,21.7431956 4.60351363,20.9075677 C2.13795889,19.5148546 0.602802163,17.4456807 0.277162857,14.8592134 C-0.0484764489,11.9146199 -0.281075953,8.21398212 0.742361865,5.34897221 C1.02148127,4.47355252 1.62623998,3.59813282 2.27751859,2.88188034 C4.72908961,0.387168282 8.29442208,-0.136481757 11.8568537,0.0274322174 Z" id="Combined-Shape" fill="${noDataMode ? '#EEEEEE' : '#12E288'}" fill-rule="nonzero"></path>
                        <path d="M5.33333333,10 C5.88888889,9.55555556 6.44444444,9.33333333 7,9.33333333 C7.55555556,9.33333333 8.11111111,9.55555556 8.66666667,10" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round"></path>
                        <path d="M13.3333333,10 C13.8888889,9.55555556 14.4444444,9.33333333 15,9.33333333 C15.5555556,9.33333333 16.1111111,9.55555556 16.6666667,10" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round"></path>
                        <path d="M6.66666667,17.3333333 C7.77777778,16 9.22222222,15.3333333 11,15.3333333 C12.7777778,15.3333333 14.2222222,16 15.3333333,17.3333333" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round" transform="translate(11.000000, 16.333333) scale(1, -1) translate(-11.000000, -16.333333) "></path>
                      </g>
                      <g id="emoji_Good" transform="translate(${this.getFaceIconStartX(paddedCanvasWidth, 1)}, 0.000000)">
                        <path d="M11.8568537,0.0274322174 L11.9723586,0.184994228 C12.8258355,1.28904476 14.1632248,2 15.6666667,2 C16.0108748,2 16.346379,1.96273413 16.6693648,1.89201691 C16.667255,1.92703596 16.6666667,1.96344928 16.6666667,2 C16.6666667,3.84094917 18.1590508,5.33333333 20,5.33333333 C20.4119685,5.33333333 20.8064817,5.25859816 21.1707214,5.12194591 C21.2016234,5.19632607 21.2305128,5.27230388 21.2576381,5.34897221 C22.281076,8.21398212 22.0484764,11.9146199 21.7228371,14.8592134 C21.5367575,17.4058889 19.6294416,19.5546464 17.2104068,20.8677759 C16.4195684,21.3054858 15.3030908,21.5044448 14.4192127,21.7034038 C12.6514565,22.0217382 11.8606182,22.0217382 10.1393818,21.9819464 C8.3251057,21.9421546 6.18519026,21.7431956 4.60351363,20.9075677 C2.13795889,19.5148546 0.602802163,17.4456807 0.277162857,14.8592134 C-0.0484764489,11.9146199 -0.281075953,8.21398212 0.742361865,5.34897221 C1.02148127,4.47355252 1.62623998,3.59813282 2.27751859,2.88188034 C4.72908961,0.387168282 8.29442208,-0.136481757 11.8568537,0.0274322174 Z" id="Combined-Shape" fill="${noDataMode ? '#EEEEEE' : '#70C750'}" fill-rule="nonzero"></path>
                        <path d="M5.33333333,10 C5.88888889,9.77777778 6.44444444,9.66666667 7,9.66666667 C7.55555556,9.66666667 8.11111111,9.77777778 8.66666667,10" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round"></path>
                        <path d="M13.3333333,10 C13.8888889,9.66666667 14.4444444,9.5 15,9.5 C15.5555556,9.5 16.1111111,9.66666667 16.6666667,10" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round"></path>
                        <path d="M7.33333333,17.1691904 C8.27350427,16.5435763 9.4957265,16.2307692 11,16.2307692 C12.5042735,16.2307692 13.7264957,16.5435763 14.6666667,17.1691904" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round" transform="translate(11.000000, 16.699980) scale(1, -1) translate(-11.000000, -16.699980) "></path>
                      </g>
                      <g id="emoji_Bad" transform="translate(${this.getFaceIconStartX(paddedCanvasWidth, 2)})">
                        <path d="M11.8568537,0.0274322174 L11.9723586,0.184994228 C12.8258355,1.28904476 14.1632248,2 15.6666667,2 C16.0108748,2 16.346379,1.96273413 16.6693648,1.89201691 C16.667255,1.92703596 16.6666667,1.96344928 16.6666667,2 C16.6666667,3.84094917 18.1590508,5.33333333 20,5.33333333 C20.4119685,5.33333333 20.8064817,5.25859816 21.1707214,5.12194591 C21.2016234,5.19632607 21.2305128,5.27230388 21.2576381,5.34897221 C22.281076,8.21398212 22.0484764,11.9146199 21.7228371,14.8592134 C21.5367575,17.4058889 19.6294416,19.5546464 17.2104068,20.8677759 C16.4195684,21.3054858 15.3030908,21.5044448 14.4192127,21.7034038 C12.6514565,22.0217382 11.8606182,22.0217382 10.1393818,21.9819464 C8.3251057,21.9421546 6.18519026,21.7431956 4.60351363,20.9075677 C2.13795889,19.5148546 0.602802163,17.4456807 0.277162857,14.8592134 C-0.0484764489,11.9146199 -0.281075953,8.21398212 0.742361865,5.34897221 C1.02148127,4.47355252 1.62623998,3.59813282 2.27751859,2.88188034 C4.72908961,0.387168282 8.29442208,-0.136481757 11.8568537,0.0274322174 Z" id="Combined-Shape" fill="${noDataMode ? '#EEEEEE' : '#F5A001'}" fill-rule="nonzero"></path>
                        <path d="M8,16.6875415 C8.76923077,16.3930417 9.76923077,16.2457918 11,16.2457918 C12.2307692,16.2457918 13.2307692,16.3930417 14,16.6875415" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round"></path>
                        <path d="M5.33333333,10 C5.88888889,9.77777778 6.44444444,9.66666667 7,9.66666667 C7.55555556,9.66666667 8.11111111,9.77777778 8.66666667,10" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round"></path>
                        <path d="M13.3333333,10 C13.8888889,9.66666667 14.4444444,9.5 15,9.5 C15.5555556,9.5 16.1111111,9.66666667 16.6666667,10" id="Path-3" stroke="#2A2A2A" stroke-width="1.66666667" stroke-linecap="round"></path>
                      </g>
                    </g>
                  </g>
                </svg>
`;

        canvas.svg(facesSVG);
    }

    drawBar(canvas, colour, reviewCount, maxReviews, positionInLine, isMobile, canvasWidth, canvasHeight, percentageText, noDataMode) {
        let paddedCanvasWidth = this.getPaddedCanvasWidth(canvasWidth);
        let barWidth = this.getBarWidth(paddedCanvasWidth);
        let maxBarHeight = canvasHeight - 100;
        let bottomTextPosition = isMobile ? 190 : 247
        let topPercentagePositionOffset = isMobile ? 17 : 15;

        if(reviewCount === null){
            reviewCount = 0;
        }
        
        // Work out the Y size of the bar
        let barY = (reviewCount / maxReviews) * maxBarHeight;

        // If it's zero, draw a tiny line
        if (barY === 0) {
            barY = 1
        }

        canvas.rect(barWidth, barY)
            .move( this.getBarStartX(paddedCanvasWidth, positionInLine), (45 + maxBarHeight) - barY)
            .fill(colour);

        if(!noDataMode) {
            canvas.plain(percentageText != null ? percentageText + '%' : "").font({
                family: 'HelveticaNeue',
                size: isMobile ? 15 : 17,
                weight: 'bold'
            }).fill(colour).center(this.getBarCentreX(paddedCanvasWidth, positionInLine), (topPercentagePositionOffset + maxBarHeight) - barY)
        }

        if(!noDataMode) {
            canvas.plain("(" + reviewCount + (reviewCount !== 1 ? " reviews" : " review") + ")").font({
                family: 'HelveticaNeue',
                size: 10,
                weight: 'normal'
            }).fill('#868686').center(this.getBarCentreX(paddedCanvasWidth, positionInLine), (34 + maxBarHeight) - barY)
        }

        canvas.plain(this.getLabelForPosition(positionInLine)).font({
            family: 'HelveticaNeue',
            size: 10,
            weight: 'normal'
        }).fill(noDataMode ? '#E8E8E8' : '#868686').center(this.getBarCentreX(paddedCanvasWidth, positionInLine), bottomTextPosition);

        if(noDataMode){
            canvas.plain("Review data will appear here").font({
                family: 'HelveticaNeue',
                size: isMobile ? 13 : 16,
                weight: 'normal'
            }).fill('#000').center(canvasWidth / 2, (canvasHeight / 2) - 10);

            canvas.plain(" when you receive 10+ reviews").font({
                family: 'HelveticaNeue',
                size: isMobile ? 13 : 16,
                weight: 'normal'
            }).fill('#000').center(canvasWidth / 2, (canvasHeight / 2) + 10);
        }
    }
    
    getBarWidth(canvasWidth){
        return canvasWidth / 5.2;
    }

    getBarMarginSize(){
        return 5;
    }
    
    getBarDistanceApart(canvasWidth){
        return (canvasWidth - (this.getBarWidth(canvasWidth) * 4))/3;
    }

    getPaddedCanvasWidth(canvasWidth){
        return canvasWidth - this.getBarMarginSize()*2;
    }

    getBarCentreX(canvasWidth, barPosition){
        let barWidth = this.getBarWidth(canvasWidth);
        let barDistanceApart = this.getBarDistanceApart(canvasWidth);

        return ((barWidth * barPosition+1) + (barDistanceApart * barPosition)) + (barWidth / 2) + this.getBarMarginSize();
    }

    getBarStartX(canvasWidth, barPosition){
        return this.getBarMarginSize() + (this.getBarWidth(canvasWidth) * barPosition ) + (this.getBarDistanceApart(canvasWidth) * barPosition);
    }

    getLabelForPosition(position){
        switch(position){
            case 0:
                return "Great";
            case 1:
                return "Good";
            case 2:
                return "Bad";
            case 3:
                return "Terrible";
            default:
                return "";
        }
    }
    
    getFaceIconStartX(canvasWidth, barPosition){
        return this.getBarCentreX(canvasWidth, barPosition) - (this.getBarMarginSize() * 2);
    }

    render() {
        const { reviewSummary } = this.props;

        if(reviewSummary != null) {
            return (
                <div>
                    <div id={`review-chart-${reviewSummary.vendorId}`} style={{textAlign: "center"}} className="review-chart"></div>
                </div>
            );
        }
        else{
            return null;
        }
    }
}