import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FaqIcon from '../../images/icons/faq/question.svg';
import { Row, Col, Container } from 'reactstrap';

export default class FaqToast extends Component {
    render() {
        return (
            <Link to="faqs" onClick={() => localStorage.setItem('scoffable.faq.popup.shown', 1)}>
        <Container>
          <Row>
            <Col xs="2" style={{ margin: "0", padding: "0", verticalAlign: "middle", display: "flex", alignItems: "center", flexWrap: "wrap" }} >
              <img src={FaqIcon} style={{ height: "30px", width: "30px", display: "block", margin: "auto" }} alt="FAQ icon" />
            </Col>
            <Col xs="10" style={{ margin: "0", padding: "0" }}>
              <p style={{ margin: "5px", color: "black", display: "inline-block" }}>
                Want to know more about reviews? Check out our FAQs section.
          </p>
            </Col>
          </Row>
        </Container>
      </Link>
        )
    }
}
