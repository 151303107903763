import React, { Component } from 'react';
import {Dropdown, Pagination, Responsive, Segment} from "semantic-ui-react";
import { Row } from 'reactstrap';
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import { bindActionCreators } from "redux";
import sizeMe from 'react-sizeme';
import { connect } from "react-redux";
import ReviewsCards from './ReviewsCards';
import {
  fetchOrderReviews, updateReviewsActivePage, updateReviewsPerPage, updateReviewSentiment,
  replyToOrderReview, editOrderReviewReply, deleteOrderReviewReply, fetchOrderReviewData,
  clearOrderReviewReplyError
} from '../../actions/OrderReviewActions';
import VendorReviewSummaries from './VendorReviewSummaries';
import { ToastContainer, toast } from 'react-toastify';
import FaqToast from './FaqToast';
import 'react-toastify/dist/ReactToastify.css';
import FirstPaginationIcon from '../../images/icons/pagination/first_page.svg';
import LastPaginationIcon from '../../images/icons/pagination/last_page.svg';
import NextPaginationIcon from '../../images/icons/pagination/next_page.svg';
import PreviousPaginationIcon from '../../images/icons/pagination/previous_page.svg';

const itemsPerPageDropdownOptions = [
  { key: 10, text: "10 per page", value: 10 },
  { key: 20, text: "20 per page", value: 25 },
  { key: 50, text: "50 per page", value: 50 },
  { key: 100, text: "100 per page", value: 100 }
]

const reviewSentimentDropdownOptions = [
  { key: "most recent", text: "Most Recent", value: "most recent" },
  { key: "positive", text: "Positive", value: "positive" },
  { key: "negative", text: "Negative", value: "negative" },
]

const sizeMeHOC = sizeMe();

const TOAST_SHOWN_COUNT_KEY = 'scoffable.faq.popup.shown';
class OrderReviews extends Component {

  constructor(props) {
    super(props);
    this.state = {
      'vendorBlacklist': null,
    };
  }

  componentDidMount() {
    const [...disabledVendors] = this.props.vendors.filter((vendor) => { return vendor.selected === false }).values();

    const vendorBlacklist = disabledVendors.map(vendor => vendor.vendorID);
    this.setState({
      'vendorBlacklist': vendorBlacklist,
      'faqToastShown': false
    });

    var opts = {
      'vendorIDBlacklist': vendorBlacklist,
      'itemsPerPage': this.props.reviewsPerPage,
      'currentPage': this.props.reviewsActivePage,
      'reviewSentiment': this.props.reviewSentiment,
    };
    this.props.actions.fetchOrderReviewData(opts);

    this.showFAQToast();
  }

  handleScroll(){
    const { location: { hash } } = this.props;

    setTimeout(() => {
      const element = document.getElementById(hash.replace("#", ""));
      const containerList = document.getElementsByClassName("invoices-container");
      if(containerList.length > 0){
        if(element !== null){
          element.parentElement.scrollIntoView({ behavior: 'smooth', block: 'end'});
        }
      }
    }, 100);
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.reviewsActivePage !== this.props.reviewsActivePage
        || prevProps.reviewsPerPage !== this.props.reviewsPerPage
        || prevProps.vendors !== this.props.vendors
        || prevProps.reviewSentiment !== this.props.reviewSentiment) {
      const [...disabledVendors] = this.props.vendors.filter((vendor) => { return vendor.selected === false }).values();

      const vendorBlacklist = disabledVendors.map(vendor => vendor.vendorID);
      this.setState({'vendorBlacklist' : vendorBlacklist});
      let opts = {
        'vendorIDBlacklist': vendorBlacklist,
        'itemsPerPage': this.props.reviewsPerPage,
        'currentPage': this.props.reviewsActivePage,
        'reviewSentiment': this.props.reviewSentiment
      };
      this.props.actions.fetchOrderReviewData(opts);

    }

    if (
        this.props.reviews !== prevProps.reviews
    ) {
      this.handleScroll();
    }
  }

  showFAQToast(){
    if (!this.state.faqToastShown && this.getToastShownCount() < 3) {
      setTimeout(() => {
          toast(
            <FaqToast/>, {
              hideProgressBar: true,
              onOpen: () => {
                localStorage.setItem(TOAST_SHOWN_COUNT_KEY, this.getToastShownCount() ? parseInt(this.getToastShownCount()) + 1 : 1);
              },
              autoClose: 8000,
            }
          );
          this.setState({'faqToastShown': true});
        }, 1000);
    }
  }

  getToastShownCount() {
    return localStorage.getItem(TOAST_SHOWN_COUNT_KEY);
  }

  render() {
    const { totalReviewsNumber, reviewsPerPage, reviewSentiment, reviews, fetchingOrderReviewsInProgress, reviewSummaries, orderReviewsSubmitting, vendors, orderReviewReplyErrors, reviewsActivePage, size, user } = this.props;
    const { replyToOrderReview, deleteOrderReviewReply, editOrderReviewReply, clearOrderReviewReplyError, updateReviewsPerPage, updateReviewsActivePage, updateReviewSentiment } = this.props.actions;
    const [...disabledVendors] = vendors.filter((vendor) => { return vendor.selected === false }).values();
    const isMobile = size.width <= 768;
    const vendorBlacklist = disabledVendors.map(vendor => vendor.vendorID);

    if (fetchingOrderReviewsInProgress) {
      return <LoadingSpinner size={80} />
    }

    const reviewsEmpty = reviews === null || reviews.size === 0;
    return (
      <div className="mh-100 minh-100 reviews-page-container" style={{ overflow: "auto" }}>
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        <Row style={{ margin: "0px" }}>
          <div className="col-sm-12" style={{ padding: "0px" }}>
            <div className="summary-title-row">
              <div className="col-sm-12">
                <div>
                  <h1 data-cy="page-title" className="d-none d-md-inline-block" style={{ padding: "0px", color: "grey" }}>Reviews</h1>
                  <h3 data-cy="page-title" className="d-md-none d-inline-block" style={{ padding: "0px", color: "grey", marginTop: "0px" }}>Reviews</h3>
                </div>
              </div>
            </div>
          </div>
          </Row>
        <VendorReviewSummaries reviewSummaries={reviewSummaries} vendorBlacklist={vendorBlacklist} />
        <div className="order-review-controls">
          <Dropdown compact downward selection options={itemsPerPageDropdownOptions} className="review-pagination-items-per-page-control"
                    onChange={(_event, data) => updateReviewsPerPage(data.value)} value={reviewsPerPage} />
          <Dropdown compact downward selection options={reviewSentimentDropdownOptions} className="review-pagination-items-per-page-control"
                    onChange={(_event, data) => updateReviewSentiment(data.value)} value={reviewSentiment} />
        </div>
        <Responsive as={Segment} fireOnMount={true} onUpdate={this.handleOnSizeUpdate} basic loading={fetchingOrderReviewsInProgress} className="order-review-container">
          <ReviewsCards reviews={reviews} fetchingOrderReviewsInProgress={fetchingOrderReviewsInProgress} replyToOrderReview={replyToOrderReview}
            orderReviewsSubmitting={orderReviewsSubmitting} vendors={vendors} deleteOrderReviewReply={deleteOrderReviewReply} editOrderReviewReply={editOrderReviewReply}
            orderReviewReplyErrors={orderReviewReplyErrors} clearOrderReviewReplyError={clearOrderReviewReplyError} user={user} showVendor={this.state != null && this.state.vendorBlacklist != null && (vendors.size - this.state.vendorBlacklist.length) > 1 } />

          {!reviewsEmpty && <Pagination activePage={reviewsActivePage + 1} totalPages={Math.ceil(totalReviewsNumber / reviewsPerPage) || 1} className="review-pagination-control"
            onPageChange={(_event, data) => {
              updateReviewsActivePage(data.activePage - 1);
            }}
            firstItem={isMobile ? false : {content:(<img src={FirstPaginationIcon} alt={"Navigate to first page"} />), 'aria-label': 'First Page'}}
            lastItem={isMobile ? false : {content: (<img src={LastPaginationIcon} alt={"Navigate to last page"} />), 'aria-label': 'Last Page'}}
            nextItem={isMobile ? false : {content: (<img src={NextPaginationIcon} alt={"Navigate to next page"} />), 'aria-label': 'Next Page'}}
            prevItem={isMobile ? false : {content: (<img src={PreviousPaginationIcon} alt={"Navigate to previous page"} />), 'aria-label': "Previous Page"}}
            />}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = (state, _ourProps) => {
  return {
    pageNumber: state.reviews.pageNumber,
    reviews: state.reviews.reviews.toList(),
    totalReviewsNumber: state.reviews.totalReviewsNumber,
    reviewsPerPage: state.reviews.reviewsPerPage,
    reviewSentiment: state.reviews.reviewSentiment,
    fetchingOrderReviewsInProgress: state.reviews.fetchingOrderReviewsInProgress,
    reviewsActivePage: state.reviews.reviewsActivePage,
    vendors: state.vendor.vendors,
    reviewSummaries: state.reviews.reviewSummaries,
    reviewSummariesFetched: state.reviews.reviewSummariesFetched,
    reviewSummariesError: state.reviews.reviewSummariesError,
    orderReviewsSubmitting: state.reviews.orderReviewsSubmitting,
    orderReviewReplyErrors: state.reviews.orderReviewReplyErrors,
    user: state.user.user
  }
}

const mapDispatchToState = (dispatch) => {
  return {
    actions: bindActionCreators({ fetchOrderReviews,
      updateReviewsActivePage,
      updateReviewsPerPage,
      updateReviewSentiment,
      fetchOrderReviewData,
      replyToOrderReview,
      editOrderReviewReply,
      deleteOrderReviewReply,
      clearOrderReviewReplyError }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToState)(sizeMeHOC(OrderReviews));