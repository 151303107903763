import {
    fetchVendors,
    fetchVendorsSuccess,
    fetchVendorsFailed,
    toggleVendorChecked,
    fetchVendorsReviewSummaries,
    fetchVendorsReviewSummariesSuccess,
    fetchVendorsReviewSummariesFailed,
    fetchVendorsCompliance, fetchVendorsComplianceSuccess, fetchVendorsComplianceFailed
} from "../../actions/VendorActions";
import { logoutSuccess } from "../../actions/UserActions";
import { Set, Map } from "immutable";
const initialState = {
    fetchVendorsInProgress: false,
    vendorsFetched: false,
    vendorsInitialFetchComplete: false,
    error: null,
    vendors: Map([]),
    complianceEnabled: false,
    vendorComplianceRequiredForBankDetailsChange: false,

    vendorsComplianceFetched: false,
    vendorsComplianceInitialFetchComplete: false,
    vendorsCompliance: null,

    reviewSummariesFetched: false,
    reviewSummariesInitialFetchComplete: false,
    reviewSummariesFetchInProgress: false,

};

export default function VendorsReducer(state = initialState, action){
    switch (action.type){
        case fetchVendors().type:
            return {...state, fetchVendorsInProgress: true, error: null, vendorsFetched: false}
        case fetchVendorsSuccess().type:
            return {...state,
                fetchVendorsInProgress: false,
                error: null,
                vendorsFetched: true,
                vendorsInitialFetchComplete: true,
                vendors: action.payload
            };
        case fetchVendorsFailed().type:
            return {...state,
                fetchVendorsInProgress: false,
                vendorsInitialFetchComplete: true,
                error: action.payload,
                vendorsFetched: false
            }
        case toggleVendorChecked().type:
            return {...state, vendors: action.payload}
        case fetchVendorsReviewSummaries().type:
            return {...state,
                reviewSummariesFetched: false,
                reviewSummariesFetchInProgress: true,
                reviewSummaries: Set(),
                reviewSummariesError: null}
        case fetchVendorsReviewSummariesSuccess().type:
            return {...state,
                reviewSummariesFetched: true,
                reviewSummariesInitialFetchComplete: true,
                reviewSummariesFetchInProgress: false,
                reviewSummaries: Set(action.payload),
                reviewSummariesError: null
            }
        case fetchVendorsReviewSummariesFailed().type:
            return {...state,
                reviewSummariesFetched: false,
                reviewSummariesInitialFetchComplete: true,
                reviewSummariesFetchInProgress: false,
                reviewSummaries: Set(),
                reviewSummariesError: action.payload.message
            }
        case fetchVendorsCompliance().type:
            return {...state,
                vendorsComplianceFetchInProgress: state.vendorComplianceFetched
            }
        case fetchVendorsComplianceSuccess().type:
            return {...state,
                vendorsComplianceFetched: true,
                vendorsComplianceInitialFetchComplete: true,
                vendorsComplianceFetchInProgress: false,
                vendorsCompliance: action.payload.vendorComplianceDetails,
                complianceEnabled: action.payload.complianceEnabled,
                complianceDeadline: action.payload.complianceDeadline,
                vendorComplianceRequiredForBankDetailsChange: action.payload.vendorComplianceRequiredForBankDetailsChange
            }
        case fetchVendorsComplianceFailed().type:
            return {...state,
                vendorsComplianceFetched: false,
                // This is non fatal, so we just mark the fact that a fetch was attempted
                vendorsComplianceInitialFetchComplete: true,
                vendorsComplianceFetchInProgress: false,
                vendorsCompliance: null,
                error: action.payload.message
            }
        case logoutSuccess().type:
            return {...initialState}
        default:
            return state;
    }
}