import React from "react";
import { Progress } from "reactstrap";
import * as Steps from "./StepValues";

const defaultExport = (props) => {
    const { activeStep, style } = props;
    switch (activeStep) {
        case Steps.ENTER_BANK_DETAILS:
            return (
                <div style={style}>
                    <div className="text-center"><b>1/4</b></div>
                        <Progress color="success" value="25" />
                </div>
            )
        case Steps.CONFIRM_BANK_DETAILS:
            return (
                <div style={style}>
                    <div className="text-center"><b>2/4</b></div>
                        <Progress color="success" value="50" />
                </div>
            )
        case Steps.SIGN_CONTRACT:
            return (
                <div style={style}>
                    <div className="text-center"><b>3/4</b></div>
                        <Progress color="success" value="75" />
                </div>
            )
        case Steps.COMPLETE:
            return (
                <div style={style}>
                    <div className="text-center"><b>4/4</b></div>
                    <Progress color="success" value="100" />
                </div>
            )
        default:
            return <div />
    }
}

export default defaultExport