import React, { Component } from 'react';
import collectionIcon from "../../../../images/order_icons/collection_icon.svg";
import deliveryIcon from "../../../../images/order_icons/delivery_icon.svg";

export class OrderType extends Component {

    render() {
        const { orderType } = this.props;
        let orderTypeIcon = orderType === "DELIVERY" ? deliveryIcon : collectionIcon;
        let orderTypeString = orderType === "DELIVERY" ? "Delivery" : "Collection";

        return <img src={orderTypeIcon} alt={orderType} title={orderTypeString} style={{height:"20px"}} />
    }
}

export default OrderType
