import "../../css/BankDetails.css"
import '../../css/VendorDashboard.css';
import "../../css/App.css";

import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchBankDetails, fetchEffectiveDate, submitBankDetails, cancelBankDetailsUpdate, submitOtpCode, fetchSigningUrl } from "../../actions/BankDetailsActions";
import LoadingSpinner from "../../components/loading/LoadingSpinner";
import BankDetailsList from "../../components/bank-details/BankDetailsList";
import EditBankDetails from "../../components/bank-details/forms/EditBankDetails";

class BankDetails extends Component {

  constructor() {
    super();
    this.state = {
      heading: "Bank Details"
    }
  }

  componentDidMount(){
    this.props.actions.fetchBankDetails();
  }

  updateHeading = (headingText) => {
    if (headingText !== this.state.heading) {
      this.setState({
        heading: headingText
      })
    }
  }

  editVendorBankDetails = (vendorID) => {
    if (vendorID) {
      this.props.history.push("/bank-details/edit/" + vendorID);
    }
  }

  render() {
    const {
      bankDetails,
      vendors,
      vendorsCompliance,
      vendorComplianceRequiredForBankDetailsChange,
      submittingCancelBankDetailsUpdate,
      fetchingBankDetails,
      fetchVendorsInProgress,
      user
    } = this.props;
    const { vendorID } = this.props.match.params;
    if (submittingCancelBankDetailsUpdate || fetchingBankDetails || fetchVendorsInProgress) {
      return (<div className="h-100">
        <Row style={{ margin: "0px" }}>
          <div className="col-sm-12" style={{ padding: "0px" }}>
            <Row className="summary-title-row">
              <div className="col-sm-12">
                <div style={{ paddingRight: "60px" }}>
                  <h1 className="d-none d-md-block" style={{ padding: "0px", color: "grey", marginLeft: "15px" }}>Bank Details</h1>
                  <h3 className="d-md-none" style={{ padding: "0px", color: "grey", marginLeft: "15px", marginTop: "0px" }}>Bank Details</h3>
                </div>
              </div>
            </Row>
          </div>
        </Row>
        <div className="h-100 justify-content-center align-items-center">
          <LoadingSpinner size={80} />
        </div>
        </div>
        )
    }
    return (
      <div>
        <Row style={{ margin: "0px" }}>
          <div className="col-sm-12" style={{ padding: "0 20px 0 20px" }}>
            <Row className="summary-title-row">
              <div className="col-sm-12">
                <div style={{ paddingRight: "0" }}>
                  <h1 className="d-none d-md-block" style={{ padding: "0px", color: "grey", marginLeft: "0" }}>Bank Details</h1>
                  <h3 className="d-md-none" style={{ padding: "0px", color: "grey", marginLeft: "0", marginTop: "0px" }}>Bank Details</h3>
                </div>
              </div>
            </Row>
          </div>
        </Row>
        <div className="bank-details-container">
          <div className="container">
            <Row className="d-block bank-details-item">
              {!vendorID && <BankDetailsList
                  user={user}
                  vendors={vendors}
                  vendorsCompliance={vendorsCompliance}
                  vendorComplianceRequiredForBankDetailsChange={vendorComplianceRequiredForBankDetailsChange}
                  bankDetails={bankDetails}
                  editVendorBankDetails={this.editVendorBankDetails}
                  updateHeading={this.updateHeading}/>}
              {vendorID && <EditBankDetails {...this.props} updateHeading={this.updateHeading} />}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {

  return {
    vendors: store.vendor.vendors,
    vendorsCompliance: store.vendor.vendorsCompliance,
    vendorComplianceRequiredForBankDetailsChange: store.vendor.vendorComplianceRequiredForBankDetailsChange,
    bankDetails: store.bankDetails.bankDetails,
    effectiveDateError: store.bankDetails.effectiveDateError,
    fetchingEffectiveDate: store.bankDetails.fetchingEffectiveDate,
    otpCodeRequested: store.bankDetails.otpCodeRequested,
    submittingCancelBankDetailsUpdate: store.bankDetails.submittingCancelBankDetailsUpdate,
    fetchingSigningUrl: store.bankDetails.fetchingSigningUrl,
    submittingBankDetails: store.bankDetails.submittingBankDetails,
    submittingOtpCode: store.bankDetails.submittingOtpCode,
    maxOTPMessagesSent: store.bankDetails.maxOTPMessagesSent,
    bankDetailsError: store.bankDetails.bankDetailsError,
    fetchSigningUrlError: store.bankDetails.fetchSigningUrlError,
    otpCodeError: store.bankDetails.otpCodeError,
    fetchingBankDetails: store.bankDetails.fetchingBankDetails,
    fetchVendorsInProgress: store.vendor.fetchVendorsInProgress,
    user: store.user.user
  }
}

const mapActionsToDispatch = (dispatch) => {
  return {
    actions: bindActionCreators({ fetchBankDetails, fetchEffectiveDate, submitBankDetails, cancelBankDetailsUpdate, submitOtpCode, fetchSigningUrl }, dispatch)
  }
}

export default connect(mapStateToProps, mapActionsToDispatch)(BankDetails);