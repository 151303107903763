import './css/App.css';
import 'react-dates/initialize';
import React, { Component } from 'react';
import Routes from './Routes';
import logger from 'redux-logger';
import createRavenMiddleware from "raven-for-redux";
import Raven from "raven-js";
import * as Sentry from '@sentry/browser';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import RootReducer from './reducers/RootReducer';
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import zh from 'react-intl/locale-data/zh';
import rootSaga from './sagas/RootSaga';
import Provider from './Provider';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/js/bootstrap.bundle';

addLocaleData([...en, ...zh]);

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    Sentry.captureException(error);
  }
});

const store = createStore(RootReducer, applyMiddleware(sagaMiddleware, createRavenMiddleware(Raven), logger));
sagaMiddleware.run(rootSaga);



class App extends Component {

  render() {

    return (
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    );
  }
}

export default App;
