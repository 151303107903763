import { Label, Input, FormGroup, Col } from "reactstrap";
import React, { Component } from 'react'

let hasHadError = false;
export default class OtpCode extends Component {

    componentDidMount = () => {
        this.otpInput.focus();
    }


  render() {
    const { input } = this.props;
    const { touched, error } = this.props.meta;
    let valid;
    if(!touched){
        valid = true
    } else if(error){
        hasHadError = true;
        valid = false;
    } else {
        valid = true;
    }
    return (
        <FormGroup>
        <Label for="otpCodeInput" style={{ color: "#818182", textAlign: "center" }}>We've sent a verification code to your registered telephone number. To continue, please enter the code below.</Label>
        <Col xs={{ size: 8, offset: 2 }} sm={{ size: 6, offset: 3 }}>
            <Input innerRef={(input) => this.otpInput = input} type="text" name="otpCode" id="otpCodeInput" placeholder="Verification Code" invalid={!valid} valid={hasHadError && valid} {...input} />
        </Col>
        <Col xs={{ size: 12, offset: 2 }} sm={{ size: 12, offset: 3 }}>
        {touched && (error && <span style={{ color: "red" }}>* {error}</span>)}
        </Col>
    </FormGroup>
    )
  }
}