import React from "react";
import { Progress } from "reactstrap";
import * as Steps from "../VendorOwnerFormSteps";

const defaultExport = (props) => {
    const { activeStep, style } = props;
    switch (activeStep) {
        case Steps.BEFORE_WE_BEGIN:
            return (
                <div style={style}>
                    <div className="text-center progress-number"><b>1/6</b></div>
                        <Progress color="success" value="16" />
                </div>
            )
        case Steps.CONFIRM_YOUR_IDENTITY:
            return (
                <div style={style}>
                    <div className="text-center progress-number"><b>2/6</b></div>
                        <Progress color="success" value="32" />
                </div>
            )
        case Steps.CHANGE_YOUR_DETAILS:
            return (
                <div style={style}>
                    <div className="text-center progress-number"><b>3/6</b></div>
                        <Progress color="success" value="48" />
                </div>
            )
        case Steps.CONFIRM_YOUR_DETAILS:
            return (
                <div style={style}>
                    <div className="text-center progress-number"><b>4/6</b></div>
                    <Progress color="success" value="64" />
                </div>
            )
        case Steps.SIGN_CONTRACT:
            return (
                <div style={style}>
                    <div className="text-center progress-number"><b>5/6</b></div>
                    <Progress color="success" value="80" />
                </div>
            )
        case Steps.COMPLETE:
            return (
                <div style={style}>
                    <div className="text-center progress-number"><b>6/6</b></div>
                    <Progress color="success" value="100" />
                </div>
            )
        default:
            return <div />
    }
}

export default defaultExport