import React, { Component } from "react";
import CompleteIcon from "../../../images/icons/bank-details/complete.svg";

class Complete extends Component {

    render() {

        const { vendor } = this.props;

        return (
            <div>
                <div className={"d-flex justify-content-center align-items-center"}>
                    <img src={CompleteIcon} style={{height: 40, width: 40}} alt="Complete Icon"/>
                    <p id={"compliance-complete-message"} className={"ml-2"}>{vendor.name} is now compliant</p>
                </div>
                <div className={"text-center mt-4 mb-3 pt-3"}>
                    <a id={"compliance-return-to-overview"} className="fix-compliance-button btn-secondary"
                       href={"/compliance"}>Return to Overview</a>
                </div>
            </div>
        );
    }

}

export default Complete