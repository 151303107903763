import '../../../../../node_modules/react-vis/dist/style.css';
import '../../../../css/OrdersSummary.css';

import React, { Component } from 'react';
import { RadialChart } from 'react-vis';
import { Card } from 'reactstrap';
import { connect } from "react-redux";


class OrderPaymentTypeSummary extends Component {

render() {
    const { summaryData } = this.props;
    let cardOrders = 0;
    let cashOrders = 0;

    if(summaryData != null && summaryData.totalCardOrders != null){
        cardOrders = summaryData.totalCardOrders;
    }
    
    if(summaryData != null && summaryData.totalCashOrders != null){
        cashOrders = summaryData.totalCashOrders;
    }

    const chartData = [ {angle: cashOrders, label: "Cash", radius: 10, color:"#E77F8C"}, 
                        {angle: cardOrders, label: "Card", radius: 10, color:"#e7131a"},];

    return (
        <div style={{width: "100%"}}>
            <Card className="order-summary-card h-100">
                <div className="headline-title">Payment Method</div>
                <div className="row h-100 justify-content-center align-items-center">
                        <div>
                            <div className="container headline-chart">
                                <RadialChart
                                    data={chartData}
                                    width={100}
                                    height={100}
                                    colorType="literal"/>
                            </div>
                            <div className="container headline-chart-legend">
                                <span className="headline-chart-legend-block" style={{backgroundColor:"#e7131a"}}/>
                                <span className="headline-chart-legend-label">Card</span> 
                                <span className="headline-chart-legend-block" style={{backgroundColor:"#E77F8C"}}/>
                                <span className="headline-chart-legend-label">Cash</span> 
                            </div>
                        </div>
                </div>
            </Card>
        </div>
    );
  }
}
  
const mapStateToProps = (state) => ({
    summaryData: state.orders.summaryData
})

export default connect(mapStateToProps)(OrderPaymentTypeSummary);