import React, { Component } from 'react'

export default class NoReviews extends Component {
  render() {
    return (
        <div className="mh-100 minh-100">
          <div className="row justify-content-center mh-100 minh-100">
            <div className="col text-center mt-5 no-reviews-content">
              <h1 style={{color: "grey"}}>No reviews to display for selected Takeaways</h1>
            </div>
          </div>
        </div>
      );
  }
}