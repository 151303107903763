import React, { Component } from 'react';
import Moment from 'moment';
import { Container, Row } from 'reactstrap';

class OrderDetailsSummaryCard extends Component {

  formatTextElement(label, element){
    if(element != null){
      return(
        <Row className="order-detail-row">
        <div className="col-6 order-detail-col-heading">
          {label}
        </div>
        <div className="col-6 order-detail-col-val">
          <b>{element}</b>
        </div>
      </Row>
      );
    }
  }

  formatDateElement(label, element){
    if(element != null){
      return (
        <Row className="order-detail-row">
        <div className="col-6 order-detail-col-heading">
          {label}
        </div>
        <div className="col-6 order-detail-col-val">
          <b><span className="fit">{Moment(element).format("DD/MM/YY HH:mm")}</span></b>
        </div>
      </Row>
      );
    }
  }

  formatCollectionMethod(collectionMethod){
    if(collectionMethod !== undefined){
      if(collectionMethod === "DELIVERY"){
        return "Delivery"
      }
      else{
        return "Collection"
      }
    }
    return null;
  }

  formatPaymentMethod(paymentMethod){
    if(paymentMethod !== undefined){
      if(paymentMethod === "CARD"){
        return "Debit / Credit Card"
      }
      else{
        return "Cash"
      }
    }
    return null;
  }

  presentVendorLogo(logoURL){
    if(logoURL != null){
      return (
        <img data-cy='order-summary-vendor-logo' src={logoURL} alt="Vendor Logo" className="rounded float-right summary-vendor-logo"/>
      );
    }
  }

  formatSpecialInstructions(specialInstructions){
    if(specialInstructions != null){
      return (
        <div>
          <hr/>
          <Row className="order-detail-row">
            <div className="col-12 order-detail-col-heading">
              Special Instructions
            </div>
          </Row>
          <Row className="order-detail-row">
            <div>
              <b>{specialInstructions}</b>
            </div>
          </Row>
        </div>
      );
    }
    return null;
  }
  
  render() {
    return (
      <div data-cy='order-summary-card' className="card mb-2 order-summary-info-card">
      <div className="card-body">
        {this.presentVendorLogo(this.props.orderData.vendorLogo)}
        <h5 className="card-title" style={{marginTop: "0px", marginBottom: "20px"}}>Order Summary</h5>
        <Container className="order-detail-container">
        {this.formatDateElement("Date Submitted", this.props.orderData.submittedDate)}
        {this.formatTextElement("Order Type", this.formatCollectionMethod(this.props.orderData.type))}
        {this.formatTextElement("Payment Method", this.formatPaymentMethod(this.props.orderData.paymentMethod))}
        {this.formatSpecialInstructions(this.props.orderData.specialInstructions)}
        </Container>
      </div>
    </div>
    );
  }
}

export default OrderDetailsSummaryCard;