export function requestOtpToRegisteredPhone(){
    return {
        type: "REQUEST_OTP_TO_REGISTERED_PHONE"
    }
}

export function requestOtpToRegisteredPhoneSuccess(payload){
    return {
        type: "REQUEST_OTP_TO_REGISTERED_PHONE_SUCCESS",
        payload: payload
    }
}

export function requestOtpToRegisteredPhoneFailed(error){
    return {
        type: "REQUEST_OTP_TO_REGISTERED_PHONE_FAILED",
        payload: error

    }
}

export function submitOtpCodeForRegisteredPhone(payload){
    return {
        type: "SUBMIT_OTP_FOR_REGISTERED_PHONE",
        payload: payload
    }
}

export function submitOtpCodeForRegisteredPhoneSuccess(payload){
    return {
        type: "SUBMIT_OTP_FOR_REGISTERED_PHONE_SUCCESS",
        payload: payload
    }
}

export function submitOtpCodeForRegisteredPhoneFailed(error){
    return {
        type: "SUBMIT_OTP_FOR_REGISTERED_PHONE_FAILED",
        payload: error
    }
}

export function wrongOtpCodeSubmittedForVendorIdentity(){
    return {
        type: "SUBMIT_OTP_FOR_REGISTERED_PHONE_FAILED_WRONG_OTP_CODE_SUBMITTED"
    }
}

export function submitNewDetails(payload){
    return {
        type: "SUBMIT_NEW_DETAILS",
        payload: payload
    }
}

export function submitNewDetailsSuccess(payload){
    return {
        type: "SUBMIT_NEW_DETAILS_SUCCESS",
        payload: payload
    }
}

export function submitNewDetailsFailed(error){
    return {
        type: "SUBMIT_NEW_DETAILS_FAILED",
        payload: error
    }
}

export function fetchInFlightVendorOwnerDetailsUpdate(){
    return {
        type: "FETCH_IN_FLIGHT_VENDOR_OWNER_DETAILS_UPDATE"
    }
}

export function fetchInFlightVendorOwnerDetailsUpdateSuccess(payload){
    return {
        type: "FETCH_IN_FLIGHT_VENDOR_OWNER_DETAILS_UPDATE_SUCCESS",
        payload: payload
    }
}

export function fetchInFlightVendorOwnerDetailsUpdateFailed(error){
    return {
        type: "FETCH_IN_FLIGHT_VENDOR_OWNER_DETAILS_UPDATE_FAILED",
        payload: error
    }
}

export function vendorOwnerDetailsUpdateNotFound(){
    return {
        type: "IN_FLIGHT_VENDOR_OWNER_DETAILS_UPDATE_NOT_FOUND"
    }
}

export function submitOtpCode(payload){
    return {
        type: "SUBMIT_OTP_CODE_NEW_DETAILS",
        payload: payload
    }
}

export function submitOtpCodeSuccess(payload){
    return {
        type: "SUBMIT_OTP_CODE_NEW_DETAILS_SUCCESS",
        payload: payload
    }
}

export function submitOtpCodeFailed(error){
    return {
        type: "SUBMIT_OTP_CODE_NEW_DETAILS_FAILED",
        payload: error
    }
}

export function otpExpired(payload){
    return {
        type: "NEW_DETAILS_OTP_EXPIRED",
        payload: payload
    }
}

export function otpExpiredSuccess(payload){
    return {
        type: "NEW_DETAILS_OTP_EXPIRED_SUCCESS",
        payload: payload
    }
}

export function fetchSigningUrl(payload){
    return {
        type: "FETCH_NEW_DETAILS_SIGNING_URL",
        payload: payload
    }
}

export function fetchSigningUrlSuccess(payload){
    return {
        type: "FETCH_NEW_DETAILS_SIGNING_URL_SUCCESS",
        payload: payload
    }
}

export function fetchSigningUrlFailed(error){
    return {
        type: "FETCH_NEW_DETAILS_SIGNING_URL_FAILED",
        payload: error
    }
}

export function completeVendorOwnerDetailsUpdate(){
    return {
        type: "COMPLETE_VENDOR_OWNER_DETAILS_UPDATE"
    }
}

export function completeVendorOwnerDetailsUpdateSuccess(payload){
    return {
        type: "COMPLETE_VENDOR_OWNER_DETAILS_UPDATE_SUCCESS",
        payload: payload
    }
}

export function completeVendorOwnerDetailsUpdateFailed(error){
    return {
        type: "COMPLETE_VENDOR_OWNER_DETAILS_UPDATE_FAILED",
        payload: error
    }
}

export function lockVendorOwner(){
    return {
        type: "MARK_VENDOR_OWNER_AS_LOCKED"
    }
}

export function unlockVendorOwner(){
    return {
        type: "MARK_VENDOR_OWNER_AS_UNLOCKED"
    }
}

export function resendOtpToRegisteredPhone(){
    return {
        type: "RESEND_OTP_TO_REGISTERED_PHONE"
    }
}

export function resendOtpToRegisteredPhoneSuccess(payload){
    return {
        type: "RESEND_OTP_TO_REGISTERED_PHONE_SUCCESS",
        payload: payload
    }
}

export function resendOtpToRegisteredPhoneFailed(error){
    return {
        type: "RESEND_OTP_TO_REGISTERED_PHONE_FAILED",
        payload: error
    }
}

export function cancelInFlightUpdate(){
    return {
        type: "CANCEL_INFLIGHT_VENDOR_OWNER_UPDATE"
    }
}

export function cancelInFlightUpdateSuccess(payload){
    return {
        type: "CANCEL_INFLIGHT_VENDOR_OWNER_UPDATE_SUCCESS",
        payload: payload
    }
}

export function cancelInFlightUpdateFailed(error){
    return {
        type: "CANCEL_INFLIGHT_VENDOR_OWNER_UPDATE_FAILED",
        payload: error
    }
}

export function completeBeforeWeBegin(){
    return {
        type: "COMPLETE_BEFORE_WE_BEGIN"
    }
}

export function resendOtpToNewDetails(payload){
    return {
        type: "RESEND_OTP_TO_NEW_DETAILS",
        payload: payload
    }
}

export function resendOtpToNewDetailsSuccess(payload){
    return {
        type: "RESEND_OTP_TO_NEW_DETAILS_SUCCESS",
        payload: payload
    }
}

export function resendOtpToNewDetailsFailed(error){
    return {
        type: "RESEND_OTP_TO_NEW_DETAILS_FAILED",
        payload: error
    }
}

export function clearErrors(){
    return {
        type: "CLEAR_ERRORS"
    }
}

export function fetchVendorOwnerLockedStatus(){
    return {
        type: "FETCH_VENDOR_OWNER_LOCKED_STATUS"
    }
}

export function fetchVendorOwnerLockedStatusSuccess(payload){
    return {
        type: "FETCH_VENDOR_OWNER_LOCKED_STATUS_SUCCESS",
        payload: payload
    }
}

export function fetchVendorOwnerLockedStatusFailed(){
    return {
        type: "FETCH_VENDOR_OWNER_LOCKED_STATUS_FAILED"
    }
}

export function rateLimitResendOtpToRegisteredPhone(){
    return {
        type: "RATE_LIMIT_RESENT_OTP_TO_REGISTERED_PHONE"
    }
}

export function rateLimitRequestOtpToRegisteredPhone(){
    return {
        type: "RATE_LIMIT_REQUEST_OTP_TO_REGISTERED_PHONE"
    }
}

export function fetchVendorOwnerAfterUpdate(){
    return {
        type: "FETCH_VENDOR_OWNER_AFTER_UPDATE"
    }
}

export function fetchVendorOwnerAfterUpdateSuccess(payload){
    return {
        type: "FETCH_VENDOR_OWNER_AFTER_UPDATE_SUCCESS",
        payload: payload
    }
}

export function fetchVendorOwnerAfterUpdateFailed(error){
    return {
        type: "FETCH_VENDOR_OWNER_AFTER_UPDATE_FAILED",
        payload: error
    }
}