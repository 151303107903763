import { put, takeLatest, call } from 'redux-saga/effects';
import delayP from "@redux-saga/delay-p";
import * as BankDetailsActions from '../../actions/BankDetailsActions';
import ApiClientProvider from '../../utils/api/ApiClientProvider';
import { Map } from "immutable";
import { logout } from '../../actions/UserActions';
import * as Sentry from '@sentry/browser';

const SECOND = 1000;

function* fetchBankDetails(action) {
    try {
        let apiInstance = ApiClientProvider.getInvoicingConfigurationControllerApiInstance();
        const {data} = yield apiInstance.getInvoicingConfigurationsForOwnerUsingGET();
        yield put({ type: BankDetailsActions.fetchBankDetailsSuccess().type, payload: Map(data.invoicingConfigurations.map(invoiceConfig => [invoiceConfig.vendorID, invoiceConfig])) })
    } catch (e) {
        if(!e.response.data){
            Sentry.captureException(e);
            yield put({ type: BankDetailsActions.fetchBankDetailsFailed().type, payload: "Could not process request for bank details." });
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put({ type: BankDetailsActions.fetchBankDetailsFailed().type, payload: e.message });
    }
}

function* fetchEffectiveDate(action) {
    try {
        const vendorID = action.payload;
        let apiInstance = ApiClientProvider.getInvoicingConfigurationControllerApiInstance();
        const {data} = yield apiInstance.getEarliestDateForInvoiceConfigCreationUsingGET({
            vendorID: parseInt(vendorID, 10)
        });
        yield put(BankDetailsActions.fetchEffectiveDateSuccess({
            effectiveDate: data.earliestInvoicingConfigurationStartDate,
            vendorID: vendorID
        }));
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            yield put(BankDetailsActions.fetchEffectiveDateFailed("Could not process request for effective date."));
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put(BankDetailsActions.fetchEffectiveDateFailed(e));
    }
}

function* submitBankDetails(action) {
    try {
        const { formValues } = action.payload;
        const { sortCode, accountNumber, vendorID } = formValues;
        let apiInstance = ApiClientProvider.getInvoicingConfigurationControllerApiInstance();
        let newInvoicingConfiguration = {};
        newInvoicingConfiguration.accountNumber = accountNumber;
        newInvoicingConfiguration.sortCode = sortCode;
        const {data} = yield apiInstance.createInvoicingConfigurationForVendorUsingPOST({
            vendorID: vendorID,
            newInvoicingConfiguration: newInvoicingConfiguration
        });
        yield put(BankDetailsActions.submitBankDetailsSuccess(data));
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            console.log(e)
            yield put(BankDetailsActions.submitBankDetailsFailed({message: "Could not process request to submit bank details."}));
            return;
        }
        switch(e.response.status){
            case 400: {
                yield put(BankDetailsActions.submitBankDetailsFailed({message: e.response.data.message}));
                break;
            }
            case 401: {
                yield put(logout());
                break;
            }
            case 403: {
                yield put(BankDetailsActions.submitBankDetailsFailed({message: "You are not authorized to change these details right now.  We might be working on generating an invoice for you.  Please try again later."}));
                break;
            }
            default: {
                Sentry.captureException(e);
                console.log(e);
                yield put(BankDetailsActions.submitBankDetailsFailed({message: "Please try again later.  If this problem continues then please get in touch with our customer support team."}));
                break;
            }
        }
    }
}

function* submitOtpCode(action) {
    try {
        let apiInstance = ApiClientProvider.getInvoicingConfigurationControllerApiInstance();
        let updateRequest = {};
        updateRequest.otpCode = action.payload.otpCode;
        updateRequest.updateType = "VERIFY";
        const {data} = yield apiInstance.updateInvoicingConfigurationUsingPATCH({
            vendorID: action.payload.vendorID,
            configurationID: action.payload.guid,
            updateRequest: updateRequest
        });
        yield put(BankDetailsActions.submitOtpCodeSuccess(data.invoicingConfiguration));
    } catch (e) {
        if(!e.response.data){
            Sentry.captureException(e);
            yield put(BankDetailsActions.submitOtpCodeFailed({message: "Could not process request to submit verification code."}));
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
            return;
        } else if(e.response.status === 403){
            yield put(BankDetailsActions.submitOtpCodeFailed({message: e.response.data.message}));
            return;
        }
        Sentry.captureException(e);
        yield put(BankDetailsActions.submitOtpCodeFailed({message: "Could not process request to submit verification code."}));
    }
}

function* cancelBankDetailsUpdate(action) {
    try {
        let apiInstance = ApiClientProvider.getInvoicingConfigurationControllerApiInstance();
        yield apiInstance.cancelInvoicingConfigurationUpdateUsingDELETE({
            vendorID: action.payload.vendorID,
            configurationID: action.payload.guid
        });
        action.payload.push("/bank-details");
        yield put(BankDetailsActions.cancelBankDetailsUpdateSuccess());
        
    } catch (e) {
        if(!e.response.data){
            Sentry.captureException(e);
            yield put(BankDetailsActions.cancelBankDetailsUpdateFailed({message: "Could not process request to cancel bank details update."}));
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put(BankDetailsActions.cancelBankDetailsUpdateFailed(e));
    }
}

function* fetchSigningUrl(action) {
    try {
        let apiInstance = ApiClientProvider.getInvoicingConfigurationControllerApiInstance();
        let updateRequest = {};
        updateRequest.updateType = "SIGN";
        let data;
        for (let x = 0; x < 60; x++) {
            let response = yield apiInstance.updateInvoicingConfigurationUsingPATCH({
                vendorID: action.payload.vendorID,
                configurationID: action.payload.guid,
                updateRequest: updateRequest
            });

            data = response.data;
            console.log(data)
            if(data.agreementSigningURL){
                break;
            }
            if(x === 59){
                //If contract generation takes a minute we time it out and display an error message to the user
                throw new Error("Contract generation timed out.")
            }
            yield call(delayP, SECOND); // wait a second
        }
        yield put(BankDetailsActions.fetchSigningUrlSuccess({
            vendorID: action.payload.vendorID,
            agreementSigningURL: data.agreementSigningURL
        }));
    } catch (e) {
        if(!e.response){
            Sentry.captureException(e);
            console.log(e)
            yield put(BankDetailsActions.fetchSigningUrlFailed({message: "Could not process request to get the signing URL for the contract."}));
            return;
        }
        if(e.response.status === 401){
            yield put(logout());
        }
        Sentry.captureException(e);
        yield put(BankDetailsActions.fetchSigningUrlFailed(e));
    }
}

export default function* rootBankDetailsSaga() {
    yield takeLatest(BankDetailsActions.fetchBankDetails().type, fetchBankDetails);
    yield takeLatest(BankDetailsActions.cancelBankDetailsUpdateSuccess().type, fetchBankDetails);
    yield takeLatest(BankDetailsActions.fetchEffectiveDate().type, fetchEffectiveDate);
    yield takeLatest(BankDetailsActions.submitBankDetails().type, submitBankDetails);
    yield takeLatest(BankDetailsActions.cancelBankDetailsUpdate().type, cancelBankDetailsUpdate);
    yield takeLatest(BankDetailsActions.submitOtpCode().type, submitOtpCode);
    yield takeLatest(BankDetailsActions.fetchSigningUrl().type, fetchSigningUrl);
}