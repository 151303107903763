import React, { Component } from "react";
import { Jumbotron, Container, Row, Col, Alert } from "reactstrap";
import VendorLogoAddressView from "../vendor/VendorLogoAddressView";
import { Button } from "reactstrap";
import {MdWarning} from "react-icons/md";
import LoadingSpinner from "../loading/LoadingSpinner";
import {userHasPermissionForVendor} from "../../utils/helpers/User";
import {CREATE_BANK_DETAILS} from "../../utils/helpers/Permission";

const maskExceptLastFour = (number) => {
    if (number != null && number.length > 4) {
        const ASTERISK = "*";
        const maskedLength = number.length - 4;
        return ASTERISK.repeat(maskedLength).concat(number.slice(maskedLength));
    }
}

class BankDetailsList extends Component {

    componentDidMount() {
        this.props.updateHeading("Bank Details")
    }

    render() {
        const {
            bankDetails,
            vendors,
            vendorsCompliance,
            vendorComplianceRequiredForBankDetailsChange,
            editVendorBankDetails,
            user
        } = this.props;
        const vendorList = vendors.toList().toJS();
        if(!vendorList || vendorList.length === 0){
            return <LoadingSpinner size={80} />
        }
        if (bankDetails.isEmpty()) {
            return (
                vendorList.filter(vendor => vendor.currentlyOwnedByAuthenticatedOwner).map(vendor => {
                    return (
                        <Jumbotron key={vendor.vendorID} fluid className="bank-details-jumbotron">
                            <Container fluid>
                                <VendorLogoAddressView vendorID={vendor.vendorID} />
                                <Row style={{ paddingTop: "30px" }}>
                                    <Col sm={{size:"8", offset:"2"}}>
                                        <Alert className="bank-details-alert">
                                            <h4 className="alert-heading">No bank details</h4>
                                            <p>There are currently no bank details associated with any takeaways you own.  Please get in touch with our customer support team to add bank details.</p>
                                        </Alert>
                                    </Col>
                                </Row>
                            </Container>
                        </Jumbotron>
                    );
                })
            );
        }

        return (
            vendorList.filter(vendor => vendor.currentlyOwnedByAuthenticatedOwner).map(vendor => {
                return (
                    <Jumbotron data-cy="bank-details-card" key={vendor.vendorID} fluid style={{ backgroundColor: "white", borderRadius: "5px", paddingTop: "2px", paddingBottom: "2px" }}>
                        <Container fluid>
                            <VendorLogoAddressView vendorID={vendor.vendorID} />
                            {bankDetails.get(vendor.vendorID) && <div> <Row style={{ paddingTop: "30px" }}>
                                <Col sm={{ size: "auto", offset: 1 }} className="sort-code-column">
                                    <span style={{ fontWeight: "bold", fontSize: "14px" }} className="ml-2">Sort Code: </span><span style={{ fontSize: "14px" }}>{bankDetails.get(vendor.vendorID).sortCode}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={{ size: "auto", offset: 1 }} className="account-number-column">
                                    <span style={{ fontWeight: "bold", fontSize: "14px" }} className="ml-2">Account Number: </span><span style={{ fontSize: "14px" }}>{maskExceptLastFour(bankDetails.get(vendor.vendorID).accountNumber)}</span>
                                </Col>
                                <Col style={{ paddingTop: "15px" }} xs={{ offset: 0, size: "12" }} sm={{ offset: 8, size: "4" }} md={{ offset: 8, size: "4" }}>
                                    { userHasPermissionForVendor(user, vendor.vendorID, CREATE_BANK_DETAILS) && <Button disabled={isEditButtonDisabled(vendor, vendorsCompliance, vendorComplianceRequiredForBankDetailsChange)} style={{ width: "100%", cursor: "pointer" }} onClick={() => editVendorBankDetails(vendor.vendorID)} color="secondary">{!bankDetails.get(vendor.vendorID).inProgressUpdate && "Edit"}
                                        {bankDetails.get(vendor.vendorID).inProgressUpdate && "Complete Update"}</Button>}
                                </Col>
                            </Row> </div>}
                            {!bankDetails.get(vendor.vendorID) && <div>
                                <Row style={{ paddingTop: "30px" }}>
                                    <Col sm={{size:"12"}}>
                                        <Alert color="warning">
                                            <h4 className="alert-heading">No bank details</h4>
                                            <p>There are currently no bank details associated with this takeaway.  Please get in touch with our customer support team to add bank details.</p></Alert>
                                    </Col>
                                </Row> </div>}
                            {bankDetails.get(vendor.vendorID) && bankDetails.get(vendor.vendorID).inProgressUpdate &&
                            <Row>
                                <Col xs={{offset: 0, size: "12"}} sm={{offset: 8, size: "4"}}
                                     md={{offset: 8, size: "4"}}>
                                    <Alert color="warning">
                                        <MdWarning size={25}/>This update hasn't been completed.
                                    </Alert>
                                </Col>
                            </Row>}
                            {isEditBlockedDueToComplianceChecksInProgress(vendor, vendorsCompliance, vendorComplianceRequiredForBankDetailsChange) &&
                            <Row>
                                <Col xs={{ offset: 0, size: "12" }} sm={{ offset: 8, size: "4" }} md={{ offset: 8, size: "4" }}>
                                    <Alert id="bank-details-compliance-checks-in-progress-warning" color="warning">
                                        <MdWarning size={25}/> Account changes are being verified. During this time you cannot change bank details.
                                    </Alert>
                                </Col>
                            </Row>
                            }
                            {isEditBlockedDueToNonCompliance(vendor, vendorsCompliance, vendorComplianceRequiredForBankDetailsChange) &&
                            <Row>
                                <Col xs={{ offset: 0, size: "12" }} sm={{ offset: 8, size: "4" }} md={{ offset: 8, size: "4" }}>
                                    <Alert id="bank-details-non-compliance-error" color="danger">
                                        <MdWarning size={25}/> To edit bank details, you must first ensure your account is fully compliant
                                    </Alert>
                                    <a id={"continue-to-compliance-button"} className="fix-compliance-button btn-secondary btn-destructive btn-block text-center"
                                       href={"/compliance"}>Compliance</a>
                                </Col>
                            </Row>
                            }
                        </Container>
                    </Jumbotron>
                );
            })
        );
    }


}


function isEditButtonDisabled(vendor, vendorsCompliance, vendorComplianceRequiredForBankDetailsChange) {
    if(! vendorComplianceRequiredForBankDetailsChange) return false;
    const complianceDetailsForVendor = vendorsCompliance.find( vc => vc.vendorId === vendor.vendorID );
    return complianceDetailsForVendor.vendorComplianceStatus !== "COMPLIANT";
}

function isEditBlockedDueToNonCompliance(vendor, vendorsCompliance, vendorComplianceRequiredForBankDetailsChange) {
    if(! vendorComplianceRequiredForBankDetailsChange) return false;
    const complianceDetailsForVendor = vendorsCompliance.find( vc => vc.vendorId === vendor.vendorID );
    return complianceDetailsForVendor.vendorComplianceStatus === "NOT_COMPLIANT";
}

function isEditBlockedDueToComplianceChecksInProgress(vendor, vendorsCompliance, vendorComplianceRequiredForBankDetailsChange) {
    if(! vendorComplianceRequiredForBankDetailsChange) return false;
    const complianceDetailsForVendor = vendorsCompliance.find( vc => vc.vendorId === vendor.vendorID );
    return complianceDetailsForVendor.vendorComplianceStatus === "COMPLIANCE_CHECKS_IN_PROGRESS";
}


export default BankDetailsList;