import React, { Component } from 'react';
import {Alert, Row} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../css/PaginationControl.css'
import {connect} from "react-redux";
import withRouter from "react-router-dom/withRouter";

class NoPermission extends Component {

  render() {
    return (
      <div className="mh-100 minh-100 reviews-page-container">
        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
        <Row style={{ margin: "0px" }}>
          <div className="col-sm-12" style={{ padding: "0px" }}>
            <div className="summary-title-row">
              <div className="col-sm-12">
                <div>
                  <h1 data-cy="page-title" className="d-none d-md-inline-block" style={{ padding: "0px", color: "grey" }}>Unauthorized</h1>
                  <h3 data-cy="page-title" className="d-md-none d-inline-block" style={{ padding: "0px", color: "grey", marginTop: "0px" }}>Unauthorized</h3>
                </div>
              </div>
            </div>
          </div>
          </Row>

            <Alert data-cy="no permission" color="warning" className="mt-3 text-center">
              Sorry, you don't have permission to look at this page. <br /> Do you think this is a bug? Please let us know.
            </Alert>
        </div>
    );
  }
}

const connectedComponent = connect()(NoPermission)

export default withRouter(connectedComponent);