import {
    Configuration,
    VendorOwnerControllerApi,
    InvoicesControllerApi,
    OrdersControllerApi,
    InvoicingConfigurationControllerApi,
    VendorControllerApi,
    ComplianceControllerApi,
    PayoutsControllerApi,
    UserControllerApi
} from '@scoffable/scoffable-api-partner-centre-public-javascript-connector';
import {getCookies, isCookiesExists} from "../cookie/CookieManager";

let configuration = new Configuration();
configuration.basePath = process.env.REACT_APP_API_URL;


class ApiClientProvider {

    static refreshCookies(){
        if(isCookiesExists()) {
            configuration.apiKey = `Bearer ${getCookies().authToken}`;
        }
        configuration.baseOptions = {
            headers: {
                'x-cf-ss': `31734ee8-f5e8-4551-84de-a20c50ce095b`,
            },
        }
    }

    static getVendorOwnerControllerApiInstance() {
        this.refreshCookies();
        return new VendorOwnerControllerApi(configuration);
    }

    static getInvoicesControllerApiInstance(){
        this.refreshCookies();
        return new InvoicesControllerApi(configuration);
    }

    static getOrdersControllerApiInstance(){
        this.refreshCookies();
        return new OrdersControllerApi(configuration);
    }

    static getInvoicingConfigurationControllerApiInstance(){
        this.refreshCookies();
        return new InvoicingConfigurationControllerApi(configuration);
    }

    static getVendorControllerApiInstance(){
        this.refreshCookies();
        return new VendorControllerApi(configuration);
    }

    static getComplianceControllerApiInstance(){
        this.refreshCookies();
        return new ComplianceControllerApi(configuration);
    }

    static getPayoutsControllerApiInstance(){
        this.refreshCookies();
        return new PayoutsControllerApi(configuration);
    }

    static getUserControllerApiInstance(){
        this.refreshCookies();
        return new UserControllerApi(configuration);
    }
}

export default ApiClientProvider;