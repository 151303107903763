import React, { Component } from 'react';
import { Segment, Responsive } from 'semantic-ui-react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../css/Carousel.css"
import '../../css/SummaryCard.css'

import BalanceSummary from "./BalanceSummary"

export default class BalanceSummaries extends Component {

    constructor(props){
        super(props);
        this.state = {}
    }

    handleOnUpdate = (e, { width }) => this.setState({ width })

    render() {
        const { width } = this.state;
        const isMobile = width <= 768;
        const { vendors, user } = this.props;

        const settings = {
            dots: true,
            fade: true,
            infinite: true,
            speed: process.env.CAROUSEL_TRANSITION_SPEED,
            slidesToShow: 1,
            slidesToScroll: 1,
            centreMode: true
        };

        let slides = [];

        vendors
            .forEach((vendor) => {
                slides.push(<BalanceSummary key={vendor.vendorID} vendor={vendor} isMobile={isMobile} width={width} user={user}/>)
            })

        if(slides.length === 0){
            // Empty dataset view
            return (<Responsive as={Segment} fireOnMount={true} onUpdate={this.handleOnUpdate} data-cy="summary-card" className="summary-section" >
                <div className="text-center">No outlet selected</div>
            </Responsive> )
        } else if (slides.length === 1) {
            return (
                <Responsive as={Segment} fireOnMount={true} onUpdate={this.handleOnUpdate} data-cy="summary-card" className="summary-section" >
                    {slides}
                </Responsive>
            );
        } else { // length > 1, show Carousel using Slider
            return (
                <Responsive as={Segment} fireOnMount={true} onUpdate={this.handleOnUpdate} data-cy="summary-card" className="summary-section">
                    <Slider {...settings} style={{color: '#000'}}>
                        {slides}
                    </Slider>
                </Responsive>
            )
        }

    }
}
