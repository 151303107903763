import { fetchOrderStats, fetchOrderStatsSuccess, fetchOrderStatsFailed, fetchOrders, fetchOrdersSuccess, fetchOrdersFailed,
    clearOrderStats, clearOrders, fetchOrderDetails, fetchOrderDetailsSuccess, fetchOrderDetailsFailed, changeActiveFilter, 
    updateOrdersListEndDate, updateOrdersListStartDate, updateOrdersListOrdersPerPage, toggleOrderSummaryView, updateOrdersListActivePage, showOrders, showOrderStats } from "../../actions/OrderActions";
import { getDateRangeForFilter } from '../../utils/dateUtils';
import { logoutSuccess } from "../../actions/UserActions";
import { MONTH } from "../../containers/Orders/FilterNames";
const { startDate, endDate } = getDateRangeForFilter(MONTH.filterName);
const initialState = {fetchOrderStatsInProgress: false, currentPage: 1, totalOrderNumber: 0, tabeldata: null, error: null, 
    ordersFetched: false, orderData: null, orderNotFound: false, activeFilterName: MONTH.filterName, ordersHeadingText: MONTH.headingText,
ordersListEndDate: endDate, ordersListStartDate: startDate, ordersListOrdersPerPage: 10, showOrderSummaryView: false,
dailyOrderValueTotals: [], dailyOrderNumberTotals: [], summaryData: {}, ordersListActivePage: 0};

export default function OrdersReducer(state = initialState, action){
    switch (action.type){
        case fetchOrderStats().type:
            return {...state, fetchOrderStatsInProgress: true, error: null}
        case fetchOrderStatsSuccess().type:
            return {...state, fetchOrderStatsInProgress: false, error: null, dailyOrderValueTotals: action.payload.dailyOrderValueTotals, dailyOrderNumberTotals: action.payload.dailyOrderNumberTotals, summaryData: action.payload.summaryData};
        case fetchOrderStatsFailed().type:
            return {...state, fetchOrderStatsInProgress: false, error: action.payload}
        case clearOrderStats().type:
            return {...state, dailyOrderNumberTotals: null, dailyOrderValueTotals: null, summaryData: null, fetchOrderStatsInProgress: false}
        case fetchOrders().type:
            return {...state, fetchOrdersInProgress: true, ordersFetched: false}
        case fetchOrdersSuccess().type:
            return {...state, fetchOrdersInProgress: false, tabledata: action.payload.tabledata, totalOrderNumber: action.payload.totalOrderNumber, currentPage: action.payload.currentPage, ordersFetched: true, ordersListLastPage: Math.ceil(action.payload.totalOrderNumber / state.ordersListOrdersPerPage)}
        case fetchOrdersFailed().type:
            return {...state, fetchOrdersInProgress: false, error: action.payload, tabledata: null, totalOrderNumber: 0, ordersFetched: false}
        case clearOrders().type:
            return {...state, tabledata: [], totalOrderNumber: 0, fetchOrdersInProgress: false}
        case fetchOrderDetails().type:
            return {...state, orderID: action.payload.orderID, orderNotFound: false, orderData: null}
        case fetchOrderDetailsSuccess().type:
            return {...state, orderData: action.payload, orderNotFound: false}
        case fetchOrderDetailsFailed().type:
            return {...state, orderData: null, orderNotFound: true}
        case changeActiveFilter().type:
            return {...state, activeFilterName: action.payload.filterName, ordersHeadingText: action.payload.headingText, ordersListActivePage: 0}
        case updateOrdersListEndDate().type:
            return {...state, ordersListEndDate: action.payload}
        case updateOrdersListStartDate().type:
            return {...state, ordersListStartDate: action.payload}
        case updateOrdersListOrdersPerPage().type:
            return {...state, ordersListOrdersPerPage: action.payload, ordersListActivePage:0}
        case toggleOrderSummaryView().type:
            return {...state, showOrderSummaryView: !state.showOrderSummaryView}
        case updateOrdersListActivePage().type:
            return {...state, ordersListActivePage: action.payload}
        case logoutSuccess().type:
            return {...initialState}
        case showOrders().type:
            return {...state, showOrderSummaryView: false}
        case showOrderStats().type:
            return {...state, showOrderSummaryView: true}
        default:
            return state;
    }
}