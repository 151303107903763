import React from "react";
import { Progress } from "reactstrap";
import * as Steps from "./StepValues";

const defaultExport = (props) => {
    const { activeStep, className } = props;
    switch (activeStep) {
        case Steps.INITIAL_INSTRUCTIONS:
            return (
                <div className={className}>
                    <div className="text-center"><b>1/3</b></div>
                        <Progress color="success" value="33" />
                </div>
            )
        case Steps.RETURN_FROM_ONBOARDING:
            return (
                <div className={className}>
                    <div className="text-center"><b>2/3</b></div>
                        <Progress color="success" value="66" />
                </div>
            )
        case Steps.COMPLETE:
            return (
                <div className={className}>
                    <div className="text-center"><b>3/3</b></div>
                    <Progress color="success" value="100" />
                </div>
            )
        default:
            return <div />
    }
}

export default defaultExport