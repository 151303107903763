import React, { Component } from "react";
import ValidateConfirmBankDetails from "../validation/ValidateConfirmBankDetails";
import { Field, reduxForm } from "redux-form";
import LoaderButton from "../../../LoaderButton";
import { Card, CardText, Row, Col, Alert, Tooltip } from "reactstrap";
import {FaQuestionCircle} from "react-icons/fa";
import OtpCode from "../../../common-form-fields/OtpCode";
import Loader from 'react-loader-spinner';
import moment from "moment";
import "moment/locale/en-gb";
moment.locale("en-gb");

class ConfirmBankDetails extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        const { accountNumber, sortCode, bankName, effectiveDate, maxOTPMessagesSent, submittingBankDetails, otpCodeError } = this.props;
        return (
            <Row>
                <Col xs="12" sm="12" md="12" lg={{ size: 6, offset: 3 }} xl={{ size: 6, offset: 3 }}>
                    {maxOTPMessagesSent && !submittingBankDetails && <Alert color="warning">
                        You have requested too many verification codes.  Please wait 30 seconds before <button className="link-button alert-link" onClick={() => this.props.resubmitBankDetails({
                            accountNumber: this.props.accountNumber,
                            sortCode: this.props.sortCode,
                            vendorID: this.props.vendorID
                        })}>retrying.</button>
                    </Alert>}
                    {otpCodeError && !submittingBankDetails && <Alert color="warning">
                        {otpCodeError.message} </Alert>}
                    <Card body>
                        <CardText>
                            <Row className="row justify-content-center">
                                <Col xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                                    <span style={{ display: "block" }}><b>Sort Code:</b> {sortCode}</span>
                                    <span style={{ display: "block" }}><b>Account Number:</b> {accountNumber}</span>
                                    <span style={{ display: "block" }}><b>Bank Name:</b> {bankName}</span>
                                    <span style={{ display: "block" }}><b>Effective Date:</b>
                                        {this.props.fetchingEffectiveDate && <span style={{ display: "inline-block", paddingLeft: "5px" }}>
                                            <Loader type="Oval" color="#e7131a" height={15} width={15} /> </span>}
                                        {!this.props.fetchingEffectiveDate && <span > {moment(effectiveDate).format("L")}
                                            <FaQuestionCircle id="effectiveDateToolTip" style={{ marginLeft: "5px" }} />
                                            <Tooltip isOpen={this.state.tooltipOpen} placement="right" toggle={this.toggle} target="effectiveDateToolTip">
                                                Changes will take effect from the end of your last billing period.
            </Tooltip> </span>}

                                    </span>
                                </Col>
                            </Row>
                        </CardText>
                    </Card>
                    <form onSubmit={this.props.handleSubmit}>
                        <Field name="otpCode" type="text" component={OtpCode} />
                        <Field
                            name="vendorID"
                            val={this.props.vendorID}
                            component={({ input, val }) => {
                                input.onChange(val)
                                return <div />
                            }}
                        />
                        <Field
                            name="guid"
                            val={this.props.guid}
                            component={({ input, val }) => {
                                input.onChange(val)
                                return <div />
                            }}
                        />
                        <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                            <LoaderButton
                                id={"bank-details-send-verification-code-button"}
                                block
                                type="submit"
                                isLoading={this.props.submittingOtpCode}
                                text="Verify Code"
                                loadingText="Submitting…"
                                className="btn-default"
                                style={{ cursor: "pointer" }} />
                            <div className="mt-1 w-100 text-right">
                                {!maxOTPMessagesSent && !submittingBankDetails && <button id={"bank-details-resend-verification-code-button"} className="link-button black-link" onClick={() => this.props.resubmitBankDetails({
                                    accountNumber: this.props.accountNumber,
                                    sortCode: this.props.sortCode,
                                    vendorID: this.props.vendorID
                                })}>Resend Verification Code</button>}
                                {!maxOTPMessagesSent && !submittingBankDetails && <span> | </span>}
                                {submittingBankDetails && <Loader type="Oval" color="#e7131a" height={15} width={15} />}
                                <button  id={"bank-details-verification-code-cancel-button"}  className="link-button black-link" onClick={() => this.props.handleCancel({ vendorID: this.props.vendorID, guid: this.props.guid })}> Cancel</button>
                            </div>
                        </Col>
                    </form>
                </Col>
            </Row>
        );
    }
}

export default reduxForm({
    form: "ConfirmBankDetails",
    validate: ValidateConfirmBankDetails
})(ConfirmBankDetails)