import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';

import MapWithMarker from '../MapWithMarker';
import {userHasPermission} from "../../utils/helpers/User";
import {VIEW_ORDERS_CUSTOMER_INFO} from "../../utils/helpers/Permission";


class OrderDetailsCustomerDetailsCard extends Component {
    
  formatTextElement(label, element){
    if(element != null){
      return(
        <Row className="order-detail-row" data-cy={"row-"+label}>
        <div data-cy='label' className="col-6 col-md-4 order-detail-col-heading">
          {label}
        </div>
        <div data-cy='value' className="col-6 col-md-8 order-detail-col-val">
          <b>{element}</b>
        </div>
      </Row>
      );
    }
  }

  formatTelephoneNumber(){
    if(this.props.orderData.telephoneNumberMaskingDetails != null){
      console.log("Got masking details", this.props.orderData.telephoneNumberMaskingDetails)
      return(<div>{this.formatTextElement("Telephone", this.props.orderData.telephoneNumberMaskingDetails.serviceTelephoneNumber)}
        {this.formatTextElement("Auth Code", this.props.orderData.telephoneNumberMaskingDetails.authCode)}</div>)
    }
    else{
      if(this.props.orderData.telephoneNumber != null) {
        return this.formatTextElement("Telephone", this.props.orderData.telephoneNumber)
      }
      else{
        return this.formatTextElement("Telephone", (<i>Expired</i>))
      }
    }
  }
  
  render() {
    const {orderData, user} = this.props;

    let deliveryAddress;

    if(this.props.orderData.deliveryAddress != null){
      deliveryAddress=(
        <div>
           <hr/>
          {this.formatTextElement("Organisation", orderData.deliveryAddress.organisation)}
          {this.formatTextElement("Address Line 1", orderData.deliveryAddress.firstLine)}
          {this.formatTextElement("Address Line 2", orderData.deliveryAddress.secondLine)}
          {this.formatTextElement("Locality", orderData.deliveryAddress.locality)}
          {this.formatTextElement("Town", orderData.deliveryAddress.town)}
          {this.formatTextElement("County", orderData.deliveryAddress.county)}
          {this.formatTextElement("Postcode", orderData.deliveryAddress.postcode)}
        </div>
      );
    }

    let mapSection;
    if(orderData.deliveryAddress != null 
      && orderData.deliveryAddress.latitude !== undefined 
      && orderData.deliveryAddress.longitude != null){
         mapSection = (
          <div>
            <hr/>
            <div className="google-map">
              <MapWithMarker
                latitude = {orderData.deliveryAddress.latitude}
                longitude = {orderData.deliveryAddress.longitude}
                zoomLevel = {12}
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBc0XB1M5qh3RZLEr7edXcH4gH4yuXB2-I&v=3.exp&libraries=geometry,drawing,places"
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `400px` }} />}
                mapElement={<div style={{ height: `100%` }} />}
              />
            </div>
        </div>
        )
    }

    let customerDetailsSection;
    if(userHasPermission(user, VIEW_ORDERS_CUSTOMER_INFO)) {
      if (orderData.firstName != null) {
        customerDetailsSection = (
            <Container className="order-detail-container">
              {this.formatTextElement("Customer Name", orderData.firstName + " " + orderData.surname != null ? orderData.surname : "")}
              {this.formatTelephoneNumber()}
              {deliveryAddress}
              {mapSection}
            </Container>
        )
      } else {
        customerDetailsSection = (
            <p><i>Customer details expire after 24 hours.</i></p>
        );
      }
    } else {
      customerDetailsSection = ( <p><i>You don't have permission to view customer details</i></p>)
    }

    return (<div className="card mb-2">
    <div data-cy="customer-details-card" className="card-body">
      <h5 className="card-title">Customer Details</h5>
      {customerDetailsSection}
    </div>
  </div>);
  }
}

export default OrderDetailsCustomerDetailsCard;
