import React, { Component } from 'react';
import {MdError} from "react-icons/md";
import { Row, Col, Button, Container } from "reactstrap";
import Loader from 'react-loader-spinner';

export default class Error extends Component {
    render() {
        const { vendorOwnerDetailsUpdateErrorMessage, updatingVendorOwner, push, cancelEditVendorOwnerDetails } = this.props;
        return (
            <Row>
                <Col xs="12" sm="12" md="12" lg={{ size: 6, offset: 3 }} xl={{ size: 6, offset: 3 }}>
                    {updatingVendorOwner && <Row className="row justify-content-center"><Loader
                        type="Puff"
                        color="#e7131a"
                        height="50"
                        width="50" /></Row>}
                    {!updatingVendorOwner && <div>
                        <Container>

                            <Col xs={{ size: "auto", offset: 0 }} sm={{ size: "auto", offset: 0 }} md={{ size: "auto", offset: 0 }} lg={{ size: "auto", offset: 0 }} xl={{ size: "auto", offset: 0 }} className="mx-auto d-block">

                                    <MdError size={50} color="#e7131a" className="mx-auto d-block" />
                            </Col>

                            <Col xs={{ size: "auto", offset: 0 }} sm={{ size: "auto", offset: 0 }} md={{ size: "auto", offset: 0 }} lg={{ size: "auto", offset: 0 }} xl={{ size: "auto", offset: 0 }}>
                                <div>
                                    <span className="text-center" style={{ display: "block" }}>Something appears to have gone wrong while updating your details.</span>
                                    <span className="text-center" style={{ display: "block" }}>{vendorOwnerDetailsUpdateErrorMessage}</span>
                                </div>
                            </Col>
                        </Container>
                        <div style={{ paddingTop: "20px" }}>
                            <Button block className="btn-default" style={{ cursor: "pointer" }} onClick={() => {
                                push("/user-management")
                                cancelEditVendorOwnerDetails();
                                }}>
                                Return to User Management
                    </Button>
                        </div>
                    </div>}
                </Col>
            </Row>
        )
    }
}
