import React, { Component } from "react";
import { Row, Col, Container, Alert } from "reactstrap";
import { Button } from "reactstrap";
import {MdWarning} from "react-icons/md";
import {CREATE_CONTACT_DETAILS} from "../../utils/helpers/Permission";
import {userHasPermission} from "../../utils/helpers/User";

class VendorOwnerDetailsView extends Component {

    componentDidMount() {
        this.props.updateHeading("Contact Details");

        if(userHasPermission(this.props.user, CREATE_CONTACT_DETAILS)) {
            this.props.fetchVendorOwnerLockedStatus();
            this.props.fetchInFlightVendorOwnerDetailsUpdate();
        }
    }

    render() {
        const { user, editVendorOwnerDetails, inFlightVendorOwnerChange, clearErrors, isVendorOwnerLocked, fetchingVendorOwnerLockedStatus } = this.props;
        const disableEditVendorOwnerDetails = inFlightVendorOwnerChange.isEmpty() && isVendorOwnerLocked;
        return (
            <Container data-cy="contact-details-card">
                <Row style={{padding: "20px"}} className="no-margin">
                    <Col className="no-padding no-margin">
                        <span style={{ fontSize: "18px" }}>My Details</span>
                    </Col>
                </Row>
                <Row style={{padding: "20px"}} className="no-margin">
                    <Col xs={{ size: 12 }} lg={{ size: 8 }} className="no-padding no-margin" >
                        <Container className="no-padding no-margin">
                            <span className="d-block d-md-inline-block" style={{ fontWeight: "bold", fontSize: "14px" }}>Email Address: </span>
                            <span className="mx-0 mx-md-2" style={{ fontSize: "14px" }}>{user.emailAddress}</span>
                        </Container>
                        <Container className="no-padding no-margin">
                            <span className="d-block d-md-inline-block" style={{ fontWeight: "bold", fontSize: "14px" }}>Phone Number: </span>
                            <span className="mx-0 mx-md-2" style={{ fontSize: "14px" }}>{user.telephoneNumber}</span>
                        </Container>
                    </Col>
                    { userHasPermission(user, CREATE_CONTACT_DETAILS) && <Col xs={{ size: 12 }} lg={{ size: 4 }} className="no-padding no-margin">
                        <div style={{ lineHeight: "100px" }}>
                            <Button data-cy="contact-details-action-button" style={{ width: "100%", cursor: disableEditVendorOwnerDetails ? "auto": "pointer", paddingTop: "15px", paddingBottom: "15px", verticalAlign: "middle" }} onClick={() => {
                                clearErrors()
                                editVendorOwnerDetails()
                                }} color="secondary" disabled={disableEditVendorOwnerDetails || fetchingVendorOwnerLockedStatus}>
                            {inFlightVendorOwnerChange.size === 0 && "Edit"}
                            {inFlightVendorOwnerChange.size > 0 && "Complete Update"}
                            </Button>
                        </div>
                        {!inFlightVendorOwnerChange.isEmpty() && <Alert color="warning">
                                        <MdWarning size={25} />You have an update in progress. <button className="link-button alert-link" style={{fontWeight: "bold", textDecoration: "underline"}} onClick={() => this.props.cancelInFlightUpdate()}>Click here</button> to cancel the update
                            </Alert>}
                            {disableEditVendorOwnerDetails && <Alert color="warning">Details can't be updated at this time due to other changes which are in progress for this account.  If this problem continues please contact our customer service team and we will investigate.
                            </Alert>}
                    </Col>}
                </Row>
            </Container>
        );
    }
}
export default VendorOwnerDetailsView;