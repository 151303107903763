export const userHasPermission = (user, requiredPermission) => {
    const hasPermission =  (user.permissions.global.permissions.some( permission => permission.key === requiredPermission)) ||
    user.permissions.vendors.some( vendor => vendor.permissions.some( permission => permission.key === requiredPermission))

    if(!hasPermission){
        console.warn("User doesn't have permission " + requiredPermission)
    }
    return hasPermission;
}

export const userHasPermissionForVendor = (user, vendorId, requiredPermission) => {
    const hasPermission =  user.permissions.vendors.some( vendor => vendor.vendorId === vendorId && vendor.permissions.some( permission => permission.key === requiredPermission))

    if(!hasPermission){
        console.warn("User doesn't have permission " + requiredPermission + " for vendor id: " + vendorId)
    }
    return hasPermission;
}