import React from "react";
import { Label, Input, FormGroup } from "reactstrap";

let hasHadError = false;

const defaultExport = ({ input, label, type, meta: { touched, error } }) => {
    let valid;
    if(!touched){
        valid = true
    } else if(error){
        hasHadError = true;
        valid = false;
    } else {
        valid = true;
    }
    return (<FormGroup>
        <Label for="phoneNumberInput"><b>Phone Number</b></Label>
        <Input type="text" name="phoneNumber" id="phoneNumberInput" placeholder="Your new phone number" invalid={!valid} valid={hasHadError && valid} {...input} />
        {touched && (error && <span style={{color: "red"}}>* {error}</span>)}
        </FormGroup>);
}

export default defaultExport