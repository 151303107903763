import "../../css/App.css"

import React from "react";
import { ClipLoader } from 'react-spinners';

const defaultExport = () => {
  return (
    <div className="mh-100 minh-100">
      <div className="row justify-content-center mh-100 minh-100">
        <div data-cy="loading-spinner" className="col align-self-center text-center">
          <ClipLoader color={'#e7131a'} size={50} />
        </div>
      </div>
    </div>
  );
}

export default defaultExport
