import React, {Component} from 'react';
import {Container, Card, CardBody, Col, Row, Tooltip} from "reactstrap";
import {Link} from 'react-router-dom';
import moment from "moment";
import MessageIcon from '../../images/message_black.svg';
import MessageIconDisabled from '../../images/message_grey.svg';
import ReplyForm from './ReplyForm';
import "moment/locale/en-gb";
import '../../css/OrderReviews.css'
import Replies from './Replies';
import EmojiTerrible from './emoticons/EmojiTerrible';
import EmojiBad from './emoticons/EmojiBad';
import EmojiGood from './emoticons/EmojiGood';
import EmojiGreat from './emoticons/EmojiGreat';
import {CREATE_REVIEWS_REPLIES} from "../../utils/helpers/Permission";
import {userHasPermissionForVendor} from "../../utils/helpers/User";

moment.locale("en-gb");

export default class ReviewsCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            replyFormOpen: false,
            replyNotPermittedTooltipOpen: false,
            ...props.initialState
        }
    }

    toggleReplyNotPermittedTooltip() {
        if(!this.props.replyPermitted) {
            this.setState({
                replyNotPermittedTooltipOpen: !this.state.replyNotPermittedTooltipOpen
            });
        }
    }

    presentReviewRating(rating, size, style) {
        if (rating !== undefined) {
            return (
                <div className="card-rating">
                    {rating === 1 && <EmojiTerrible size={size} style={style} alt="Terrible Rating Emoji"/>}
                    {rating === 2 && <EmojiBad size={size} style={style} alt="Bad Rating Emoji"/>}
                    {rating === 3 && <EmojiGood size={size} style={style} alt="Good Rating Emoji"/>}
                    {rating === 4 && <EmojiGreat size={size} style={style} alt="Great Rating Emoji"/>}
                </div>
            );
        }
    }

    presentReviewContent(content) {
        return (
            <Container className="review-content">
                {content.split('\n').map((content, i) => <p key={i}>{content}</p>)}
            </Container>
        )
    }

    toggleReplyForm = () => {
        if(this.props.replyPermitted) {
            this.setState({
                replyFormOpen: !this.state.replyFormOpen
            });
            this.props.clearOrderReviewReplyError({
                reviewId: this.props.id
            })
        }
    }

    render() {
        const {
            firstCreatedDate, content, ratingValue, id, replyToOrderReview, orderReviewsSubmitting, replies, vendor, deleteOrderReviewReply,
            editOrderReviewReply, orderReviewReplyError, orderId, showVendor, replyPermitted, user
        } = this.props;
        const {replyFormOpen} = this.state;
        if (content !== undefined && content.length > 0) {
            return (
                <Col data-cy={"review-card-" + orderId} className="review-card">
                    <CardBody className="review-card-body">
                        <Container className="review-content-container">
                            <Row className="no-padding">
                                <Col xs="auto" className="no-margin no-padding">
                                    {this.presentReviewRating(ratingValue, 16)}
                                </Col>
                                <Col className="no-margin no-padding">
                                    <Link className="order-link" to={`/order/${orderId}`}>SCOFF-{orderId}</Link>
                                    {firstCreatedDate && <div
                                        className="review-date">{moment(firstCreatedDate).format("dddd, MMMM Do YYYY, h:mm a")}</div>}
                                    {this.presentReviewContent(content)}
                                </Col>
                                <Col xs="auto">
                                    {showVendor && <img src={vendor.logoURL} alt="vendor logo"
                                         className="rounded vendor_logo vendor-logo review-card-logo"/>}
                                </Col>
                            </Row>
                            <Row className="no-padding">
                                <Col xs="12" className="no-padding">
                                    <Replies replies={replies} vendor={vendor} reviewId={id}
                                             user={user}
                                             deleteOrderReviewReply={deleteOrderReviewReply}
                                             editOrderReviewReply={editOrderReviewReply}
                                             orderReviewsSubmitting={orderReviewsSubmitting}
                                             orderReviewReplyError={orderReviewReplyError}/>
                                    { userHasPermissionForVendor(user, vendor.vendorID, CREATE_REVIEWS_REPLIES) &&
                                        !replyFormOpen &&
                                        replies.length === 0 &&
                                        <div>
                                        <button data-cy="reply-button" id={`reply-button-${id}`} className={`btn btn-secondary reply-button ${!replyPermitted ? 'disabled' : ''}`} target="_blank"
                                                onClick={() => this.toggleReplyForm()}>
                                            <img src={replyPermitted ? MessageIcon : MessageIconDisabled} alt="Message Icon"/>Reply
                                        </button>

                                        <Tooltip innerClassName='reply-tooltip' isOpen={this.state.replyNotPermittedTooltipOpen} placement="bottom" toggle={this.toggleReplyNotPermittedTooltip.bind(this)} target={`#reply-button-${id}`}>
                                            You can only reply to recent reviews
                                        </Tooltip>
                                    </div>}
                                    { userHasPermissionForVendor(user, vendor.vendorID, CREATE_REVIEWS_REPLIES) &&
                                        replyFormOpen &&
                                        <Container>
                                            <ReplyForm reviewId={id} toggleReplyForm={this.toggleReplyForm}
                                                   replyToOrderReview={replyToOrderReview}
                                                   orderReviewsSubmitting={orderReviewsSubmitting}
                                                   orderReviewReplyError={orderReviewReplyError}/>
                                    </Container>}
                                </Col>
                            </Row>
                        </Container>
                    </CardBody>

                </Col>
            );
        } else {
            return (
                <Card data-cy={"review-card-" + orderId} className="review-card">
                    <CardBody className="review-card-body">
                        <Container className="review-content-container">
                            <Row className="no-padding">
                                <Col xs="auto" className="no-margin no-padding">
                                    {this.presentReviewRating(ratingValue, 16)}
                                </Col>
                                <Col className="no-margin no-padding">
                                    <Link className="order-link" to={`/order/${orderId}`}>SCOFF-{orderId}</Link>
                                    <div style={{display: "block"}}>
                                        {firstCreatedDate && <div
                                            className="review-date">{moment(firstCreatedDate).format("dddd, MMMM Do YYYY, h:mm a")}</div>}
                                    </div>
                                </Col>
                                <Col xs="auto">
                                    {showVendor && <img src={vendor.logoURL} alt="vendor logo"
                                         className="rounded vendor_logo vendor-logo"
                                         style={{height: "30px", width: "30px"}}/>}
                                </Col>
                            </Row>
                            <Row className="no-padding">
                            </Row>
                        </Container>
                    </CardBody>
                </Card>
            )
        }
    }
}
