import React from "react";
import {Col, Container, Row} from "reactstrap";
import FirstLineTownPostcode from "../address/FirstLinePostcode";

export default class VendorStackedView extends React.Component {

    render() {
        const { apiVendor } = this.props;

        return (
            <Container data-cy="vendor-stacked-view">
                <Row xs={10} className="align-items-center mt-1 mb-1">
                    <Col style={{maxWidth: 80}}  className="col-sm">
                        <img data-cy="vendor-image" src={apiVendor.logoURL} alt={apiVendor.name + " logo"}
                             className="rounded mw-100 mh-100" />
                    </Col>
                    <Col className="col-sm-auto p-0 pr-2">
                        <div data-cy="vendor-name" className="font-weight-bold" >{apiVendor.name}</div>
                        <FirstLineTownPostcode address={apiVendor.address} />
                    </Col>
                </Row>
            </Container>
        );
    }
}