import React, { Component } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import OtpCodeForm from '../OtpCodeForm';
import OtpDescription from '../fields/OtpDescription';
import { submit } from 'redux-form';
import Loader from 'react-loader-spinner';
import LoaderButton from '../../../LoaderButton';
import * as StepRoutes from '../VendorOwnerFormRoutes';

class ConfirmYourDetails extends Component {

    getOtpsArrayFromVendorUpdate = (inFlightVendorOwnerChange) => {
        const detailUpdates = inFlightVendorOwnerChange.get("detailsUpdateResponse") || [];
        let otps = [];
        detailUpdates.forEach(detailUpdate => {
            const isBeingSubmitted = this.props.submittingOtpCodes && this.props.submittingOtpCodes.includes(detailUpdate.getIn(['otp','guid']));
            otps.push({ ...detailUpdate.get("otp").toJS(), detailsUpdateType: detailUpdate.get("detailsUpdateType"), requestGuid: detailUpdate.get("guid"), isBeingSubmitted: isBeingSubmitted });
        });
        return otps;
    }

    isAllOtpsVerified = () => {
        const detailUpdates = this.props.inFlightVendorOwnerChange.get("detailsUpdateResponse") || [];
        return detailUpdates.every(detailUpdate => detailUpdate.getIn(["otp","verified"]));
    }

    render() {
        const { inFlightVendorOwnerChange, resendingNewDetailsOtps, resendingNewDetailsOtpsErrors, resendOtpToNewDetails, otpCodeErrors, otpHasExpired } = this.props;
        const otps = this.getOtpsArrayFromVendorUpdate(inFlightVendorOwnerChange);

        const isContinueButtonEnabled = this.isAllOtpsVerified();
        
        return (
            <div>
                <Row>
                    <Col>
                        <span style={{ fontSize: "18px" }}>Confirm your new details</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span>We have sent a verification code to the following:</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs={{ size: 12 }} >
                        {otps.map(otp => <OtpDescription otp={otp} />)}
                    </Col>
                </Row>
                <Row style={{margin: "0"}}>
                    <Col style={{padding: "0"}}>
                        {otps.map(otp => {
                            const error=otpCodeErrors.get(otp.guid, "");
                            if (otp.isBeingSubmitted || resendingNewDetailsOtps.includes(otp.guid)) {
                                return (<div style={{ textAlign: "center" }}>
                                <Loader
                                    type="Puff"
                                    color="#e7131a"
                                    height="25"
                                    width="25"/>
                                    </div>)
                            } else {
                                return <OtpCodeForm form={otp.requestGuid} otp={otp} newDetailsType={otp.detailsUpdateType} onChange={(values, dispatch) => {
                                    if (values.otpCode && values.otpCode.length >= 4) {
                                        dispatch(submit(otp.requestGuid));
                                    }
                                }} onSubmit={this.props.onSubmit} resendingNewDetailsOtpsErrors={resendingNewDetailsOtpsErrors} resendOtpToNewDetails={resendOtpToNewDetails}
                                fieldError={error} />;
                            }

                        })}
                        {otpHasExpired && <Alert color="warning">The submitted verification code has expired.  We have sent a new verification code to your new details.</Alert>}
                    </Col>
                </Row>
                <Col xs={{ size: 10, offset: 1 }} sm={{ size: 8, offset: 2 }}>
                                <LoaderButton
                                    block
                                    disabled={!isContinueButtonEnabled}
                                    text="Continue"
                                    className="btn-default"
                                    onClick={() => this.props.push(StepRoutes.SIGN_CONTRACT)} />
                                <div className="mt-1 w-100 text-right">
                                    <button className="link-button black-link" onClick={() => this.props.cancelInFlightUpdate()}> Cancel</button>
                                </div>
                            </Col>
            </div>
        )
    }
}
export default ConfirmYourDetails;