import React, {Component} from "react";
import { Row, Col, FormGroup, Label, Alert, Tooltip } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import {MdError} from "react-icons/md";
import {FaQuestionCircle} from "react-icons/fa";
import LoaderButton from "../../../LoaderButton";
import AccountNumber from "../fields/AccountNumber";
import ValidateEnterBankDetails from "../validation/ValidateEnterBankDetails";
import SortCode from "../fields/SortCode";
import Loader from 'react-loader-spinner';
import moment from "moment";
import "moment/locale/en-gb";
moment.locale("en-gb");

class EnterBankDetails extends Component{

    constructor(props){
        super(props);
        this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

render(){
    return (
        <Row>
            <Col sm="12" md={{ size: 4, offset: 4 }}>
            {this.props.bankDetailsError && <Alert color="danger">{this.props.bankDetailsError.message}</Alert>}
                <form onSubmit={this.props.handleSubmit}>
                    <FormGroup>
                        <Label for="accountNumberInput"><b>Effective Date: </b>
                            {this.props.fetchingEffectiveDate && <div style={{ display: "inline-block", paddingLeft: "5px" }}> <Loader type="Oval" color="#e7131a" height={15} width={15} /> </div>}
                            {this.props.effectiveDate && <span id={"bank-details-data-entry-effective-date"}>{moment(this.props.effectiveDate).format("L")} <FaQuestionCircle id="effectiveDateToolTip" />
                                <Tooltip isOpen={this.state.tooltipOpen} placement="right" toggle={this.toggle} target="effectiveDateToolTip" >
                                Changes will take effect from the end of your last billing period.
        </Tooltip> </span>}
                            {this.props.effectiveDateError && <MdError size="20" color="red" />}
                            {this.props.effectiveDateError &&
                                <Alert color="danger">
                                    Something went wrong while fetching the effective date.
                                    <button className="link-button alert-link" onClick={() => this.props.fetchEffectiveDate(this.props.vendorID)}>  Click here to retry</button>.
                      </Alert>}
                        </Label>
                    </FormGroup>
                    <Field name="sortCode" type="text" component={SortCode} />
                    <Field name="accountNumber" type="text" component={AccountNumber} />
                    <Field
                        name="vendorID"
                        val={this.props.vendorID}
                        component={({ input, val }) => {
                            input.onChange(val)
                            return <div />
                        }}
                    />
                    <LoaderButton
                        block
                        type="submit"
                        isLoading={this.props.submittingBankDetails}
                        text="Submit"
                        loadingText="Submitting…"
                        className="btn-default"
                        style={{cursor:"pointer"}}/>
                    <div className="mt-1 w-100 text-right">
                        <button className="link-button black-link" onClick={() => this.props.push("/bank-details")}>Cancel</button>
                    </div>
                </form>
            </Col>
        </Row>
    )
}
}

export default reduxForm({
    form: "EnterBankDetails",
    validate: ValidateEnterBankDetails
})(EnterBankDetails)