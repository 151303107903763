import React, { Component } from "react";
import { Row, Col, Tooltip, Button } from "reactstrap";
import moment from "moment";
import CompleteIcon from "../../../../images/icons/bank-details/complete.svg";
import {FaQuestionCircle} from "react-icons/fa";
class Complete extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }

    render() {
        const { accountNumber, sortCode, bankName, startDate, push } = this.props;
        return (
            <Row>
                <Col xs="12" sm="12" md="12" lg={{ size: 6, offset: 3 }} xl={{ size: 6, offset: 3 }}>
                            <Row className="row justify-content-center">
                                <span id={"bank-details-update-completed-text"}>Your bank details have been successfully updated</span>
                            </Row>
                            <Row className="row justify-content-center">
                                <Col xs={{ size: "auto", offset: 0 }} sm={{ size: "auto", offset: 0 }} md={{ size: "auto", offset: 0 }} lg={{ size: "auto", offset: 0 }} xl={{ size: "auto", offset: 0 }}>
                                    <img src={CompleteIcon} style={{ height: 80, width: 80 }} alt="Complete Icon" />
                                </Col>
                                <Col id={"bank-details-update-completed-details"} xs={{ size: "auto", offset: 0 }} sm={{ size: "auto", offset: 0 }} md={{ size: "auto", offset: 0 }} lg={{ size: "auto", offset: 0 }} xl={{ size: "auto", offset: 0 }}>
                                    <span>
                                        <span style={{ display: "block" }}><b>Account Number:</b> {accountNumber}</span>
                                        <span style={{ display: "block" }}><b>Sort Code:</b> {sortCode}</span>
                                        <span style={{ display: "block" }}><b>Bank Name:</b> {bankName}</span>
                                        <span style={{ display: "block" }}><b>Effective Date:</b><span> {moment(startDate).format("L")} <FaQuestionCircle id="effectiveDateToolTip" />
                                            <Tooltip isOpen={this.state.tooltipOpen} placement="right" toggle={this.toggle} target="effectiveDateToolTip" >
                                                Changes have taken effect from the end of your last billing period.
        </Tooltip> </span>
                                        </span>
                                    </span>
                                </Col>
                            </Row>
                            <div style={{paddingTop: "20px"}}>
                            <Button id={"bank-details-update-completed-return-button"} block className="btn-default" style={{cursor:"pointer"}} onClick={() => push("/bank-details")}>
                            Return to Bank Details
                            </Button>
                            </div>
                </Col>
            </Row>
        );
    }
}
export default Complete;