import React from "react";
import { Button } from "reactstrap";
import  {FiRefreshCcw} from 'react-icons/fi'
import "../css/LoaderButton.css";

const defaultExport = ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) => <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <FiRefreshCcw className="loader-icon spinning"/>}
    {!isLoading ? text : loadingText}
  </Button>;

  export default defaultExport