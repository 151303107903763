import React, { Component } from "react";

class InitialInstructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            redirectToComplianceInstructions: false
        }
    }

    render() {

        const { vendor, complianceSession } = this.props;

        return (
            <div>

                <div id={"compliance-instructions"}>

                    {vendor.setupPreviouslyCompleted &&
                    <div>
                        <p>You've previously completed the KYC requirements, however there is
                        new information that needs collected from you.</p>

                        <p>Please continue to our partner (Stripe) who manages this on behalf of Scoffable.</p>
                    </div>
                    }

                    {!vendor.setupPreviouslyCompleted &&
                    <p>You will be redirected to our partner (Stripe) who manages
                        the KYC (Know Your Customer) requirements on behalf of Scoffable.</p>}

                </div>

                <div className={"text-center mt-4 mb-3"}>
                    <a id={"continue-to-stripe-button"} className="fix-compliance-button btn-secondary btn-destructive"
                       href={complianceSession.url}>Continue to Stripe</a>
                </div>

            </div>
        );
    }

}

export default InitialInstructions