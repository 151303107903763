import React from 'react';

const QuestionAndAnswer = ({ question, answer, index, id }) => {
    return (
        <div className="card" id={`${question.replace(/ /g, '')}`}>
            <div className="card-header" id={`${question.replace(/ /g, '')}-heading`} >
                <h2 className="mb-0">
                    <button className="btn btn-link text-secondary" type="button" data-toggle="collapse" data-target={`#${question.replace(/ /g, '')}-collapsible`} aria-expanded="false" aria-controls={index} style={{ textAlign: "left" }}>
                        {`${question}?`}
                    </button>
                </h2>
            </div>
            <div id={`${question.replace(/ /g, '')}-collapsible`} className="collapse" aria-labelledby={`${question.replace(/ /g, '')}-heading`} data-parent={`#${id}faqs`}>
                <div className="card-body">
                    {answer}
                </div>
            </div>
        </div>);
}

export default QuestionAndAnswer;