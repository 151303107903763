import React, {Component} from "react";
import {Card, Container, Media} from "reactstrap";
import LoaderButton from "../../../components/LoaderButton";
import moment from "moment";
import {userHasPermissionForVendor} from "../../../utils/helpers/User";
import {CREATE_COMPLIANCE} from "../../../utils/helpers/Permission";

class ComplianceCard extends Component {

    render() {

        const { vendor, history } = this.props

        const cardPadding = this.complianceIssueNeedsResolving() ? "px-3 pt-3" : "p-3"

        return (
            <Container data-cy="compliance-vendor-card" className={"container-sm"}>
                <Card className={"m-3 " + cardPadding}>
                    <Media>
                        <img src={vendor.logoURL} alt="vendor logo"
                             className={"rounded vendor-logo vendor_logo mr-3"}
                             style={{height: "50px", width: "50px"}}/>
                        <div className={"media-body"}>
                            <h4 className="vendor-name m-0">{vendor.name}</h4>
                            <Container
                                className={"flex justify-content-start p-0 my-2"}>{/*d-flex flex-wrap justify-content-end align-bottom*/}
                                {vendor.vendorComplianceStatus === "COMPLIANT" && <span
                                    className={"compliance-status-text unimportant-text"}>Fully compliant - No action required</span>}
                                {vendor.vendorComplianceStatus === "FUTURE_NOT_COMPLIANT" && <span
                                    className={"compliance-status-text unimportant-text"}>Action required before {moment(vendor.futureComplianceEnforcementDate).format('L')}</span>}
                                {vendor.vendorComplianceStatus === "NOT_COMPLIANT" && <span
                                    className={"compliance-status-text important-text"}><em>Not Compliant</em></span>}
                                {vendor.vendorComplianceStatus === "COMPLIANCE_CHECKS_IN_PROGRESS" && <span
                                    className={"compliance-status-text unimportant-text"}><em>Verification checks in progress</em></span>}
                            </Container>
                        </div>

                    </Media>
                    {
                        this.complianceIssueNeedsResolving() &&
                    <Container className={"col-md-4 mb-1 p-0 flex justify-content-end"}>
                        {  <LoaderButton
                            block
                            type="submit"
                            text="Fix Issue"
                            loadingText="Submitting…"
                            className="fix-compliance-button btn-secondary btn-destructive"
                            onClick={() => history.push("/compliance/"+vendor.vendorId+"/"+vendor.ownershipPeriodId)}
                            style={{cursor: "pointer"}}/>
                        }
                    </Container>}
                </Card>
            </Container>

        );
    }

    complianceIssueNeedsResolving() {
        const { user, vendor } = this.props;

        return userHasPermissionForVendor(user, vendor.vendorId, CREATE_COMPLIANCE) &&
            (vendor.vendorComplianceStatus === "NOT_COMPLIANT" || vendor.vendorComplianceStatus === "FUTURE_NOT_COMPLIANT");
    }
}

export default (ComplianceCard)