import React, { Component } from 'react';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from 'react-google-maps';


class MapWithMarker extends Component {  

  render() {

    return (
        <GoogleMap
        defaultZoom={this.props.zoomLevel}
        defaultCenter={{ lat: this.props.latitude, lng: this.props.longitude }}>
        
        <Marker
          position={{ lat: this.props.latitude, lng: this.props.longitude }}
        />
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(MapWithMarker));