const defaultExport = values => {
    const errors = {};
    const otpCodeRegex = new RegExp(/^\d{6}$/);

    if(!values.otpCode){
        errors.otpCode = "Confirmation code is required";
    }
    if(values.otpCode && !otpCodeRegex.test(values.otpCode)){
        errors.otpCode = "Confirmation code has to have 6 digits."
    }
    return errors;
}

export default defaultExport