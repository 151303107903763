import React, { Component } from 'react';
import {FaQuestionCircle} from 'react-icons/fa';
import { Row, Tooltip } from "reactstrap";

export default class NameTooltip extends Component {

    constructor(props) {
      super(props)
    
      this.state = {
         nameTooltipOpen: false,
         id: `nameExpiredTooltip${props.id}`
      }
    }
    
    toggleNameTooltip = () => {
        this.setState({
            nameTooltipOpen: !this.state.nameTooltipOpen
        })
    }

  render() {
      const{ firstName, lastName } = this.props;
    var nameElement;
    if (!firstName || !lastName) {
        nameElement = <span id={this.state.id} style={{color: "grey"}}><FaQuestionCircle  style={{ marginRight: "5px" }} />
        <Tooltip isOpen={this.state.nameTooltipOpen} placement="right" toggle={this.toggleNameTooltip} target={this.state.id}>
        User details will expire after 24hrs in order to comply with data privacy laws
</Tooltip> Expired</span>
    } else {
        nameElement = <b>{firstName} {lastName}</b>
    }
    
    return (<Row className="order-detail-row">
                <div className="col-6 order-detail-col-heading">
                    Name
                </div>
                <div className="col-6 order-detail-col-val">
                    <b>{nameElement}</b>
                </div>
                
            </Row>)
  }
}
