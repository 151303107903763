import {
    login,
    loginSuccess,
    loginFailed,
    logout,
    logoutSuccess,
    logoutFailed,
    verifyOTP,
    verifyOTPSucccess,
    verifyOTPFailed,
    storePasswordResetKey,
    cancelOTP,
    resendOTP,
    requestPasswordResetEmail,
    requestPasswordResetEmailSuccess,
    requestPasswordResetEmailFailed,
    setPassword,
    setPasswordSuccess,
    setPasswordFailed,
    requestRegistrationEmail,
    requestRegistrationEmailSuccess,
    requestRegistrationEmailFailed,
    passwordResetCodeExpired,
    selectLoginAccount,
    selectLoginAccountSuccess,
    selectLoginAccountFailed,
    register,
    fetchUserDetails, fetchUserDetailsSuccess, fetchUserDetailsFailed
} from "../../actions/UserActions";

import { isCookiesExists } from "../../utils/cookie/CookieManager";

const initialState = {
    maxOTPMessagesSent: false,
    resetPasswordInProgress: false,
    passwordResetCodeExpired: false,
    userHasAuthenticated: isCookiesExists(),
    user: null,
    fetchingUser: false
};

export default function UserReducer(state = initialState, action){
    switch (action.type){
        case login().type:
            return {...state, loginInProgress: true, error: null, maxOTPMessagesSent: false}
        case loginSuccess().type:
            return {...state, loginInProgress: false, error: null, loginSessionId: action.payload.loginSessionId};
        case loginFailed().type:
            return {...state, loginInProgress: false, error: action.payload}
        case logout().type:
            return {...state}
        case logoutSuccess().type:
            return {...initialState, userHasAuthenticated: false }
        case logoutFailed().type:
            return {...state, error: action.payload, userHasAuthenticated: false}
        case verifyOTP().type:
            return {...state, verifyOTPInProgress: true, error: null}
        case verifyOTPSucccess().type:
            return {...state, verifyOTPInProgress: false, error: null, userHasAuthenticated: action.userHasAuthenticated, linkedAccounts: action.linkedAccounts, userId: action.userId, notification: null}
        case verifyOTPFailed().type:
            return {...state, verifyOTPInProgress: false, error: action.payload}
        case resendOTP().type:
            return {...state, error: null, notification: "Verification code re-sent."}
        case cancelOTP().type:
            return {...state, verifyOTPInProgress: false, error: null, loginSessionId: null}
        case requestRegistrationEmail().type:
            return {...state, resetPasswordInProgress: true, error: null, passwordResetRequestSent: false}
        case requestRegistrationEmailSuccess().type:
            return {...state, resetPasswordInProgress: false, error: null, passwordResetRequestSent: true, passwordResetCodeExpired:false}
        case requestRegistrationEmailFailed().type:
            return {...state, resetPasswordInProgress: false, error: action.payload, passwordResetRequestSent: false}
        case requestPasswordResetEmail().type:
            return {...state, resetPasswordInProgress: true, error: null, passwordResetRequestSent: false}
        case requestPasswordResetEmailSuccess().type:
            return {...state, resetPasswordInProgress: false, error: null, passwordResetRequestSent: true, passwordResetCodeExpired:false}
        case requestPasswordResetEmailFailed().type:
            return {...state, resetPasswordInProgress: false, error: action.payload, passwordResetRequestSent: false}
        case storePasswordResetKey().type:
            return {...state, passwordResetKey: action.payload}
        case setPassword().type:
            return {...state, resetPasswordInProgress: true, error: null}
        case register().type:
            return {...state, resetPasswordInProgress: true, error: null}
        case passwordResetCodeExpired().type:
            return {...state, resetPasswordInProgress: false, checkingIfPasswordResetCodeExpired: false, passwordResetCodeExpired: true}
        case setPasswordSuccess().type:
            return {...state, resetPasswordInProgress: false, error: null, loginSessionId: action.loginSessionId}
        case setPasswordFailed().type:
            return {...state, resetPasswordInProgress: false, error: action.payload}
        case selectLoginAccount().type:
            return {...state, isAccountSelectionInProgress: true}
        case selectLoginAccountSuccess().type:
            return {...state, isAccountSelectionInProgress: false, userHasAuthenticated: action.userHasAuthenticated}
        case selectLoginAccountFailed().type:
            return {...state, resetPasswordInProgress: false, error: action.payload}
        case fetchUserDetails().type:
            return {...state, fetchingUser: true}
        case fetchUserDetailsSuccess().type:
            return {...state, fetchingUser: false, user: action.payload}
        case fetchUserDetailsFailed().type:
            return {...state, fetchingUser: false}
        default:
            return state;
    }
}