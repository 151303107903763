import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { Container, Row, Button } from "reactstrap";
import moment from "moment";
import { FormattedNumber, IntlProvider } from 'react-intl';
import NameTooltip from '../../../../components/user-details/NameTooltip';
import AddressTooltip from '../../../../components/user-details/AddressTooltip';
import {userHasPermission} from "../../../../utils/helpers/User";
import {VIEW_ORDERS_CUSTOMER_INFO} from "../../../../utils/helpers/Permission";

class OrdersCard extends Component {
    /* eslint-disable react/style-prop-object */

    formatTextElement(label, element) {
        if (element != null) {
            return (
                <Row className="order-detail-row">
                    <div className="col-6 order-detail-col-heading">
                        {label}
                    </div>
                    <div className="col-6 order-detail-col-val">
                        <b>{element}</b>
                    </div>
                </Row>
            );
        }
    }

    formatDateElement(label, element) {
        let date;
        if (element != null) {
            if(moment(element).isBefore(moment().subtract(6, "days"))){
                date = moment(element).format("DD/MM/YY HH:mm")
            } else {
                date = `${moment(element).format("dddd")} at ${moment(element).format("HH:mm")}`
            }
            return (
                <Row className="order-detail-row">
                    <div className="col-6 order-detail-col-heading">
                        {label}
                    </div>
                    <div className="col-6 order-detail-col-val">
                        <b>{date}</b>
                    </div>
                </Row>
            );
        }
    }

    formatCollectionMethod(collectionMethod) {
        if (collectionMethod !== undefined) {
            if (collectionMethod === "DELIVERY") {
                return "Delivery"
            }
            else {
                return "Collection"
            }
        }
        return null;
    }

    formatPaymentMethod(paymentMethod) {
        if (paymentMethod !== undefined) {
            if (paymentMethod === "CARD") {
                return "Debit / Credit Card"
            }
            else {
                return "Cash"
            }
        }
        return null;
    }

    presentVendorLogo(logoURL, orderNumber) {
        if (logoURL != null) {
            return (
                <div style={{marginBottom: "10px"}}>
                <img src={logoURL} alt="Vendor Logo" className="rounded summary-vendor-logo vendor-logo" />
                <h5 className="card-title" style={{display: "inline-block", paddingLeft: "5px", margin: "0px"}}>SCOFF-{orderNumber}</h5>
                </div>
            );
        }
    }

    renderPayment(total, paymentMethod) {
        /* eslint-disable react/style-prop-object */
        return (
            <div>
                <IntlProvider locale={navigator.language}>
                    <FormattedNumber
                        value={total}
                        currency="GBP"
                        style="currency"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2} />
                </IntlProvider>
                <span style={{fontStyle: "italic", paddingLeft: "5px"}}>{this.formatPaymentMethod(paymentMethod)}</span>
            </div>
        )
        /* eslint-enable react/style-prop-object */
    }

    render() {
        const {id, vendorLogo, submittedDate, type, firstName, surname, deliveryAddress, total, paymentMethod, history, user} = this.props
        return (
            <div key={id} style={{paddingLeft: "10px", paddingRight: "10px"}}>
            <div data-cy={"order-listing-card-" + id } className="card mb-2 order-summary-info-card">
                <div className="card-body">
                    {this.presentVendorLogo(vendorLogo, id)}
                    <Container>
                        {this.formatDateElement("Date", submittedDate)}
                        {this.formatTextElement("Type", this.formatCollectionMethod(type))}
                        {userHasPermission(user, VIEW_ORDERS_CUSTOMER_INFO) && <NameTooltip firstName={firstName} lastName={surname} id={id} />}
                        {userHasPermission(user, VIEW_ORDERS_CUSTOMER_INFO) && this.formatTextElement("Address", <AddressTooltip address={deliveryAddress} type={type} id={id} />)}
                        {this.formatTextElement("Payment", this.renderPayment(total, paymentMethod))}
                        <Button data-cy='view-order-details-button' color="secondary" block onClick={() => history.push("/order/" + id)} style={{marginTop: "20px"}}>View order details</Button>
                    </Container>
                </div>
            </div>
            </div>
        );
    }
}

export default withRouter(OrdersCard);