import React from 'react';
import { Link } from 'react-router-dom';

const AccountQuestions = ({showOrderStats}) => {
    return ([
        {
            question: "How do I get my daily sales on the printer",
            answer: <p>This feature is not available on the printer itself, however you can navigate to the <Link to={() => {
                showOrderStats();
                return "/orders"
                }}
                >orders page</Link> for a full breakdown of all orders received on a specified date period. This will include order totals &amp; show the details for each individual order received.</p>
        },
        {
            question: "How do I update my bank details",
            answer: <p>You can update your bank details in the <Link to="bank-details">bank details section</Link>.</p>
        },
        {
            question: "How do I update ownership",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 and let a member of staff know about the change of ownership. A few details will be taken down and we will arrange for a member of our sales team to visit the store to complete the ownership change procedure. A full list of required documentation will be provided to you by email to ensure a smooth transition.</p>
        },
        {
            question: "How do I change my registered email address or mobile number",
            answer: <p>You can do this in the <Link to="/user-management">contact details section</Link>.</p>
        },
        {
            question: "How do I change the email address invoices are sent to",
            answer: <p>Invoices are sent to the registered email address on your account which you can change in the <Link to="/user-management">contact details section</Link>.</p>
        },
        {
            question: "How do I block a customer or specific address",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 and let a member of staff know about the issues with a specific address/customer. We can ensure a block is put on any orders for these details going forward.</p>
        },
        {
            question: "How often do I receive payments",
            answer: <p>Payments are transferred to your bank account on a weekly basis, and include orders from the full previous week (Monday to Sunday). Invoices and payments are sent on Wednesday each week, however it can take up until Friday to arrive in your bank account depending on the bank you use.</p>
        },
        {
            question: "How do I get more business through you",
            answer: <p>If you are unhappy with the order volume, or would like to attempt to gain a higher volume of customers please let us know by calling our Support Line on 0131 208 2230. A lot of our advertising is conducted online and we can arrange to attempt increased coverage for your business through Google &amp; social media.</p>
        },
        {
            question: "How do I get promotional leaflets or t-shirts",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 where a member of our staff will arrange for promotional leaflets &amp; t-shirts to be delivered to your business at the earliest opportunity.</p>
        },
        {
            question: "How do I cancel my Scoffable account",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 to discuss the cancellation procedure.</p>
        },
        {
            question: "How do I close for a night",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 and one of our staff will immediately close your business for the rest of the day. This can be for delivery orders, collection orders, or both.</p>
        },
        {
            question: "How do I close for a holiday or refurbishment",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 and one of our staff will immediately close your business for online orders. If you have a date for reopening we can arrange for the business to automatically reopen at this time, or we can temporarily close the business until further notice if required.</p>
        },
        {
            question: "How do I order till rolls for my printer",
            answer: <p>Please contact our Customer Support Team on 0131 208 2230 where a member of staff will arrange for delivery of thermal printer rolls to your business at the earliest opportunity. These will be charge at £10+VAT per box of 20 rolls. This cost will be charged on your next available invoice after delivery.</p>
        },
        {
            question: "When are the Customer Support Team available",
            answer: <p>Our Customer Support Team is available for contact between 8AM and Midnight every day of the year.</p>
        },
        {
            question: "How do I know my details are secure - what if someone calls pretending to be me",
            answer: <p>Our Customer Support Team will only make changes to your business’ menu/opening times if we receive a phone call from the store landline or owner mobile number. In the case where the request is made outwith these numbers we will call the shop landline to confirm the changes prior to completing them.</p>
        }
    ])
}

export default AccountQuestions;
