import React from "react";
import {Col, Container, Jumbotron, Row} from "reactstrap";
import VendorStackedView from "../../components/vendor/VendorStackedView";
import LoaderButton from "../../components/LoaderButton";

export default class Account extends React.Component {

    render() {
        const {account, accountNumericIndex, onAccountSelect, isAccountSelectionInProgress} = this.props;

        return (
            <Jumbotron data-cy="account-card" fluid className="card pt-3 pb-4 mb-3 bg-white" style={{borderRadius: "5px"}}>
                <Container fluid>
                    <Row className="align-items-center">
                        <Col className="pb-2">
                            <h4 data-cy="account-number">Account #{accountNumericIndex}</h4>
                        </Col>
                    </Row>
                    <Row className="pl-2">
                        {
                            account.vendors.map(vendor => <VendorStackedView key={vendor.vendorID}
                                apiVendor={vendor}/>)
                        }
                    </Row>
                    <Row className="align-items-center pt-3">
                        <Col>

                            <LoaderButton
                                block
                                type="submit"
                                onClick={() => onAccountSelect(account.id)}
                                isLoading={isAccountSelectionInProgress}
                                text="Select"
                                loadingText="Logging in…"
                                data-cy="select-account-button"
                                className="btn-default"/>

                        </Col>
                    </Row>
                </Container>
            </Jumbotron>
        )
    }
}