import React, { Component } from 'react';
import InvoiceCard from './InvoiceCard';

export default class InvoicesCards extends Component {
    

    renderInvoiceCards = (invoices, user) => {
        return invoices.map(invoice => <InvoiceCard {...invoice} key={invoice.invoiceNumber} user={user} />)
    }

  render() {
    const { invoices, user } = this.props;

    return (
        <div data-cy="invoices-cards-container">
          {this.renderInvoiceCards(invoices, user)}
          </div>
    )
  }
}
