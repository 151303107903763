import React from "react";
import {Col, Container, Row} from "reactstrap";
import Account from "../components/account/Account";

export default class AccountSelection extends React.Component {

    render() {
        const {accounts, onSelect, isAccountSelectionInProgress} = this.props;

        return (
            <Row className="justify-content-center">
                <Container>
                    <Col>
                        <Container className="mt-3">
                            <Row className="d-block">
                                {accounts.map((account, index) =>
                                    <Account key={account.id} account={account} accountNumericIndex={index + 1}
                                             onAccountSelect={onSelect}
                                             isAccountSelectionInProgress={isAccountSelectionInProgress}/>
                                )}
                            </Row>
                        </Container>
                    </Col>
                </Container>
            </Row>
        );
    }
}