import '../css/Login.css';

import React, { Component } from 'react';
import { Form, FormGroup, Input, Alert } from 'reactstrap';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderButton from '../components/LoaderButton';
import logoImg from '../images/scoffable_partner_centre_logo.svg';
import { setPassword, register } from "../actions/UserActions";


class PasswordReset extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      password: "",
      reenteredpassword: "",
    };
  }

  renderErrorSection() {
    if (this.state.password.length === 0 || this.state.reenteredpassword.length === 0) {
      return null;
    }
    if (!this.validatePassword()) {
      return (<div className="alert alert-danger" role="alert">Password must be at least 8 characters.</div>);
    }
    else if (!this.validatePasswordsMatch()) {
      return (<div className="alert alert-danger" role="alert">Passwords do not match. Please re-enter.</div>);
    }

    if (this.props.passwordError) {
      return (<div className="alert alert-danger" role="alert">{this.props.passwordError}</div>)
    }
  }

  validatePassword() {
    return this.state.password.length >= 6
  }

  validatePasswordsMatch() {
    return this.state.password === this.state.reenteredpassword
  }

  validateForm() {
    return this.validatePassword()
      && this.validatePasswordsMatch();
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    if (this.validateForm()) {
      if(this.props.registerMode){
        this.props.actions.register({
          registrationSessionId: this.props.match.params.registrationSessionId,
          passwordResetKey: this.props.match.params.passwordResetKey,
          password: this.state.password,
          push: this.props.history.push
        })
      } else {
        this.props.actions.setPassword({
          passwordResetSessionId: this.props.match.params.passwordResetSessionId,
          passwordResetKey: this.props.match.params.passwordResetKey,
          password: this.state.password,
          push: this.props.history.push
        })
      }
    }
  }

  render() {
    let { passwordResetCodeExpired } = this.props;
    let passwordForm = (
        <Form onSubmit={this.handleSubmit}>
          <div className="alert alert-light p-0 m-2 text-center" role="alert">Please enter your new Scoffable Partner
            Centre password below.
          </div>
          {this.renderErrorSection()}
          <FormGroup>
            <Input type="password"
                   name="Password"
                   id="password"
                   value={this.state.password}
                   onChange={this.handleChange}
                   placeholder="Enter Password"/>
          </FormGroup>
          <FormGroup>
            <Input type="password"
                   name="Re-enter Password"
                   id="reenteredpassword"
                   value={this.state.reenteredpassword}
                   onChange={this.handleChange}
                   placeholder="Re-enter Password"/>
          </FormGroup>
          <LoaderButton
              block
              type="submit"
              isLoading={this.props.resetPasswordInProgress}
              text="Set Password"
              loadingText="Setting Password..."
              className="btn-default"
          />
        </Form>
    );

    return (
      <div className="container login-container">
        <div className="card login-card">
          <div className="card-body">
            <div className="Login">
              <div className="logo-container">
                <img alt="login" src={logoImg} className="login-logo" />
              </div>
              {!passwordResetCodeExpired && passwordForm}
              {passwordResetCodeExpired && <div>
                <Alert color="danger" style={{margin: "20px"}}>
                  This email code has expired or is not valid.  <a href={(this.props.registerMode) ? "/register" : "/passwordreset"} className="alert-link">You can request another one here.</a>
                </Alert>
              </div>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    resetPasswordInProgress: store.user.resetPasswordInProgress,
    passwordError: store.user.error,
    passwordResetCodeExpired: store.user.passwordResetCodeExpired,
    checkingIfPasswordResetCodeExpired: store.user.checkingIfPasswordResetCodeExpired
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ register, setPassword }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);