export function fetchInvoices(payload) {
    return {
        type: "FETCH_INVOICES",
        payload: payload
    }
}

export function fetchInvoicesSuccess(payload) {
    return {
        type: "FETCH_INVOICES_SUCCESS",
        payload: payload
    }
}

export function fetchInvoicesFailed(error) {
    return {
        type: "FETCH_INVOICES_FAILED",
        payload: error
    }
}

export function updateInvoicesPerPage(payload){
    return {
        type: "UPDATE_INVOICES_PER_PAGE",
        payload: payload
    }
}

export function updateInvoicesListActivePage(payload){
    return {
        type: "UPDATE_INVOICES_LIST_ACTIVE_PAGE",
        payload: payload
    }
}