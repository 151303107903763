import '../../../../css/OrdersSummary.css';

import React, { Component } from 'react';
import { Sparklines, SparklinesLine, SparklinesSpots } from 'react-sparklines';
import { Card, CardBody } from 'reactstrap';
import { connect } from "react-redux";

class OrderNumberSummary extends Component {

    render() {
        const { summaryData, dailyOrderNumberTotals } = this.props;

        let summaryValue = 0;
        let dailyOrderNumberSummary = [];
        if (summaryData != null && summaryData.totalNumOrders != null) {
            summaryValue = summaryData.totalNumOrders;
        }

        if (dailyOrderNumberTotals != null) {
            dailyOrderNumberSummary = dailyOrderNumberTotals;
        }

        return (
            <div style={{width: "100%", height: "100%"}}>
                <Card className="order-summary-card h-100">
                    <div className="headline-title">Number of Orders</div>
                    <CardBody>
                        <div className="headline-value">{summaryValue}</div>
                        <div className="chart-wrapper" style={{height: "90px"}}>
                            <div className="headline-spark-chart">
                                {dailyOrderNumberSummary.length > 1 && <Sparklines data={dailyOrderNumberSummary}
                                    height={40}>
                                    <SparklinesLine style={{ fill: "#B8008A", stroke: "#B8008A" }} />
                                    <SparklinesSpots style={{ fill: "#B8008A" }} />
                                </Sparklines>}
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    summaryData: state.orders.summaryData,
    dailyOrderNumberTotals: state.orders.dailyOrderNumberTotals
});

export default connect(mapStateToProps)(OrderNumberSummary);