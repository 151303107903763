import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import InvoiceTableRow from './InvoicesTableRow';

export default class InvoicesTable extends Component {

    renderTableRows = (invoice, user) => {
        return invoice.map(invoice => <InvoiceTableRow key={invoice.invoiceNumber} {...invoice} user={user} />)
    }

  render() {
      const { invoices, user } = this.props;
    return (
      <div>
        <Table data-cy="invoices-table" celled definition unstackable striped>
                <Table.Header fullWidth>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>From</Table.HeaderCell>
                        <Table.HeaderCell>To</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Commission</Table.HeaderCell>
                        <Table.HeaderCell singleLine textAlign="right">Service Fee</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">VAT</Table.HeaderCell>
                        <Table.HeaderCell singleLine textAlign="right">Total Charge</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {this.renderTableRows(invoices, user)}
                </Table.Body>
            </Table>
      </div>
    )
  }
}
