
export function fetchBankDetails() {
    return {
      type: 'FETCH_BANK_DETAILS'
    }
  }

  export function fetchBankDetailsSuccess(payload) {
      return {
          type: "FETCH_BANK_DETAILS_SUCCESS",
          payload: payload
      }
  }

export function fetchBankDetailsFailed(error) {
    return {
        type: "FETCH_BANK_DETAILS_ERROR",
        payload: error
    }
}

export function fetchEffectiveDate(payload) {
    return {
        type: "FETCH_EFFECTIVE_DATE",
        payload: payload
    }
}

export function fetchEffectiveDateSuccess(payload) {
    return {
        type: "FETCH_EFFECTIVE_DATE_SUCCESS",
        payload: payload
    }
}

export function fetchEffectiveDateFailed(error) {
    return {
        type: "FETCH_EFFECTIVE_DATE_FAILED",
        payload: error
    }
}

export function submitBankDetails(payload){
    return {
        type: "SUBMIT_BANK_DETAILS",
        payload: payload
    }
}

export function submitBankDetailsSuccess(payload){
    return {
        type: "SUBMIT_BANK_DETAILS_SUCCESS",
        payload: payload
    }
}

export function submitBankDetailsFailed(error){
    return {
        type: "SUBMIT_BANK_DETAILS_FAILED",
        payload: error
    }
}

export function submitOtpCode(payload){
    return {
        type: "SUBMIT_OTP_CODE",
        payload: payload
    }
}

export function submitOtpCodeSuccess(payload){
    return {
        type: "SUBMIT_OTP_CODE_SUCCESS",
        payload: payload
    }
}

export function submitOtpCodeFailed(error){
    return {
        type: "SUBMIT_OTP_CODE_FAILED",
        payload: error
    }
}

export function cancelBankDetailsUpdate(payload){
    return {
        type: "CANCEL_BANK_DETAILS_UPDATE",
        payload: payload
    }
}

export function cancelBankDetailsUpdateSuccess(payload){
    return {
        type: "CANCEL_BANK_DETAILS_UPDATE_SUCCESS",
        payload: payload
    }
}

export function cancelBankDetailsUpdateFailed(error){
    return {
        type: "CANCEL_BANK_DETAILS_UPDATE_FAILED",
        payload: error
    }
}

export function fetchSigningUrl(payload){
    return {
        type: "FETCH_SIGNING_URL",
        payload: payload
    }
}

export function fetchSigningUrlSuccess(payload){
    return {
        type: "FETCH_SIGNING_URL_SUCCESS",
        payload: payload
    }
}

export function fetchSigningUrlFailed(error){
    return {
        type: "FETCH_SIGNING_URL_FAILED",
        payload: error
    }
}