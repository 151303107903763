import React from 'react';
import { Row, Col } from 'reactstrap';

const FaqSection = ({ questionsAndAnswers, title, id }) => {
    return (
    <Row style={{maxWidth: "1135px", margin: "0 auto 0 auto"}}>
        <Col>
            <h4 style={{ color: "grey" }}>
                {title}
            </h4>
            <div className="accordion" id={`${id}faqs`} style={{ textAlign: "left" }}>
                {questionsAndAnswers}
            </div>
        </Col>
    </Row>);
}

export default FaqSection;