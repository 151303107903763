import {userHasPermission} from "./User";
import {VIEW_BANK_DETAILS, VIEW_COMPLIANCE, VIEW_CONTACT_DETAILS, VIEW_REVIEWS} from "./Permission";

export const showContactDetails = (props) => {
    const { user, vendors } = props;
    // As we sign a new contract etc etc we currently only allow this to be done on owners who have currently active vendors
    return userHasPermission(user, VIEW_CONTACT_DETAILS) && vendors.some(vendor => vendor.currentlyOwnedByAuthenticatedOwner)
}

export const showBankDetails = (props) => {
    const { user, vendors } = props;
    // As we sign a new contract etc etc we currently only allow this to be done on owners who have currently active vendors
    return userHasPermission(user, VIEW_BANK_DETAILS) && vendors.some(vendor => vendor.currentlyOwnedByAuthenticatedOwner)
}

export const showCompliance = (props) => {
    const { user, complianceEnabled } = props;
    // At the time of writing the compliance stuff isn't rolled out, so we selectively show / hide this menu
    return userHasPermission(user, VIEW_COMPLIANCE) && complianceEnabled;
}

export const showReviews = (props) => {
    const { user, orderReviewsDisabled } = props;
    // I'm not sure why we'd disable order reviews. Maybe this was a migration consideration we never threw away?
    // Anyway, I'm not going to look into / fix this now.
    return userHasPermission(user, VIEW_REVIEWS) && !orderReviewsDisabled
}