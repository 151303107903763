import React, {Component} from 'react'
import ReplyCard from './ReplyCard'

export default class Replies extends Component {


    render() {
        const {
            replies,
            vendor,
            reviewId,
            deleteOrderReviewReply,
            editOrderReviewReply,
            orderReviewsSubmitting,
            orderReviewReplyError,
            user
        } = this.props;
        if (replies.length > 0) {
            return replies.map(reply => <ReplyCard key={reply.id}
                                                   reply={reply}
                                                   vendor={vendor}
                                                   user={user}
                                                   reviewId={reviewId}
                                                   deleteOrderReviewReply={deleteOrderReviewReply}
                                                   editOrderReviewReply={editOrderReviewReply}
                                                   orderReviewsSubmitting={orderReviewsSubmitting}
                                                   orderReviewReplyError={orderReviewReplyError}/>)
        } else {
            return null;
        }
    }
}
